import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import SnsLoginButton from './sociallogin/SnsLoginButton';
// import AppleLoginButton from './sociallogin/AppleLoginButton';
import { useAuth } from "./Auth";
import GrayCheckbox from 'element/GrayCheckbox';

const SignForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 34.6rem;
    height: 100%;
    gap: 4rem;
`;

const InputContainer =  styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
    height: 100%;
`;


const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    height: fit-content;
    mrgin-bottom: 1rem;
    align-items: center;
    justify-content: center;
    
    input {
        display: flex;
        font-size: 1.55rem;
        letter-spacing: 0.1rem;
        width: 100%;
        height: auto;
        background-color: #F3F3F3;
        border-radius: 1rem;
        border: none;
        padding: 1.6rem 1.6rem;
        filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12));
    
        &:focus {
            outline: none;
        }
    }
`;
const TextandSocialContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 2.4rem;
`;


const KakaoLogin = styled.button`
    background-color: #FEE500;
    cursor: pointer;
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 10rem;
`;

const NaverLogin = styled.button`
    background-color: #03c75a;
    color: white;
    width: 4.4rem;
    height: 4.4rem;
    cursor: pointer;
    border-radius: 10rem;
`;

const SocialLoginGroup = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3.5rem;
    width: 100%;
`;

const CustomBtn = styled.button`
    cursor: pointer;
    width: 100%;

    padding: 1.3rem 1.6rem;
    font-size: 2.2em;
    font-weight: 700;
    cursor: pointer;
    border-radius: 2.4rem;
    background-color: black;
    color: white;
`;

const ButtomContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    gap: 0.8rem;
`;

const FindSignupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: top;
    justify-content: center;
    width: 100%;
    height: fit-content;
    gap: 1.6rem;
`;

const FindSIgnupText = styled.span`
    font-size: 1.5rem;
    font-weight: 500;
    color: #666161;
    font-family: Spoqa Han Sans Neo;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    };

`;

const SocialText = styled.span`
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
    width: auto;
    color: #666161;
    font-family: Spoqa Han Sans Neo;
    white-space: nowrap;
`;

const SocialIcons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.6rem;
    width: 100%;
    height: 100%;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.6rem;
    width: 100%;
    height: fit-content;
`;

const UnderLine = styled.div`
    width: 100%;
    border-bottom: 0.1rem solid rgba(102, 97, 97, 0.50);;
`;

const InputField = ({ type, register, error, placeholder }) => (
    <>
        <input type={type} {...register} placeholder={placeholder} />
        {error && <span className="error">{error.message}</span>}
    </>
);

const VerticalLine = () => {
    const Div = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
    `;

    return (
        <Div>
        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="13" viewBox="0 0 2 13" fill="none">
        <path d="M1 0.5V12.5" stroke="#666161" strokeOpacity="0.5"/>
        </svg>
        </Div>
    )
}

const LoginForm = ({onSignup, toggleOverlay}) => {
    const { register, handleSubmit, formState: { errors, isValid } } = useForm();
    const { signInMutate } = useAuth();

    const onsubmit = async (data, e) => {
        e.preventDefault();
        await signInMutate.mutateAsync(data);
    };

    return (
        <>
        <SignForm onSubmit={handleSubmit(onsubmit)}>
            <InputContainer>
                <InputWrapper>
                    <InputField
                        type="text"
                        register={register("email", {
                            required: "이메일을 입력해주세요.",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "유효한 이메일 주소를 입력해주세요.",
                            },
                            }
                        )}
                        placeholder="이메일"
                        error={errors.email}
                    />
                </InputWrapper>
                <InputWrapper>
                    <InputField
                        type="password"
                        register={register("password", {
                            required: "비밀번호를 입력해주세요.",
                            })}
                        error={errors.password}
                        placeholder="비밀번호"
                    />
                </InputWrapper>
            </InputContainer>

            <ButtomContainer>
                <GrayCheckbox size='15px' label= '로그인 상태 유지'/>
                <CustomBtn disabled={!isValid} >로그인</CustomBtn>
            </ButtomContainer>

            <TextandSocialContainer>
                <FindSignupWrapper>
                    <TextWrapper>
                        <FindSIgnupText>비밀번호 찾기</FindSIgnupText>
                        <VerticalLine />
                        <FindSIgnupText>아이디 찾기</FindSIgnupText>
                        <VerticalLine />
                        <FindSIgnupText onClick={onSignup}> 회원가입</FindSIgnupText>
                    </TextWrapper>
                    <UnderLine />
                </FindSignupWrapper>

                <SocialLoginGroup>
                    <SocialText>간편한 소셜가입/로그인</SocialText>
                    <SocialIcons>
                        <KakaoLogin onClick={(event) => 
                            {event.preventDefault(); toggleOverlay();}}>
                            <SnsLoginButton social='kakao'/>
                        </KakaoLogin>
                        <NaverLogin onClick={(event) => 
                            {event.preventDefault(); toggleOverlay();}}>
                            <SnsLoginButton social='naver'/>
                        </NaverLogin>
                        {/* <AppleLoginButton onClick={(event) => 
                            {event.preventDefault(); toggleOverlay();}}/> */}
                    </SocialIcons>
                </SocialLoginGroup>
                
            </TextandSocialContainer>
        </SignForm>
        </>
    )
    };

export default LoginForm;
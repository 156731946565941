import { Route, Routes } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { AuthContext } from "utils/func/AuthContext";

import { isAdminLoginModalState, 
    globalUserInfoState,
 } from 'store/atoms';

import Accounts from 'pages/admin/Accounts';
import Apply from 'pages/admin/Apply';
import Dashboard from 'pages/admin/Dashboard';
import Portfolio from 'pages/admin/Portfolio';
import Favtest from 'pages/admin/Favtest';

import Headers from 'components/admin/Headers';
import Sidebar from 'components/admin/Sidebar';
import AdminLoginModal from 'components/admin/AdminLoginModal';

import { useEffect, useContext } from "react";

function AdminRoute() {
    const [isVisibleLoginModal, setVisibleLoginModal] = useRecoilState(isAdminLoginModalState);
    const { logoutClear } = useContext(AuthContext);
    const GlobalUserInfo = useRecoilValue(globalUserInfoState);

    useEffect(() => {
        
        if (GlobalUserInfo?.data?.role === 'CUSTOMER') {
            alert('관리자만 접근 가능합니다.');
        } else if (GlobalUserInfo?.data?.role) {
            setVisibleLoginModal(false);
        } 
    }, [GlobalUserInfo, setVisibleLoginModal, logoutClear]);

    
    return (
    <>
        <Headers />
        <ContentSection>
            <Sidebar />
            <Routes>
                <Route element={<Dashboard />} path="/" />
                <Route element={<Accounts />} path="/accounts" />
                <Route element={<Apply />} path="/apply" />
                <Route element={<Favtest />} path="/favtest" />
                <Route element={<Portfolio />} path="/portfolio" /> 
                {/* {/* <Route element={<Home />} path="/home" /> */}
            </Routes>
        </ContentSection>
        {isVisibleLoginModal && <AdminLoginModal />}
    </>
    );
}

export default AdminRoute;

const ContentSection = styled.section`

    display: flex;
    width: 100%;
    height: calc(100vh - 6rem);
    justify-content: space-between;
    overflow: auto;

    & > :first-child {
        width: 15rem;
    }

    & > :last-child {
        width: 100%;
    }
`;
import React, {useState} from "react";
import styled from "styled-components";
import { flexColumnCenter } from "utils/style/mixins";
// import {currentisSignupForm} from 'store/atoms';
// import { useSetRecoilState } from "recoil";
import { useForm } from 'react-hook-form';
import {postVerifyEmail, postVerifyCheckEmail} from "utils/api/api";
import SnsLoginButton from '../sociallogin/SnsLoginButton';
import ProgressBar from 'element/ProgressBar';
import { signupFormState } from 'store/atoms';
import { useRecoilState } from 'recoil';


const FormWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    gap: 7.6rem;
    flex-direction: column;

    @media ${props => props.theme.mediaQuery.desktop} {
        padding: 5.6rem 5.6rem 7.6rem 5.6rem;
        width: 75rem;
        height: 100%;
    };
    @media ${props => props.theme.mediaQuery.tablet} {
        padding: 5.6rem 5.6rem 7.6rem 5.6rem;
        width: 100%;
        height: fit-content;
    };
    @media ${props => props.theme.mediaQuery.phone} {
        padding: 0 3rem 7.6rem 3rem;
        width: 100%;
        height: fit-content;
    };
`;

const HeaderProgressBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media ${props => props.theme.mediaQuery.desktop} {
        padding: 0 12.9rem;
    };

    @media ${props => props.theme.mediaQuery.tablet} {
        padding: 0 0;
    };

    @media ${props => props.theme.mediaQuery.phone} {
        padding: 0 0;
    };


`;

const TItleWrapper = styled.div`
    width: 100%;
    height: 100%;
    ${flexColumnCenter}
    
    align-items: center;
    justify-content: center;
`;

const BodyWrapper = styled.div`
    width: 100%;
    height: 100%;
    ${flexColumnCenter}
    align-items: center;
    justify-content: center;
    gap: 5.9rem;
`;

const SubmitWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

`;

const TItleH1 = styled.span`
    font-family: Spoqa Han Sans Neo;
    font-size: 3.2rem;
    font-weight: 700;
    color: #222;
    letter-spacing: -2.56px;
`;

const TItlespan = styled.span`
    color: #757E89;
    text-align: center;
    font-family: Spoqa Han Sans Neo;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -1.28px;
`;

const SignForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 34.6rem;
    height: 100%;
    gap: 4rem;
`;

const InputContainer =  styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4.8rem;
    width: 100%;
    height: 100%;
`;


const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    mrgin-bottom: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0.8rem;


    
    input {
        display: flex;
        font-size: 1.55rem;
        letter-spacing: 0.1rem;
        width: 248px;
        height: 0.5rem;
        border: 0.1rem solid #747C87;
        padding: 1.6rem 1.6rem;
        filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12));
    
        &:focus {
            outline: none;
        }
    }
`;

const InputWrapper2 = styled.div`
    display: flex;

    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
`;

const TextandSocialContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 100%;
    gap: 2.5rem;
`;


const KakaoLogin = styled.button`
    background-color: #FEE500;
    cursor: pointer;
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 10rem;
`;

const NaverLogin = styled.button`
    background-color: #03c75a;
    color: white;
    width: 4.4rem;
    height: 4.4rem;
    cursor: pointer;
    border-radius: 10rem;
`;

const AppleLogin = styled.button`
    background-color: black;
    color: white;
    width: 4.4rem;
    height: 4.4rem;
    cursor: pointer;
    border-radius: 10rem;
`;


const SocialLoginGroup = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3.5rem;
    width: 100%;
`;

const CustomBtn = styled.button`
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 9rem;
    height: 100%;

    flex: none;
    padding: 0.8rem 1.6rem;
    font-size: 1.6em;
    font-weight: 400;
    font-family: Spoqa Han Sans Neo;

    letter-spacing: -1.28px;
    
    border-radius: 4px;
    background-color: ${props => props.disabled ? 'gray' : '#CDB36E'};
    color: white;
    white-space: nowrap;
`;

const RoundedBtn = styled.button`
    display: flex;
    width: 148px;
    height: 48px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    border-radius: 500px;
    background: #CDB36E;
    color: #FFF;
    cursor: pointer;

    span {
        color: #FFF;
        font-family: Spoqa Han Sans Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    &:active {
        background-color: #black;
        color: white;
    }
    &:focus {
        background-color: black;
        color: white;
    }

`;


const SocialIcons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.6rem;
    width: 100%;
    height: 100%;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 7.2rem;
`;

const TextContainer  = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 0.9rem;
    width: 100%;
    height: 5rem;

    input {
        display: flex;
        font-size: 1.55rem;
        letter-spacing: 0.1rem;
        width: 248px;
        height: 100%;
        border: 0.1rem solid #747C87;
        padding: 1.6rem 1.6rem;
        filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12));
    
        &:focus {
            outline: none;
        }
    }
    // 경고 메세지
    span {
        color: #1E52D6;
        font-family: Spoqa Han Sans Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.96px;
    }
`;

const Labelcon = styled.div`
    align-self: flex-start;
    label {
        color: #222;
        font-family: Spoqa Han Sans Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -1.28px;
    }

    span {
        color: #FF7235;
        font-family: Spoqa Han Sans Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1.28px;
    };
`;

const TextContainer2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.9rem;
    width: 100%;


    input {
        display: flex;
        font-size: 1.55rem;
        letter-spacing: 0.1rem;
        width: 100%;
        height: 100%;
        border: 0.1rem solid #747C87;
        padding: 1.6rem 1.6rem;
        filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12));
    
        &:focus {
            outline: none;
        }
    }
    `;

const InputWrapper3 = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const SplitterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    text-align: center;
    font-family: Spoqa Han Sans Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.28px;


    &::before,
    &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #000;  /* 수평선의 색상을 설정합니다 */
    }

    &::before {
        margin-right: 22px;
    }

    &::after {
        margin-left: 22px;
    }
`;

const SplitterContainer2 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    &::before,
    &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #000;
    }
`;


const SocialIconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    height: 100%;
    span {
        color: #757E89;
        text-align: center;
        font-family: Spoqa Han Sans Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -1.28px;
    }
`;

const InputContainer1 = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;

    span {
        color: ${props => props.color};
        font-family: Spoqa Han Sans Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.96px;
    }
`;
const InputField = ({  type, register, error, isEmailDisabled,placeholder, autoComplete="on", onChange}) => (
    <>
        <input type={type} {...register} 
        disabled={isEmailDisabled} // disabled 속성 설정
        style={{ backgroundColor: isEmailDisabled ? 'gray' : 'white' }} 
        autoComplete={autoComplete}
        onChange={onChange}
        placeholder={placeholder}
        />
        {error && <span className="error">{error.message}</span>}
    </>
);


const EmailSignup = () => {
    const [isEmailVerified, setEmailVerified] = useState(false);
    const [isEmailDisabled, setEmailDisabled] = useState(false);
    const [isEmailSent, setEmailSent] = useState(false);
    const [signupForm,setSignupForm] = useRecoilState(signupFormState);
    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        watch,
        trigger,
    } = useForm({ mode: 'all' });

      
    const onSubmit = async () => {
        // 마지막 유효성 검사
        if (Object.values(errors).some(Boolean)) return;
        if (!isEmailVerified) return;
        if (isEmailDisabled === false) {
            return;
        }
        
        setSignupForm((prevform) => ({
            ...prevform,
            lastsignupstage: 3, // 다음 단계로 이동
            email: watch('email'),
            password: watch('password'),
            passwordConfirm: watch('passwordConfirm'),
            referal: watch('referal'),
        }));
    };

    const handleVerificationCodeChange = async (code) => {
        const formData = new FormData();
        try {
            formData.append('email', watch('email'));
            formData.append('code', code);
            await postVerifyCheckEmail(formData);
            trigger('code');
            setEmailVerified(true);
            setSignupForm((prevform) => ({
                ...prevform,
                codeverify: true,
            }));
        } catch (error) {
            trigger('code', {
                type: 'manual',
                message: '인증번호가 일치하지 않습니다.'
            });
            setEmailVerified(false);
        }
    };

    const handleVerifyBtnClick = async (e) => {
        const formData = new FormData();
        e.preventDefault();
        if (errors.email) {
            return;
        }
        if (!watch('email')) {
            trigger('email', { message: '이메일을 입력해주세요.' });
            return;
        }
        try {
            formData.append('email', watch('email'));
            await postVerifyEmail(formData);
            trigger('email');
            setEmailSent(true);
            setEmailDisabled(true);
        } catch (error) {
            alert("다른 이메일로 시도해주세요.");
            trigger('email',{ message: '다른 이메일로 시도해주세요.' });
            setEmailSent(false);
            setEmailDisabled(false);
        }
    }
    return (
        <>
        <FormWrapper className="signupform">
            <HeaderProgressBar>
                <ProgressBar activePoint={signupForm.lastsignupstage}/>
            </HeaderProgressBar>

            <TItleWrapper>
                <TItleH1>가입방법선택</TItleH1>
                <TItlespan>회원가입 방법을 선택해주세요.</TItlespan>
            </TItleWrapper>

            <BodyWrapper>
                <SignForm>
                    <InputContainer>
                        <InputWrapper>
                            <Labelcon>
                                <label htmlFor="email">이메일
                                <span className="required"> (필수)</span>
                                </label>
                            </Labelcon>
                            <TextContainer>
                                <InputContainer1 color="#1E52D6">
                                    <InputField
                                        type="email"
                                        register={register("email", {
                                            required: "이메일을 입력해주세요.",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "이메일 형식으로 입력해주세요.(ex. ATICO@atico.com)"
                                            }
                                        })}
                                        error={errors.email}
                                        isDisabled={isEmailVerified}
                                        isEmailDisabled={isEmailDisabled}
                                    />
                                    {(isEmailDisabled) && <span>사용가능한 이메일입니다</span>}
                                </InputContainer1>
                                <CustomBtn type="submit" disabled={isEmailSent} onClick={handleVerifyBtnClick}>인증받기</CustomBtn>
                            </TextContainer>
                            {isEmailDisabled && !isEmailVerified && (
                                <>
                                <InputContainer1 color="#FF3535">
                                <InputField
                                    type="text"
                                    id="code"
                                    required
                                    register={register("code", {
                                        required: "인증번호를 입력해주세요",
                                        pattern: {
                                            value: /^[A-Za-z0-9]{6}$/,
                                            message: "인증번호는 6자리입니다",
                                        }
                                    })}
                                    error={errors.code}
                                    autoComplete="off"
                                    placeholder="올바른 인증번호를 입력해주세요"
                                    onChange={(e) => handleVerificationCodeChange(e.target.value)}
                                />
                                </InputContainer1>
                                {/* {errors.code && <span className="error">{errors.code.message}</span>} */}
                                </>
                            )}
                        </InputWrapper>
                        <InputWrapper2>
                            <TextContainer2>
                                <Labelcon>
                                    <label htmlFor="password">비밀번호
                                    <span className="required"> (필수)</span>
                                    </label>
                                </Labelcon>
                                <InputContainer1 color="#FF3535">
                                    <InputField
                                        type="password"
                                        register={register("password", {
                                            required: "비밀번호를 입력해주세요.",
                                            minLength: {
                                                value: 8,
                                                message: "*숫자,영문,특수문자 조합 최소 8자를 조합해주세요.",
                                            }
                                        })}
                                        error={errors.password}
                                    />
                                </InputContainer1>
                                
                            </TextContainer2>
                            <TextContainer2>
                                <InputContainer1 color="#FF3535">
                                    <InputField
                                        type="password"
                                        register={register("passwordConfirm", {
                                            required: "비밀번호를 입력해주세요.",
                                            validate: (value) => {
                                                return (
                                                value === watch('password') || "비밀번호가 일치하지 않습니다."
                                                );
                                            },
                                            })}
                                        error={errors.passwordConfirm}
                                    />
                                </InputContainer1>
                            </TextContainer2>
                        </InputWrapper2>
                        <InputWrapper3>
                            <TextContainer2>
                                <Labelcon>
                                    <label htmlFor="text">친구 초대 추천인 아이디
                                    </label>
                                </Labelcon>
                                <InputField
                                    type="text"
                                    register={register("referal")}
                                />
                            </TextContainer2>
                        </InputWrapper3>
                    </InputContainer>
                </SignForm>
            </BodyWrapper>

            <TextandSocialContainer>
                <SplitterContainer>
                    SNS 가입
                </SplitterContainer>

                <SocialLoginGroup>
                    <SocialIcons>
                        <SocialIconWrapper>
                            <KakaoLogin>
                                <SnsLoginButton social='kakao'/>
                            </KakaoLogin>
                            <span>카카오</span>
                        </SocialIconWrapper>
                        <SocialIconWrapper>
                            <NaverLogin>
                                <SnsLoginButton social='naver'/>
                            </NaverLogin>
                            <span>네이버</span>
                        </SocialIconWrapper>
                        <SocialIconWrapper>
                            <AppleLogin>
                                <SnsLoginButton social='apple'/>
                            </AppleLogin>
                            <span>애플</span>
                        </SocialIconWrapper>
                    </SocialIcons>
                </SocialLoginGroup>

                <SplitterContainer2 />
            </TextandSocialContainer>

            <SubmitWrapper>
                <ButtonContainer>
                    <RoundedBtn disabled={!isValid} onClick={handleSubmit(onSubmit)}><span>다음</span></RoundedBtn>
                </ButtonContainer>
            </SubmitWrapper>
        </FormWrapper>
        </>
    );
};  


export default EmailSignup;

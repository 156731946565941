import React from "react";
import styled from "styled-components";

import useMediaQuery from '@mui/material/useMediaQuery';


// images
import promotionimage1 from '../assets/1.png';
import sample2 from '../assets/smaple2.webp';
import small1 from '../assets/small1.webp';
import small2 from '../assets/small2.webp';
import small3 from '../assets/small3.webp';
import pr1 from '../assets/pr1.webp';
import pr2 from '../assets/pr2.webp';
import pr3 from '../assets/pr3.webp';
import pr4 from '../assets/pr4.webp';
import tip1 from '../assets/tip1.png';
import tip2 from '../assets/tip2.png';
import smaple from '../assets/smaple.jpg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Contents = styled.div`
    margin-top: 3rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
`;

const SectionContainer = styled.div`
    padding: 2rem 8rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    
    @media (max-width: 1015px) {
        
    }
`;

const HeadSectionContainer = styled.div`
        padding-top: 5.2rem;
        gap: 3rem;
        cursor: pointer;
        @media (max-width: 1015px) {
            
        }
`;

const NoticeSectionContainer = styled.div`  
    padding: 2rem 8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    gap: 3rem;
    width: 100%;
    @media (max-width: 1015px) {
        
    }
`;
const BigWallDiv = styled.div`

    background-color: transparent;

    &:hover .swiper-button-next,
    &:hover .swiper-button-prev {
        opacity: 0.4;  
        color: white;
        width: 5.6rem;
        height: 5.6rem;
        justify-content: center;
        background-color: #121212;
    }

    .swiper-button-next,
    .swiper-button-prev {
        opacity: 0;
        transition: opacity 0.5s smooth-fading;
    }

`;

const HookingTitle1 = styled.div`
    display:flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    font-size: 3.5rem;
    font-weight: 650;
    color: #000000;
    padding: 3rem;
`;

const HookingTitle2 = styled.div`
    display:flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    font-size: 3rem;
    font-weight: 500;
    color: #000000;
    padding: 5rem;
`;

const SmallWallDiv = styled.div`
    display:flex;
    img {
        width: 100%;
        height: auto;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 1rem;
`;
const BigImage = styled.div`
    display: flex;
    img {
        width: 100%;
        height: auto;
    }
`;
const RowImage = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap : 2rem;
    width: 100%;
    padding: 1rem 0;
    
`;
const StyledImg = styled.img`
    width: ${props => `calc((100% - ${(props.numImages - 1) * 2}rem) / ${props.numImages})`};
    height: auto;
`;

const TipImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap : 2rem;
    img {
        width: 50%;
        height: auto;
    }
`;

const SwiperSlideC = styled(SwiperSlide)`
    display: flex;
    position: relative;
    img {
        width: 100%;
        max-height: 75rem;
        height: auto;
        object-fit: cover;
    }



`;

const NoticeBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    flex:1;
`;

const CsBox = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    font-weight: 500;
    flex:1;
`;

const TitleBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.5rem;
    font-size: 2rem;
    font-weight: 600;
    border-bottom: 0.2rem solid #000000;
`;
const Noticeli = styled.li`
    display: flex;
    align-items: flex-start;
    font-size: 1.5rem;
    white-space: nowrap;
    ::before {
        content: "•";
        display: inline-block;
        width: 1em;
    }
`;

const Csdiv = styled.ul`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
`;

const Csa = styled.a`
    display: flex;
    padding: 1rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: #000000;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
        text-decoration: underline;
        color: #000000;
    }
   
    ::before {
        content: "•";
        width: 1em;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }

`;


const Recommend = () => {
  const matches = useMediaQuery('(max-width: 1015px)');
  const BigImages = [smaple, smaple];
  const images = [small1, small2, small3, small1];
  const prdtimages = [pr1, pr2, pr3, pr4];
  const tipsimage = [tip1, tip2];
  return (
    <>
    <Contents>
      {/* 모바일 */}
      {matches ? (
        <SectionContainer>
        </SectionContainer>
      ) : (
        <>
        <HeadSectionContainer>
            <BigWallDiv>
                <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={10}
                slidesPerView={1}
                loop={true}
                navigation
                pagination={{ clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                >
                {BigImages.map((image, index) => (
                    <SwiperSlideC key={index } >
                    <img src={image} alt={`Slide ${index + 1}`} />
                    </SwiperSlideC>
                ))}
                </Swiper>
            </BigWallDiv>
        </HeadSectionContainer>

        <SectionContainer>
            <HookingTitle1>인테리어 혼자 고민 마시고 아티코에서 상담하세요!</HookingTitle1>
            <SmallWallDiv>
                <img src={sample2} alt="sample2" />
            </SmallWallDiv>
        </SectionContainer>
        <SectionContainer>
            <HookingTitle2>인테리어 트렌드 제안</HookingTitle2>
            <ImageContainer>
                <BigImage>
                    <img src={promotionimage1} alt="promotionimage1" />
                </BigImage>
                <RowImage>
                {images.map((image, index) => (
                    <StyledImg src={image} alt={`image${index + 1}`} numImages={images.length} key={index} />
                ))}
                </RowImage>
            </ImageContainer>
        </SectionContainer>
        <SectionContainer>
            <HookingTitle2>추천상품</HookingTitle2>
            <ImageContainer>
                <RowImage>
                {prdtimages.map((image, index) => (
                    <StyledImg src={image} alt={`image${index + 1}`} numImages={images.length} key={index} />
                ))}
                </RowImage>
            </ImageContainer>
        </SectionContainer>
        <SectionContainer>
            <HookingTitle2>인테리어 어디서부터 시작할지 모르겠다면?</HookingTitle2>
            <ImageContainer>
                <TipImage>
                {tipsimage.map((image, index) => (
                    <StyledImg src={image} alt={`image${index + 1}`} numImages={images.length} key={index} />
                ))}
                </TipImage>
            </ImageContainer>
        </SectionContainer>
        <NoticeSectionContainer>
            <NoticeBox>
                <TitleBox>공지사항</TitleBox>
                <Noticeli >
                    [당첨자 발표] 인테리어 상담신청 경품이벤트 발표
                </Noticeli>
                <Noticeli>
                    [ATICO LIVE] 11월 17일 LIVE 방송 사은품 안내
                </Noticeli>
                <Noticeli>
                    개인정보처리방침 변경( 20203.11.31 예정
                </Noticeli>
            </NoticeBox>
            <CsBox>
                <TitleBox>고객센터</TitleBox>
                <Csdiv>
                    <Csa href="">자주찾는질문</Csa>
                    <Csa href="">1:1 문의</Csa>
                    <Csa href="">입점문의</Csa>
                    <Csa href="">비회원 주문조회</Csa>
                    <Csa href="">멤버쉽</Csa>
                    <Csa href="">대리점 개설 안내</Csa>
                </Csdiv>
            </CsBox>
        </NoticeSectionContainer>
        </>
      )
      }
    </Contents>
    </>
  );
};

export default Recommend;

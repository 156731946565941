
import React, { useCallback, useEffect, useRef } from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import styled from "styled-components";
import { modalVariants } from "utils/animation/variants";
import {
  boxBorderRadius,
  flexColumnCenter,
  flexRowCenter,
} from "utils/style/mixins";
import { IoMdClose } from "react-icons/io";
import { useState } from "react";
import Button from "element/Button";
import SelectArea from "./SelectArea";
import { useForm } from "react-hook-form";
import { changeState, isFilterState, searchValueState } from "store/atoms";
import { DateWrapper, CustomDatePicker } from "./DetailSubmit";
import { ko } from "date-fns/esm/locale";

import CustomSlider from 'element/Slider';

const ModalWrapper = styled.div`
  z-index: 999;
  position: fixed;
  ${flexRowCenter};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.35);

  overflow-y: auto;
`;

const ModalLayout = styled.section`
  position: relative;
  overflow-y: auto;
  display: flex;
  width: 56rem;
  height: auto;
  ${boxBorderRadius}
  ${flexColumnCenter}
  background-color: white;
  padding: 2rem 3rem;
  gap: 1.2rem;
`;

const TitleBox = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 0 2rem;
  border-bottom: 0.2rem solid ${(props) => props.theme.borderColor};

  h1 {
    
    font-size: 1.8rem;
    font-weight: 600;
  }
`;

const SearchForm = styled.form`
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const FilterTitle = styled.span`
    text-align: left;
    line-height: 4rem;
    font-size: 1.5rem;
    margin-top: 1rem;
  `;
const CloseIcon = styled(IoMdClose)`
  position: absolute;
  left: 0.2rem;
  transform: translateY(-50%);
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  button {
    white-space: nowrap;
    font-size: 1.4rem;
    font-weight: 500;
    text-decoration: none;
    background-color: transparent;
    color: black;
    border-bottom: $(props) => 2px solid rgb(42, 48, 55);};
  }
`;


const FilterSearch = () => {
  const modalRef = useRef(null);
  const [area, setArea] = useState("");
  const { handleSubmit } = useForm();
  const setSearchValue = useSetRecoilState(searchValueState);
  const setFilterModal = useRecoilState(isFilterState);

  const date = new Date();
  let oneDay = 24 * 60 * 60 * 1000;
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(new Date(date.getTime() + oneDay));
  const setChange = useSetRecoilState(changeState);

  const handleClickOutside = useCallback((e) => {
    // click modalWrapper과 modallayout 사이 공간 클릭 시 모달창 닫기
    console.log("CLICK OUTSIDE FROM FILTER SEARCH :", e.target)
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setFilterModal(false);
    }
  }, [modalRef, setFilterModal]);

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);

    //BUTTON CLICK EVENT
    const navLinks = document.querySelectorAll('button a');
    navLinks.forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault(); // prevent the default action

        // Scroll to the corresponding section
        const section = document.querySelector(link.getAttribute('href'));
        section.scrollIntoView({ behavior: 'smooth' });
      });
    });


    // Get all the sections
    const sections = document.querySelectorAll('section');
    const options = {
      root: null, // relative to the viewport if null
      threshold: 0.7, // percentage of the target visible in observed area to trigger callback
    };

    // Callback function
    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        console.log("ENTRY FROM FILTER SEARCH :", entry)
        // Get the nav link corresponding to this section
        const navLink = document.querySelector(`button a[href="#${entry.target.id}"]`);

        if (entry.isIntersecting) {
          // If section is in viewport, add active class to nav link
          navLink?.classList.add('active');
        } else {
          // If section is not in viewport, remove active class
          navLink?.classList.remove('active');
        }
      });
    };

    // Create the observer
    const observer = new IntersectionObserver(observerCallback, options);

    // Start observing all sections
    sections.forEach((section) => {
      observer.observe(section);
    });
  }, [modalRef,handleClickOutside]);

  const onSubmit = (data) => {
    const value = {
      minPrice: data.minPrice,
      maxPrice: data.maxPrice,
      
      adminDistrict: area,
      startDate: `${startDate.getFullYear()}-${String(
        startDate.getMonth() + 1
      ).padStart(2, "0")}-${String(startDate.getDate()).padStart(2, "0")}`,
      endDate: `${endDate.getFullYear()}-${String(
        endDate.getMonth() + 1
      ).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`,
    };
    setSearchValue(value);
    setFilterModal(false);
  };

  return (
    <ModalWrapper
      ref={modalRef}
    >
      <ModalLayout variants={modalVariants} initial="start" animate="animate" >
        <TitleBox>
          <CloseIcon size={23} onClick={() => setFilterModal(false)} />
          <h1 className="title">필터</h1>
        </TitleBox>
        <ButtonWrapper>
          <Button type={true} isBackground={false}>
            <a href="#section1">지역</a></Button>
          <Button type={true} isBackground={false}>
            <a href="#section2">준공년도</a></Button>
          <Button type={true} isBackground={false}>
            <a href="#section3">최근매매가</a></Button>
          <Button type={true} isBackground={false}>
            <a href="#section4">세대수</a></Button>
        </ButtonWrapper>

        <SearchForm onSubmit={handleSubmit(onSubmit)}>
          <section id="section1">
          <FilterTitle>지역</FilterTitle>
          <SelectArea area={area} setArea={setArea} />
          </section>
          <section id="section2">
          <FilterTitle>준공년도</FilterTitle>
          <DateWrapper>
            <CustomDatePicker
              minDate={new Date()}
              dateFormat="yyyy. MM. dd"
              selected={startDate}
              locale={ko}
              placeholderText="체크인"
              onChange={(date) => {
                setStartDate(date);
                setChange(true);
              }}
            />
            <CustomDatePicker
              minDate={startDate}
              dateFormat="yyyy. MM. dd"
              selected={endDate}
              locale={ko}
              placeholderText="체크아웃"
              onChange={(date) => {
                setEndDate(date);
                setChange(true);
              }}
            />
          </DateWrapper>
          </section>
          <section id="section3">
          <FilterTitle>최근매매가</FilterTitle>
          <CustomSlider/>
          </section>
          <section id="section4">
          <FilterTitle>세대수</FilterTitle>
          <CustomSlider />
          </section>
          <Button type={true} isBackground={true}>
            검색
          </Button>
        </SearchForm>
      </ModalLayout>
    </ModalWrapper>
  );
};

export default FilterSearch;

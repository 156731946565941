import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AiOutlineUserAdd } from "react-icons/ai";
import { CgMenuRightAlt } from "react-icons/cg"; 
import useMediaQuery from '@mui/material/useMediaQuery';
import Csicon from "assets/csicon.svg";
import mlogo from 'assets/logos/mlogo.svg';

import {
  HeaderFooterStyle,
  SwipeHeaderFooterStyle,
} from "utils/style/mixins";

import {
  useSetRecoilState, useRecoilState
} from "recoil";

import {
  isLoginModalState,
  isMyPageModalState,
  userNamePersistState,
  // isUserState
} from "store/atoms";

const Header = (height) => {
  const matches = useMediaQuery('(max-width: 1015px)');
  const navigate = useNavigate();
  const setVisibleLoginModal = useSetRecoilState(isLoginModalState);
  const setVisibleMyPageModal = useSetRecoilState(isMyPageModalState);
  const [UserName, setUserName] = useRecoilState(userNamePersistState); // Use a selector
  const [open, setOpen] = useState(false);
  // const [activeBtn, setActiveBtn] = useState(null); // 카테고리 hover시 서브카테고리 보여주기
  const ref = useRef(null);

  // 스크롤 이벤트
  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 200 ) {
          setScrolled(true);
      } else {
          setScrolled(false);
      }
  }

  useEffect(() => {
      window.addEventListener('scroll', handleScroll)
  })

  const handleStorageChange = (e) => { 
    if (e.key && e.storageArea.nickname) {
      setUserName(e.storageArea.nickname);
      renderLoginButton();
    }
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const renderLoginButton = () => {
    if (UserName) {
      setVisibleMyPageModal(true);
      setVisibleLoginModal(false);
    } else {
      setVisibleLoginModal(true);
      setVisibleMyPageModal(false);
    }
  };

  return (
    <>
      {
      matches ? (
          <SwipeHeaderContainer scrolled={scrolled}>
            <LogoHamburgerContainer>
              <HeaderLogoImg src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/blacklogo.svg'} title="logo" onClick={() => navigate('/')}/>
              {/* <AppearingImg scrolled={scrolled} src={logos} alt="logos"  /> */}
              <LoginUserButtonContainer>
                {/* 햄벅 섹션 */}
                <div ref={ref}>
                  <MenuButton>
                    <CgMenuRightAlt strokeWidth={1.3} size={25} color={"black"} aria-haspopup="menu" aria-expanded={open} onClick={() => renderLoginButton()}/>
                  </MenuButton>
                </div>
              </LoginUserButtonContainer>
            </LogoHamburgerContainer>
          </SwipeHeaderContainer>
        ) : (
          <HeaderContainer>
            <CategoryContainer>
              <LogoImg
                onClick={() => navigate("/")}
                src={mlogo} title="logo"
              />
              <CategoryBtn onClick={() => navigate("/services")}>
                서비스소개
              </CategoryBtn>
              <CategoryBtn onClick={() => navigate("/portfolio")}>
                포트폴리오
              </CategoryBtn>
              <CategoryBtn onClick={() => navigate("/createmyhome")}>
                우리집 만들기
              </CategoryBtn>
            </CategoryContainer>
            
            <LoginUserButtonContainer>
              <InqueriesContainer onClick={() => navigate('/interiorcall')}>
                <CsText>상담 신청하기</CsText>
                <img src={Csicon} alt="상담하기" width={22} height={22} />
              </InqueriesContainer>

              {/* 로그인 아이콘 */}
              { UserName ? (
                <div ref={ref}>
                <MenuButton onClick={renderLoginButton}>
                  <CgMenuRightAlt size={23} color={"black"} aria-haspopup="menu" aria-expanded={open} onClick={() => setOpen(!open)}/>
                </MenuButton>
              </div>
              ) : (
              <LoginButtonBox onClick={renderLoginButton}>
                <AiOutlineUserAdd size={22} color={"black"} />
                <LoginText>로그인 회원가입</LoginText>
              </LoginButtonBox>  
              )}
            </LoginUserButtonContainer>
          </HeaderContainer>
        )
      }
    </>
  )}

export default Header;

// const AppearingImg = styled.img`
//   display: ${({ scrolled }) => (scrolled ? 'block' : 'none')};
//   width: 22rem;
//   height: auto;
// `;

const LogoHamburgerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const HeaderLogoImg = styled.img`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 2.815rem;
  height: 2rem;
  justify-content: flex-start;
`;


const InqueriesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: black;
  border-radius: 2.3rem;
  padding: 1.3rem;
`;

const CsText= styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  padding: 0.3rem;
`;

const SwipeHeaderContainer = styled.div`
    z-index: 998;
    position: fixed;
    top: 0;
    width: 100%;
    color: #495057;
    ${SwipeHeaderFooterStyle}
    background-color: ${props => props.scrolled ? 'white' : 'transparent'};
    transition: background-color 0.3s;
    &:hover {
      background-color: white;
    }
`;

const HeaderContainer = styled.div`
  ${HeaderFooterStyle}
  z-index: 998;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  border-bottom: none;
  white-space: nowrap;
`;


const LogoImg = styled.img`
  cursor: pointer;
  width: 3.33rem;
  height: auto;
  margin-right: 5rem;
`;

const LoginUserButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const LoginButtonBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1.4rem;
  cursor: pointer;
`;
const LoginText = styled.span`
  font-size: 1.6rem;
  color: #666666;
  &:hover {
    color: black;
    font-weight: 800;
    background-color: transparent;
  }
`;
const CategoryContainer = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left:0; 
  justify-content: space-between;
  gap: 1.5rem;
  background-color: transparent;
`;

const CategoryBtn = styled.div`
  padding: 1rem 1.4rem;
  display: block;
  font-size: 16px;
  font-family: Noto sans KR;
  line-height: 1.5;
  cursor: pointer;
  font-weight: 400;
  color: #666666;
  
  &:hover {
    color: black;
    font-weight: 800;
    background-color: transparent;
  }
`;
const MenuButton = styled.div`
  justify-content: flex-end;
  display: flex;
  font-size: 1.5rem;
  padding: 0 1.4rem;
  line-height: 1.5;
  cursor: pointer;
  color: #666666;

  svg {
    font-weight: 900;
  }

  &:hover {
    color: #666666;
    font-weight: 600;
    background-color: transparent;
  }
`;

import { Route, Routes, useLocation,Navigate } from 'react-router-dom';
import GlobalStyle from 'utils/style/GlobalStyle';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { isFilterState, isLoginModalState, isTotalTagsModal, 
    isMyPageModalState, errorMessageState } from 'store/atoms';
import { useEffect } from 'react';

// Components
import Header from 'components/Header';
import Footer from 'components/Footer';
import LoginModal from 'components/LoginModal';
import MyPageModal from 'components/MyPageModal';
// import HotelAddModal from 'components/HotelAddModal';
import FilterSearch from 'components/FilterSearch';
import TotalTags from 'components/TotalTags';
import SnsLoginCallback from 'components/SnsLoginCallback';


// Pages
// import Home from 'pages/Home';
import MyPage from 'pages/MyPage';
import Recommend from 'pages/Recommend';
import Fav from 'pages/Fav';
import SelectPic from 'pages/styletest/SelectPic';
// import SelectPic2 from 'pages/styletest/SelectPic2';
// import SelectPic3 from 'pages/styletest/SelectPic3';
import SelectResult from 'pages/styletest/SelectResult';
// import SelectColor from 'pages/styletest/SelectColor';
// import SelectTag from 'pages/styletest/SelectTag';
// import SelectMat from 'pages/styletest/SelectMat';
import InteriorCall from 'pages/InteriorCall'
import Portfolio from 'pages/Portfolio';
import PortfolioDetail from 'pages/PortfolioDetail';
import Orders from 'pages/Orders';
import OrderDetails from 'pages/OrderDetails'
import Landing from 'pages/Landing';
import Privacy from 'pages/Privacy';
import Terms from 'pages/Terms';
import Intro from 'pages/Intro';
import Services from 'pages/Services';
import Main from 'pages/Main';
import Createmyhome from 'pages/Createmyhome';
// import Kintex from 'pages/notice/Kintex';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
//   min-height: 100vh;
//   height: auto;
  width: 100%;
  overflow-x: hidden;
`;

const ContentContainer = styled.div`
  flex: 1 0 auto;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
`;

function Layout() {
    const location = useLocation();
    const isVisibleLoginModal = useRecoilValue(isLoginModalState);
    const isVisibleMypageModal = useRecoilValue(isMyPageModalState);
    const isVisibleFilterModal = useRecoilValue(isFilterState);
    const isVisibleTagsModal = useRecoilValue(isTotalTagsModal);
    const errorMessage = useRecoilValue(errorMessageState);
    // const [NoticeHeight, setNoticeHeight] = useState(null);

    useEffect(() => {
        if (isVisibleLoginModal || isVisibleMypageModal || isVisibleFilterModal || isVisibleTagsModal) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }

        // errorMessage && alert(errorMessage);
    },[errorMessage, isVisibleLoginModal, isVisibleMypageModal, isVisibleFilterModal, isVisibleTagsModal])

    return (
    <>
    <MainContainer>
        <GlobalStyle />
        <Header/>
        <ContentContainer>
            <Routes>
                <Route element={<Main />} path="" />
                <Route element={<Landing />} path="/ourservices" />
                <Route element={<Recommend />} path="/recommend" />
                {/* <Route element={<Home />} path="/prop" /> */}
                <Route element={<Createmyhome />} path="/createmyhome" />
                <Route element={<Fav />} path="/fav" />
                <Route element={<SelectPic />} path="/fav/pick/1" />
                <Route element={<SelectPic />} path="/fav/pick/2" />
                {/* <Route element={<SelectPic3 />} path="/fav/pic3" /> */}
                <Route element={<SelectResult />} path="/fav/result/:id" />
                {/* <Route element={<SelectColor />} path="/fav/color" />
                <Route element={<SelectTag />} path="/fav/tag" /> */}
                {/* <Route element={<SelectMat />} path="/fav/mat" /> */}
                <Route element={<InteriorCall />} path="/interiorcall" />
                {/* {<Route element={<Products />} path="/products" />  */}
                <Route element={<Orders />} path="/orders" />
                <Route element={<OrderDetails />} path="/orders/:id" />
                <Route element={<Portfolio />} path="/portfolio" />
                <Route element={<PortfolioDetail />} path="/portfolio/:id" />
                <Route element={<SnsLoginCallback />} path="/login/callback" />
                <Route element={<MyPage />} path="/profile" />
                <Route element={<Intro />} path="/about" />
                <Route element={<Services />} path="/services" />
                <Route element={<Privacy />} path="/privacy" />
                <Route element={<Terms />} path="/terms" />
                {/* <Route element={<Kintex />} path="/koreabuildweek" /> */}
                <Route element={<Navigate to="/" />} path="/*" />
            </Routes>
        </ContentContainer>
        {location.pathname !== '/prop' && 
        location.pathname !== '/interiorcall' && 
        location.pathname !== '/fav/pick/1' &&
        location.pathname !== '/fav/pick/2' && 
        location.pathname !== '/koreabuildweek' &&
        location.pathname.includes('fav/result') === false &&
        <Footer />}
        
        {isVisibleLoginModal === true && <LoginModal />}
        {isVisibleMypageModal === true && <MyPageModal />}
        {/* {isVisibleHotelModal === true && <HotelAddModal />} */}
        {isVisibleFilterModal === true && <FilterSearch />}
        {isVisibleTagsModal === true && <TotalTags />}
        {/* {errorMessage && alert(errorMessage)} */}
    </MainContainer>
    </>
    );
  }

export default Layout;

import { AuthProvider } from "utils/func/AuthContext";
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from 'utils/style/theme';
import Layout from 'Layout';
import AdminRoute from 'AdminRoute';

function App() {
    return (
        <ThemeProvider theme={theme}>
        <AuthProvider>
        <Routes>
            <Route element={<Layout />} path="/*" />
            <Route element={<AdminRoute />} path="/aticoadmin/*" />
        </Routes>
        </AuthProvider>
        </ThemeProvider>
    );
}

export default App;

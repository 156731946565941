const { default: styled } = require("styled-components");

const StrokeLineContainer = styled.section`
    display: flex;
    margin-top: ${(props) => props.marginTop ? props.marginTop : '6rem'};
    width: 100%;
    display: flex;
    justify-content: ${(props) => props.position};
    align-items: center;
    padding: ${(props) => props.padding};
`;

const StrokeLineWrapper = styled.div`
    display: flex;
    height: ${(props) => props.height ? props.height : '0.3rem'};
    width: ${(props) => props.width};
    background-color: ${(props) => props.Linecolor};
`;

const StrokeLine = ({Linecolor, width, position, marginTop, height, padding}) => {
    return (
        <StrokeLineContainer position={position} marginTop={marginTop} padding={padding}>
            <StrokeLineWrapper Linecolor={Linecolor} width={width} height={height}/>
        </StrokeLineContainer>
    );
};

export default StrokeLine;
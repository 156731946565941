
import React, { useEffect } from 'react';
import { Box, Modal } from '@mui/material';
import styled from 'styled-components';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const StyledModal = styled(Modal)`
    overflow-y: auto;

    &::-webkit-scrollbar {
        background-color: transparent;
        width: 0.5rem;
        border-radius: 10px;
        position: absolute;
    }
    
    &::-webkit-scrollbar-track {
        background: transparent;  // 수정된 코드
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
      
    &:hover::-webkit-scrollbar-thumb {
        background-color: #555;
        width: 0.3rem;
        border-radius: 10px;
    }
`;
    


  
const BigPicModal = ({ open, handleClose, selectedImage }) => {
    const handleKeyDown = (e) => {
        if (e.keyCode === 27) {
            handleClose();
        }
    };
    useEffect(() => {
        const a = window.addEventListener('keyup', handleKeyDown);
        return () => a;
    });

    return (
        <StyledModal
            open={open}

            aria-labelledby="bigsize-images"
            aria-describedby="customer can see bigger size images"
            >
            <Box sx={{
                backgroundColor: 'white',
                ":focus": {
                    outline: 'none',
                }
            }}>
                <ModalWrapper>
                    <Top>
                        <ArrowBackIosNewIcon fontSize='large' onClick={() =>handleClose() }/>
                    </Top>
                    <Body>
                        {selectedImage && selectedImage.map((image, index) => {
                            return (
                            <>
                            <img key={index} src={image.file} alt={image.description} />
                            <span className='desc'>{image.description}</span>
                            </>
                            )
                        }
                        )}
                    </Body>
                </ModalWrapper>
            </Box>
        </StyledModal>
    );
};
export default BigPicModal;

const ModalWrapper = styled.section`
    display: flex;
    padding: 2rem;
    flex-direction: column;
    overflow-y: auto;
    
`;

const Top = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;

    color: #707072;
    svg {
        cursor: pointer;
    }

`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 70vw;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    padding: 3rem 0;
    
    img {
        width: 100%;
        height: auto;
    }
    span {
        font-size: 1.2rem;
    }
`;
import { AuthContext } from "utils/func/AuthContext";

import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import CatSpinner from 'components/CatSpinner';
import { useQuery } from 'react-query';
import { getRandomFavPic, postFavPicCheckList } from 'utils/api/api';
import { STYLES } from "../../store/atoms";

import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import heartlike from 'assets/mobile/heartlike.svg';
import heart from 'assets/mobile/heart.svg';

const MainContentWrapper = styled.section`
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

const Contents = styled.div`
    
    display: flex;
    width: 100vw;
    @media ${props => props.theme.mediaQuery.desktop} {
      margin-top: 8rem;
      height: 100%;
      overflow-y: hidden;
    };
    @media ${props => props.theme.mediaQuery.tablet} {
      margin-top: 8rem;
      height: calc(100vh - 8rem);
      overflow-y: hidden;
    };
    @media ${props => props.theme.mediaQuery.phone} {
      margin-top: 6rem;
      height: calc(100vh - 6rem);
      overflow-y: hidden;
      overflow-x: hidden;
    };

`;

const TasteSection = styled.section`
  display: flex;
  width: 100%;
  height: 100%;

  @media ${props => props.theme.mediaQuery.desktop} {
    padding: 3rem 8rem;
    flex-direction: column;
    flex-wrap: wrap;
  };

  @media ${props => props.theme.mediaQuery.tablet} {
    padding: 0 4rem;
    flex-direction: column;
    flex-wrap: wrap;
  };

  @media ${props => props.theme.mediaQuery.phone} {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  };
`;

// const GridContainer = styled(Grid)`
//     display: grid;
//     grid-template-columns: repeat(4, 1fr);
//     grid-auto-rows: minmax(100px, auto);
//     height: 100%;
// `;


const ImageContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    img { 
      object-fit: cover;
      -webkit-user-drag: none;
    
      @media ${props => props.theme.mediaQuery.desktop} {
        width: calc(92vw / 3 - 4rem);
        aspect-ratio: 96/54;
        height: auto;
        object-fit: cover;
        border-radius: 1rem;
        cursor: pointer;
        gap: 2rem;
      };

      @media ${props => props.theme.mediaQuery.tablet} {
        width: calc(92vw / 2 - 4rem);
        aspect-ratio: 96/54;
        height: auto;
        object-fit: cover;
        border-radius: 1rem;
        cursor: pointer;
      };

      @media ${props => props.theme.mediaQuery.phone} {
          width: 100%;
          height: fit-content;
      };
    }
`;

const Tasteh1 = styled.h1`
    font-size: 3rem;
    font-weight: 500;
    color: #000000;
`;


const MTasteh1 = styled.h1`
    font-size: 2rem;
    font-weight: 900;
    font-family: Pretendard;
    color: #222222;
`;

const Tastespan2 = styled.span`
    font-size: 2rem;
    font-weight: 500;
    color: #747C87;
`;

const MTastespan2 = styled.span`
    font-size: 1.3rem;
    font-weight: 500;
    font-family: Pretendard;
    color: #222222;
`;
   
   
const BtnDiv = styled.div`
    display: flex;
    justify-content: center;
    gap: 4rem;
    padding: 4rem;
`;
const MBtnDiv = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5.6rem;
    z-index: 1;
`;

// const PreBtn = styled.button`
//     font-size: 1.8rem;
//     font-weight: 500;
//     width: 15rem;
//     padding: 0 1rem;
//     background-color: white;
//     border: 1px solid black;
//     border-radius: 1.5rem;
//     cursor: pointer;
// `;

const AftBtn = styled.button`
    font-size: 1.8rem;
    font-weight: 500;
    padding: 1rem;
    width: 15rem;
    background-color: ${props => props.disabled ? 'F7F7F7' : 'black'};
    color: white;
    border: 1px solid ${props => props.disabled ? 'transparent' : 'black'};
    border-radius: 1.5rem;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

const MAftBtn = styled.button`
    font-size: 2rem;
    font-weight: 900;
    font-family: Pretendard;
    text-align: right;
    padding: 0 3.6rem;
    background-color: ${(props) => props.show > 2 ? '#E0D7C0' : 'rgba(219, 219, 219, 1)'};
    color: white;
    width: 100%;
    height: 100%;
`;
const MTextDiv  = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    gap : 1rem;
`;

const TextDiv  = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: left;
    white-space: nowrap;
    gap : 1rem;
    padding-bottom: 3rem;
`;

const ProgressBar = styled.div` 
    width: 100%;
    height: 0.5rem;
    background-color: #E5E5E5;
    border-radius: 1rem;
    margin-bottom: 2rem;
`;

const ImageScrollView = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    gap: 2rem;

    @media ${props => props.theme.mediaQuery.phone} {
      height: calc(100vh - 24.2rem);
      overflow-y: scroll;
    };
`;

const MProgessStage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5rem;
`;

const FavIcon = styled.div`
  position: absolute;
  top: 2rem;
  left: 1rem;
  width: fit-content;
  height: fit-content;
  display: flex;
  cursor: pointer;
  z-index: 1;
  img {
    width: 100%;
    height: 100%;
  
  }
`;

const ColorBall = styled.div`
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 2px solid #E0D7C0;
    background-color: ${(props) => props.color};
    z-index: 1;
    ${props => props.style};
`;


const SelectPic = () => {
  const navigate = useNavigate();
  const { logoutClear } = useContext(AuthContext);
  const matches = useMediaQuery('(max-width: 1024px)');
  const tabletmatches = useMediaQuery('(max-width: 1024px)');
  // const token = getCookie('jwt');
  const pageIndex = window.location.pathname.split('/').pop();
  // const [checkboxesState, setCheckboxState] = useRecoilState(selectedCheckboxesState);
  const [checkboxesState, setCheckboxState] = useState([]);
  const [apiImages, setapiImages] = useState([]);

  // API로부터 랜덤 이미지 가져오기
  const getFavPic = async () => {
    const promises = STYLES.map((style) => getRandomFavPic(style));
    const results = await Promise.all(promises);
    console.log("FAV PICS : ", results.flat());
    return results.flat();
  };

  const { data: randomfavimagelist, isError, error } = useQuery('randomfavpic', getFavPic, {
    retry: false,
    enabled: apiImages.length === 0,
  });//

  // const navigateToPage = (index) => {
  //     navigate(`/fav/pick/${index}`);
  // };

  const handleResultClick = async () => {
    // 선택한 이미지를 api로 보내기
    const token = localStorage.getItem('token');
    const flatten = Object.values(checkboxesState).flat();
    const postdatas  = {"favorite_pic" : flatten}
    const response = await postFavPicCheckList(postdatas, token);
    if (response?.code === "token_not_valid" || 
        response?.code === "ERR_NETWORK" || 
        response?.code === "ERR_BAD_REQUEST"||
        response?.statusText === "Unauthorized") {
        logoutClear();
    } else {
      navigate(`/fav/result/${response.data?.results_link}`);
    }
    
    
  }
  

  // 이미지 클릭시 체크박스 토글
  const handleImageClick = (index) => toggleCheckbox(index);
  const toggleCheckbox = (index) => {
    const currentPageCheckboxes = checkboxesState[pageIndex] || [];
    const checkboxIndex = currentPageCheckboxes.findIndex((i) => i === index);
    if (checkboxIndex !== -1) { // 체크해제
      setCheckboxState({
        ...checkboxesState,
        [pageIndex]: currentPageCheckboxes.filter((i) => i !== index),
      });
    } else if (currentPageCheckboxes.length < 3) { // 체크박스 3개 이하일 때만 추가
      setCheckboxState({
        ...checkboxesState,
        [pageIndex]: [...currentPageCheckboxes, index],
      });
    }
  };

  const calculateColor = (index) => {
    const currentPageCheckboxes = checkboxesState[pageIndex] || [];
    return currentPageCheckboxes.length > index ? '#E0D7C0' : 'white';
  };
 
  useEffect(() => {
    // if (checkboxesState[1] === undefined && pageIndex === 2) {
    //   alert('이전 페이지부터 다시 시작해주세요.');
    //   navigate('/fav');
    // }
    // if (!apiImages && pageIndex === 2) {
    //   alert('이전 페이지부터 다시 시작해주세요.');
    //   navigate('/fav');
    //   return;
    // }
    if (randomfavimagelist) {
      console.log("RANDOM FAV IMAGE LIST : ", randomfavimagelist)
      const datas = randomfavimagelist.map((datas) => datas.data.results);
      setapiImages(datas);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, checkboxesState, randomfavimagelist]);

  const renderError = () => (
    <MainContentWrapper>
      <div>
        <p>Error: {error.message}</p>
      </div>
    </MainContentWrapper>
  );

  // 로딩중일때
  const [loading, setLoading] = useState(true);
    useEffect(() => {
      apiImages && setLoading(false);
    }, [apiImages]);
    if (loading) {
        return <CatSpinner />
    }

  const renderContents = () => (
    <>
      <Contents>
      <TasteSection className="tastesection">
        {/* 모바일 헤더*/}
        {matches ? (
            <TextDiv>
              <MTextDiv>
                <MTastespan2>선호하는 이미지를 3개 선택해주세요!</MTastespan2>
                <MTasteh1>인테리어 취향테스트</MTasteh1>
              </MTextDiv>
              <MProgessStage>
                <ColorBall color={calculateColor(0)} />
                <ColorBall color={calculateColor(1)} />
                <ColorBall color={calculateColor(2)} />
              </MProgessStage>
            </TextDiv>
          ) : (
            <>
              <TextDiv>
                <Tastespan2>인테리어 취향 테스트</Tastespan2>
                <Tasteh1>마음에 드는 이미지를 3개 선택해주세요</Tasteh1>
              </TextDiv>
              <ProgressBar />
            </>
          )
        }
        <ImageScrollView className="Grid">
          {apiImages.map((datas) => {
            const image = datas[pageIndex];
            return (
              <ImageContainer className="imgcontainer">
                <FavIcon
                  onClick={() => handleImageClick(image.id)}
                  checked={checkboxesState[pageIndex]?.includes(image.id)}>
                  <img
                    src={checkboxesState[pageIndex]?.includes(image.id) ? heartlike : heart}
                    alt="heart"
                  />
                </FavIcon>
                <img
                  src={image.images}
                  alt={image.style}
                  onClick={() => handleImageClick(image.id)}
                />
              </ImageContainer>
            );
          })}
        </ImageScrollView>
        {tabletmatches ? 
          <>
            <MBtnDiv >
              {
                  // (checkboxesState[pageIndex]?.length + (checkboxesState[pageIndex-1]?.length)) >= 6 ? 
                  // <MAftBtn onClick={() => handleResultClick()} show={checkboxesState[pageIndex]?.length}>결과보기</MAftBtn> :
                  // checkboxesState[pageIndex]?.length === 3 ? 
                  // <MAftBtn onClick={() => navigateToPage(2)} show={checkboxesState[pageIndex]?.length}>다음</MAftBtn> : 
                  // <MAftBtn disabled>다음</MAftBtn>
                  checkboxesState[pageIndex]?.length === 3 &&
                  <MAftBtn onClick={() => handleResultClick()} show={checkboxesState[pageIndex]?.length}>결과보기</MAftBtn>
              }
            </MBtnDiv>
          </> : 
          <BtnDiv>
            {
                checkboxesState[pageIndex]?.length === 3 &&
                <AftBtn onClick={() => handleResultClick()}>결과보기</AftBtn>
            }
          </BtnDiv>
        }
      </TasteSection>
      </Contents>
    </>
  );
  return isError ? renderError() : renderContents();
};

export default SelectPic;

import React from 'react';
import styled from 'styled-components';
// import Sidebar from 'components/Sidebar';



// import { useHistory } from 'react-router-dom';
// import { getMember } from 'utils/api/member';
// import { getMemberList } from 'utils/api/member';
// import { getMemberCount } from 'utils/api/member';
// import { getMemberCountByDate } from 'utils/api/member';
// import { getMemberCountByGender } from 'utils/api/member';
// import { getMemberCountByAge } from 'utils/api/member';
// import { getMemberCountByRegion } from 'utils/api/member';
// import { getMemberCountByDevice } from 'utils/api/member';
// import { getMemberCountByOS } from 'utils/api/member';
// import { getMemberCountByBrowser } from 'utils/api/member';
// import { getMemberCountByDeviceType } from 'utils/api/member';
// import { getMemberCountByDeviceBrand } from 'utils/api/member';
// import { getMemberCountByDeviceModel } from 'utils/api/member';
// import { getMemberCountByDeviceBrowser } from 'utils/api/member';
// import { getMemberCountByDeviceOS } from 'utils/api/member';
// import { getMemberCountByDeviceOSVersion } from 'utils/api/member';


const Dashboard = () => {
    return (
        <Container>
            <Body>
                <BodyRight>
                    {/* <BodyLeftContentItemText>
                                기기OS버전별 회원수: {memberCountByDeviceOSVersion}
                            </BodyLeftContentItemText> */}
                </BodyRight>
            </Body>
        </Container>
    );
}

export default Dashboard;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;


const Body = styled.div`
    display: flex;
    flex: 1;
`;

const BodyRight = styled.div`
    display: flex;
    flex: 1;
`;


// const BodyRightContentItem = styled.div`

// `;

// const BodyRightContentItemTitle = styled.h2`

// `;
// const BodyRightContentItemText = styled.span`

// `;

// const BodyRightContentItemChart = styled.div`

// `;

// const BodyRightContentItemChartTitle = styled.h3`

// `;
// const BodyRightContentItemChartText = styled.span`

// `;

// const BodyRightContentItemChartGraph = styled.div`

// `;

// const BodyRightContentItemChartGraphTitle = styled.h4`

// `;

// const BodyRightContentItemChartGraphText = styled.span`

// `;

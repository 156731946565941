import { useEffect } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { isLandingDetailModal } from "store/atoms";
import { BsHouse, BsBadge3D } from "react-icons/bs";
import googleplay from "./../assets/googleplay.png";

import AppleIcon from '@mui/icons-material/Apple';
import "@fontsource/playfair-display";
// import "@fontsource/playfair-display/400.css";
// import "@fontsource/playfair-display/400-italic.css";



//components
import Bigwall from "components/Bigwall";

// images
import mainimg1 from "./../assets/mainimg1.png";
import mainimg2 from "./../assets/mainimg2.png";
import mainimg3 from "./../assets/mainimg3.png";
import wall2 from "./../assets/wall/wall2.png";
import exp1 from './../assets/exp1.png';
import exp2 from './../assets/exp2.png';
import exp3 from './../assets/exp3.png';
import exp4 from './../assets/exp4.png';
import exp5 from './../assets/exp5.png';
import upsidedown from './../assets/upsidedown.png';

import serviceimage1 from './../assets/serviceimage1.png';
import serviceimage2 from './../assets/serviceimage2.png';
import serviceimage3 from './../assets/serviceimage3.png';
import serviceimage4 from './../assets/serviceimage4.png';
import feature1 from './../assets/feature1.png';
import feature2 from './../assets/feature2.png';
import feature3 from './../assets/feature3.png';
import feature4 from './../assets/feature4.png';
import feature5 from './../assets/feature5.png';
import feature6 from './../assets/feature6.png';


//elems
import Block from "element/Block";
import BeigeButton from "element/BeigeButton";
import StrokeLine from "element/StrokeLine";

// Modal
import LandingDetailModal from "components/LandingDetailModal";

const Body = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const SectionContainer = styled.section`
    display: flex;
    flex-direction: column;
`;
const ServiceSectionContainer = styled.section`
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: #F3F0E7;
    margin-top: 30rem;
    padding: 10rem 10rem 20rem 10rem;
   
    width: 100%;
    flex-direction: column;
`;

const ServiceSEctionWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-width: 120rem;
    gap: 25rem;
`;

const Title = styled.h1`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000;
    font-family: "Playfair Display";
    font-size: 64px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 2.8px;
    white-space: nowrap;
    width: 100%;
    
`;

const TopTitle = styled.h1`
    display: flex;
    align-items: center;
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Noto Sans KR";
    font-size: 36px;
    font-style: normal;
    font-weight: 350;
    letter-spacing: 0.72px;
    width: 100%;
    justify-content: top-left;
`;

const TextContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center-left;
    flex-direction: column;
    color : white;

    .titletext  {
        h1 {
            color: #FFF;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: "Playfair Display";
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 3.2px;
        }
    }
    .titlespan {
        margin-top: 5rem;
        p {
            font-size: 22px;
            font-weight: 300;
            font-family: Gilroy;
            font-style: normal;
        }
    }
`;

const AppDownDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    height: 34px;
    justify-content: flex-start;
    margin-top: 2.5rem;
    gap: 2rem;
    background-color: transparent;

    div {
        width: 147px;
        height: 34px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.74);
        border-radius: 2rem;
        padding: 0.5rem 1rem;
        cursor: pointer;
    }

    div.icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
    }

    span {

        font-size: 1.6rem;
        color: black;
        font-weight: 600;
        font-family: Noto Sans KR;
    }
    img { 
        width: 3rem;
        height: 3rem;
    }
`;
const Blank = styled.div`
    display: flex;
    width: 100%;
    height: 4rem;
`;

// --------------------------------------------------------
const SectionContainer2 = styled.section`
    ${SectionContainer};
    gap: 3.4rem;
    margin-top: 10rem;
    padding: 6rem 11rem;
`;

const SectionContainerExs = styled.section`
    ${SectionContainer};
    gap: 3.4rem;
    margin-top: 10rem;
    padding: 10rem 11rem;
    background-color: #e5e7eb;
`;

// const GridSection = styled.section`
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     gap: 6vw;
//     margin-top: 1rem;
// `;

const GridSection2 = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    margin-top: 3rem;

    h1 {
        ${Title};
        justify-content: center;
    }
`;

const Image = styled.div`
    display: flex;
    flex: 1;
    align-items: right;
    justify-content: right;
    flex-direction: column;

    img {
        width: 450px;
        height: 450px;
    }
`;

const BlackTextContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 6rem;
    width: 100%;
    height: 100%;
    color: black;
    align-items: flex-start;

    
    .titlespan {
        p {
            font-size: 2rem;
            font-weight: 300;
            line-height: 150%;
            white-space: nowrap;

        }
    }
`;


// --------------------------------------------------------


const Section = styled.section`
    display: flex;
    padding: 0 4rem;
`;

const ImageRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;
    

    div.imgdiv {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    img {
        width: 70rem;
        height: auto;
    }

`;


const TextContainer3 = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    flex-direction: column;
    gap: 2rem;
`;

const TextContainer4 = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-end;
    text-align: right;

    flex-direction: column;
    gap: 2rem;
`;

const TopTtile = styled.h2`
    display: flex;
    color: #444;

    font-family: "Noto Sans KR";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 104%; /* 20.8px */
    white-space: nowrap;
`;

const Stepper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
        border-radius: 2.7rem;
        background-color: #E3DAC5;
        font-weight: 400;
        font-size: 1.5rem;
        padding: 1rem 2rem;
    };
`;

const FeatureTitle = styled.strong`
    display: flex;
    color: #000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Noto Sans KR";
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 126%; /* 80.64px */
    letter-spacing: 3.2px;
    white-space: nowrap;
`;
    
const Explain = styled.p`
    display: flex;
    color: #444;

    font-family: "Noto Sans KR";
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 139%; /* 27.8px */
    white-space: nowrap;
`;


// --------------------------------------------------------

const Bar = styled.div`
    display: flex;
    width: 100%;
    min-height: 23rem;
    background-image: url("https://cdn.imweb.me/thumbnail/20230619/4b32c8eb36f82.png");
    background-size: cover;
`;

// const DetailButton = styled.button` 
//     display: flex;
//     padding: 6px 20px;
//     border-radius: 0.5rem;
//     background-color: transparent;
//     color: black;
//     font-size: 1.2rem;
//     font-weight: 400;
//     font-family: NanumGothicCoding, Gilroy, "Apple SD Gothic Neo", "Malgun Gothic", "Nanum Gothic", "Noto Sans", "sans-serif";
//     border: 1px solid rgba(0, 0, 0, 0.2);
//     cursor: pointer;
// `;


const TextContainer5 = styled.div`

    display: flex;
    flex-direction: column;

    text-align: left;
    align-items: flex-start;
    gap: 2rem;
    padding: 6rem 10vw;
    color: black;

    .titlespan {
        p {
            color: #616161;
            font-family: "Noto Sans KR";
            font-size: 20px;
            font-style: normal;
            font-weight: 350;
            line-height: 170%; /* 34px */
        }
    }
`;

const TopTitle2 = styled.strong`
    display: flex;
    width: 100%;
    color: #000;
    font-family: "Noto Sans KR";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 48px */
    white-space: nowrap;
`;

const TopTitle5 = styled.strong`
    display: flex;
    width: 100%;
    color: #000;
    font-family: "Noto Sans KR";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 48px */
    white-space: nowrap;
    span {
        color: #000;
        font-family: "Noto Sans KR";
        font-size: 32px;
        font-style: normal;
        font-weight: 350;
        line-height: 150%;
    }
`;
//--------------------------------------------------------

const TopTitle3 = styled.h1`
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    white-space: nowrap;
    line-height: 1.6;
    color: #191f28;
`;

const TopTitle4 = styled.h1`
    text-align: center;
    font-size: 30px;
    font-weight: 500;
`;

const GridSection3 = styled.section`
    display: flex;
    margin-top: 3rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    h1 {
        ${Title};
        justify-content: center;
    }
`;

const Griditem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: calc((100% - 2rem)/3);
    max-width: 42rem;
    min-width: 32rem;
    height: 25rem;
    background-color: #f9f9f9;
    padding: 2rem 4rem;
    gap: 2rem;
    white-space: nowrap;
    img {
        width: 35px;
        height: 35px;
    }

    span{
        text-align: center;
        font-size: 1.5rem;
        font-weight: 400;
        font-family: Gilroy;
        color: rgba(54, 54, 54, 0.8);
        line-height: 2.4rem;
    }

`;

const Steps2 = styled.strong`
    display: flex;
    align-items: center;
    font-size: 2.4rem;
    font-family: NanumGothicCoding, Gilroy, "Apple SD Gothic Neo", "Malgun Gothic", "Nanum Gothic", "Noto Sans", "sans-serif";
    font-weight: 700;
    width: 100%;
    justify-content: center;
`;

// --------------------------------------------------------

const ImageRow2 = styled.div`   
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: top-center;
    justify-content: center;
    gap: 4rem;

`;
const RowSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 100%;
    gap: 2rem;

    .title {
        border-radius: 5rem;
        background-color: black;
        width: fit-content;
        padding: 1rem 2rem;
    }
    .title span {
        display: flex;
        color: white;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 12px;
        font-style: normal;
        font-weight: 350;
        line-height: 150%; /* 18px */
    }

    h1 {
        color: #000;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 37.5px */
        letter-spacing: 1.25px;
    }
    .content {
        color: #666;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 10px;
        font-style: normal;
        font-weight: 350;
        line-height: 150%; /* 15px */
    }
    img {
        width: 95px;
        height: 95px;
    }
`;

const ColContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    
    .rowimage1 {
        width: 39.3rem;
        height: auto;
    }
    .rowimage2 {
        width: 70rem;
        height: auto;
    }
`;

const ColContainer1 = styled.div`
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: space-between;
    gap: 20rem;
    padding: 0 10rem;
`;

const TextContainer6 = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    justify-content: space-between;
    padding: 2rem 0;
    color: black;

    span {
        font-size: 13px;
        font-weight: 300;
    }
    strong {
        font-size: 1.8rem;
        font-weight: 700;
        font-family: 'Gilroy','NanumGothicCoding','Apple SD Gothic Neo', 'Malgun Gothic','Nanum Gothic', 'Noto Sans','sans-serif';
        text-align: left;
        white-space: nowrap;
        margin-top: 1rem;
        color: #363636;
    }
`;

const Strong = styled.strong`
    display: flex;
    align-items: center;
    font-size: 2.5rem;
    font-weight: 500;
    font-family: NanumGothicCoding, Gilroy, "Apple SD Gothic Neo", "Malgun Gothic", "Nanum Gothic", "Noto Sans", "sans-serif";
    text-align: left;
    white-space: nowrap;
`;
// --------------------------------------------------------


const Griditem2 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;

    img {
        width: 100%;
        max-width: 38rem;
        height: 22rem;
        border-radius: 1.5rem;
    }

    span {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 400;
        font-family: Gilroy;
        color: rgba(54, 54, 54, 0.8);
        line-height: 2.4rem;
    }
`;

const Steps3 = styled.strong`
    display: flex;
    align-items: center;
    font-size: 2.4rem;
    font-family: NanumGothicCoding, Gilroy, "Apple SD Gothic Neo", "Malgun Gothic", "Nanum Gothic", "Noto Sans", "sans-serif";
    font-weight: 500;
    width: 100%;
    justify-content: center;
`;
// --------------------------------------------------------

const SectionContainer4 = styled.section`
    width: 100%;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12rem;
    gap: 3.4rem;
    padding: 6rem 3rem 12rem 3rem;
`;

const GridSection4 = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(31rem, 1fr));
  grid-gap: 1rem; 

  @media (min-width: 64rem) { /* Adjust breakpoint as needed */
    grid-template-columns: repeat(4, 31rem);
  }
`;

const Griditem3 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.1);
    }

    img {
        width: 31rem;
        height: 22.7rem;
    }
`;

const LeftContent = styled.div`
    display: flex;
    align-items: center;
    max-width: 144rem;
    justify-content: space-between;
    gap: 10rem;
    padding: 0 15rem;,
    width: fit-content;
    height: auto;
    text-align: left;
    justify-content: flex-start;
`;

const RightTopTitle = styled.strong`
    display: flex;
    width: 100%;
    font-size: 4.8rem;
    font-weight: Bold;
    font-family: Nano Sans KR;
    justify-content: flex-end;
`;

const RightContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10rem;
    text-align: right;
    justify-content: flex-end;
    width: auto;
    height: auto;
`;

const HeadSectionContainer  = styled.div`
    height: auto;
`;

const SectionContainer5 = styled.section`
    margin-top: 25rem;
    padding: 0 15rem;
    margin-left: auto;
    margin-right: auto;
    gap: 3rem;
    width: fit-content;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const LeftContent5 = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 5rem;
    line-height: 0.85;
    width: fit-content;
    height: auto;
`;

const ContentTitle = styled.span`
    display: flex;
    color: #000;
    white-space: nowrap;
    text-align: right;
    font-family: "Noto Sans KR";
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 2.8px;
    justify-content: flex-end;
`;


const RightContent5 = styled.div`
    display: flex;
    align-items: right;
    flex-direction: column;
    width: fit-content;
    height: auto;
    text-align: left;
    justify-content: flex-start;
`;


const TextContnet5 = styled.div`
    display: flex;
    align-items: left;
    white-space: nowrap;
    text-align: left;
    flex-direction: column;
    width: fit-content;
    height: auto;
    justify-content: flex-start;
    color: #666;
    font-family: "Noto Sans KR";
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    letter-spacing: 1px;
`;
const ImageCol = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: fit-content;
    height: 200px;
    justify-content: flex-start;
    align-items: center;
`;

const ServiceInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: left;
    justify-content: flex-start;
    span {
        color: #8B8B8B;
        font-family: "Noto Sans KR";
        font-size: 24px;
        font-style: normal;
        font-weight: 350;
        line-height: 150%; /* 36px */
    }
`;

const ServiceTitle = styled.h1`
    display: flex;
    justify-content: flex-start;
    color: #000;
    text-align: left;
    font-family: "Noto Sans KR";
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 2.8px;
    
`;
const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1400rem;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    margin-top: 10rem;
`;

const LeftContent1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20rem;
    width: 100%;
    height: 100%;
`;

const BlackButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40rem;
    height: 10rem;
    border-radius: 80.5px;
    background-color: #000;

    span {
        color: white;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 126%; /* 50.4px */
        letter-spacing: 2px;
        cursor: pointer;
    }
`;

const TextContainer2 = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    span {
        display: flex;
        width: auto;
        text-align: center;

        font-size: 1.6rem;
        font-weight: 300;
        font-family: Gilroy;
        color: black;
        line-height: 1.5;
    }
`;

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
`;


const Landing = () => {
    const [ModalVisibility, setModalvisibility] = useRecoilState(isLandingDetailModal);
    const UnderLine = () => {
        const UnderlineDiv  = styled.div`
            display: flex;
            width: 100%;
            padding: 5rem 0;
            height: 0.2rem;
            align-items: center;
            justify-content: center;
        `;

        const Underline = styled.div`
            display: flex;
            width: 20%;
            height: 0.2rem;
            align-items: center;
            justify-content: center;
            background-color: #000000;
        `;

        return (
            <UnderlineDiv>
                <Underline />
            </UnderlineDiv>
        );
    }

    const handleDetailButtonClick = (modalName) => {
        setModalvisibility(modalName);
    };
    
    useEffect(() => {
        const devenv = process.env.NODE_ENV;
        console.log("current env: ", devenv);
    }, []);

    const AppDownButtons = () => {
        return (
            <AppDownDiv>
                <div>
                    <div className="icon">
                        <AppleIcon fontSize="large" sx={{ color:'black' }}/>
                    </div>
                    <span>App store</span>
                </div>
                <div>
                    <img alt="playstore" src={googleplay} />
                    <span>Google Play</span>
                </div>
            </AppDownDiv>
        );
    }

    return (
    <>
    <HeadSectionContainer>
        <Bigwall backgroundColor={'#414141'} image={wall2}>
            <TextContainer>
                <TopTitle >하루만에 일어나는 마법같은 <br/> 홈 스타일링</TopTitle>
                {/* <div className="titletext">
                    <h1>Home styling</h1>
                </div> */}

                <StrokeLine Linecolor={'white'} width={'20%'} position={'flex-start'} marginTop ={'3rem'}></StrokeLine>

                <div className="titlespan">
                    <p>
                        스마트 AI를 통해 개인의 라이프스타일을 담은 세상에 단 하나,
                    </p>
                    <p>
                        꿈꾸던 나만의 맞춤 공간을 지금 당장 만나보세요.
                    </p>
                </div>
                <AppDownButtons/>
            </TextContainer>
        </Bigwall>
    </HeadSectionContainer>

    <Body>
        <BodyWrapper>
        <Blank />
        <SectionContainer2>
        <TopTitle3>혁신적인 인테리어 디자인 경험을 즉시 실현시킵니다.</TopTitle3>
            <Title>A dream house just for you</Title>
            <UnderLine />
            <TextContainer2>
                <span>메타디자인하우스는 세상 사람들 모두 각자만의 라이프스타일을 가지고 있다고 <br/> 생각하기에 개개인에게 맞춘 최적의 인테리어 디자인을 고민합니다.<br/><br/>
                단순히 인테리어를 넘어서 당신의 라이프스타일까지 생각하는 1:1 맞춤형 디자인<br/> 메타디자인하우스는 결국 '당신'입니다.<br/>
                계속해서 변화하는 환경과 지친 마음 속에서 진정으로 정착할 수 있는 당신만을<br/> 위한 안정된 보금자리를 위해 우리는 당신을 위해 존재할 것입니다.<br/><br/>
                AI를 기반으로 라이프스타일 분석과 실시간 3D시뮬레이션을 통해<br/> 상상만 하던 공간이 현실화되는 모습을 실시간으로 즉시, 보여드릴 것입니다.</span>
            </TextContainer2>
        </SectionContainer2>

        <SectionContainer>
            <Block height={'20rem'}/>
            <ColContainer1>
                <LeftContent>
                    <Image>
                        <img alt="img" src={mainimg1}></img>
                    </Image>
                    <BlackTextContainer>
                        <TopTitle2>25년 경력의 노하우</TopTitle2>
                        <div className="titlespan">
                            <p>25년동안의 인테리어 실무 경험으로 축적된 소비자 피드백 <br/>
        데이터를 기반으로 연구 개발되었습니다.</p>
                        </div>
                        <BeigeButton position="left" onClick={() => handleDetailButtonClick('career')}>자세히 보기</BeigeButton>
                    </BlackTextContainer>
                </LeftContent>

                <RightContent>
                    <BlackTextContainer>
                        <RightTopTitle>생성형 3D AI기반 <br/>
                            맞춤형 디자인 솔루션</RightTopTitle>
                        <div className="titlespan">
                            <p>당신의 생활공간을 재창조하는 AI 기반 3D 인테리어 디자인 플랫폼.<br/>
    사용자의 취향과 스타일을 AI가 분석하여 맞춤형 디자인을 제안하고,<br/>
    이를 실시간 3D 시뮬레이션으로 실현하는 서비스를 제공합니다.</p>
                        </div>
                        <BeigeButton position="right" onClick={() => handleDetailButtonClick('career')}>자세히 보기</BeigeButton>
                        </BlackTextContainer>
                    <Image>
                        <img alt="img2" src={mainimg2}></img>
                    </Image>
                </RightContent>

                <LeftContent>
                    <Image>
                        <img alt="img" src={mainimg3}></img>
                    </Image>
                    <BlackTextContainer>
                        <TopTitle2>공간 디자이너와 고객의<br />즉각적인 소통</TopTitle2>
                        <div className="titlespan">
                            <p>기존 ‘주거공간 3D 디자인 시장’은 디자이너와 고객과의 소통이 <br/>
                            어려워 고객의 시간적 효율과 만족도가 낮을 수밖에 없습니다. <br/>
                            아티코디자인은 해당 문제를 인식하고, 서비스를 제공합니다.</p>
                        </div>
                        <BeigeButton onClick={() => handleDetailButtonClick('career')}>자세히 보기</BeigeButton>
                    </BlackTextContainer>
                </LeftContent>
            </ColContainer1>
        </SectionContainer>

        <SectionContainer5>
            <LeftContent5>
                <ContentTitle>지금, 바로 <br />
                    경험해보세요!</ContentTitle>
                <BeigeButton position="right" >무료 체험하기</BeigeButton>
            </LeftContent5>
            <RightContent5>
                <TextContnet5>
                메타디자인하우스와 함께라면, 당신만의 독특하고<br/>
    매력적인 공간을 쉽고 빠르게 현실로 만들 수 있습니다.
                </TextContnet5>
                <ImageRow2>
                        <img className="rowimage1" alt="img1" src={exp1}></img>
                    <ImageCol>
                        <img className="rowimage2" alt="img2" src={exp2}></img>
                        <img className="rowimage3" alt="img3" src={exp3}></img>
                    </ImageCol>
                        <img className="rowimage1" alt="img1" src={exp1}></img>
                    <ImageCol>
                        <img className="rowimage4" alt="img4" src={exp4}></img>
                        <img className="rowimage5" alt="img5" src={exp5}></img>
                    </ImageCol>
                </ImageRow2>
            </RightContent5>
        </SectionContainer5>

        <ServiceSectionContainer>
            <ServiceSEctionWrapper>
                <ServiceInfoContainer className="infocon">
                    <span>우리 집 도면으로 내 스타일에 맞는 공간 디자인</span>
                    <ServiceTitle>서비스 이용 안내</ServiceTitle>
                    <StrokeLine Linecolor={'black'} width={'25%'} position={'flex-start'} marginTop ={'3rem'}></StrokeLine>
                    <RowWrapper className="rowwrapper">
                        <LeftContent1>
                            <RowSection>
                                <div className="title"><span>STEP 01</span></div>
                                <BsHouse size={'9.5rem'}/>
                                <h1>우리집 찾기</h1>
                                <span className="content">상담 신청하기 버튼을 눌러 신청서를 작성하고, <br />
                                인테리어 취향 테스트를 통해 자신의 라이프<br />
                                스타일에 대해 알아보세요.</span>
                            </RowSection>
                            <RowSection>
                                <div className="title"><span>STEP 02</span></div>
                                <img alt="thumb" src={upsidedown}></img>
                                <h1>성향 분석</h1>
                                <span className="content">상담 신청하기 버튼을 눌러 신청서를 작성하고, <br />
                                인테리어 취향 테스트를 통해 자신의 라이프<br />
                                스타일에 대해 알아보세요.</span>
                            </RowSection>
                            <RowSection>
                                <div className="title"><span>STEP 03</span></div>
                                <BsBadge3D size={'9.5rem'}/>
                                <h1>3D공간 디자인</h1>
                                <span className="content">상담 신청하기 버튼을 눌러 신청서를 작성하고, <br />
                                인테리어 취향 테스트를 통해 자신의 라이프<br />
                                스타일에 대해 알아보세요.</span>
                            </RowSection>
                        </LeftContent1>
                    </RowWrapper>
                </ServiceInfoContainer>
                <Section>
                    <ImageRow>
                        <div className="imgdiv">
                            <img alt="thumb" src={serviceimage1}></img>
                        </div>
                        <TextContainer3 className="textcontainer">
                            <FeatureTitle>
                                나의 라이프<br />스타일 진단하기
                            </FeatureTitle>
                            <TopTtile>
                                당신의 취향을 <br/> AI가 정밀하게 분석
                            </TopTtile>
                            
                            <Explain>
                                좋아하는 스타일의 사진을 선택해주세요< br/>
                                라이프스타일을 분석해 당신의 스타일에 맞는 정보를<br/>
                                찾아줍니다
                            </Explain>
                            <Stepper>
                                <span>
                                진단하기
                                </span>
                            </Stepper>
                        </TextContainer3>
                    </ImageRow>
                </Section>
                <Section>
                    <ImageRow>
                        <TextContainer4 className="textcontainer">
                            <FeatureTitle>
                                원하는 공간 디자인<br /> 스타일 선택
                            </FeatureTitle>
                            <TopTtile>
                                AI가 제안하는 공간<br/>
                                디자인 선택하기
                            </TopTtile>
                            <Explain>
                                좋아하는 공간 디자인을 선택해 주세요.<br/>
                                당신이 좋아할 스타일을 만들기 위해,<br/>
                                AI가 디자인을 생성합니다.
                            </Explain>
                            <Stepper>
                                <span>
                                둘러보기
                                </span>
                            </Stepper>
                            
                        </TextContainer4>
                        <div className="imgdiv">
                        <img alt="thumb" src={serviceimage2}></img>
                        </div>
                    </ImageRow>
                </Section>
                <Section>
                    <ImageRow>
                        <div className="imgdiv">
                            <img alt="thumb" src={serviceimage3}>
                            </img>
                        </div>
                        <TextContainer3 className="textcontainer">
                            <FeatureTitle>
                                AI가 생성하는 <br /> 하나뿐인 공간
                            </FeatureTitle>
                            <TopTtile>
                                당신을 위한 공간 디자인 <br/>
                                자동으로 생성되는 라이프스타일
                            </TopTtile>
                            <Explain>
                                1분 이내에 AI가 라이프스타일에 맞는 공간 <br/>
                                디자인을 구축합니다. 디자인된 공간을 3D <br/>
                                화면으로 둘러볼 수 있도록 준비됩니다.
                            </Explain>
                            <Stepper>
                                <span>
                                둘러보기
                                </span>
                            </Stepper>
                        </TextContainer3>
                    </ImageRow>
                </Section>
                <Section>
                    <ImageRow>
                        <TextContainer4 className="textcontainer">
                            <FeatureTitle>
                                3D로 생성된 <br />우리 집 둘러보기
                            </FeatureTitle>
                            <TopTtile>
                                하루만에 만들어지는 <br/> 마법같은 내공간 둘러보기
                            </TopTtile>
                            <Explain>
                                이제 3D화면으로 내 스타일에 맞는 공간을< br/>
                                둘러볼 수 있습니다. 원한다면 가구를 직접 < br/>
                                배치하거나, 다른 제품으로 교체할 수도 있습니다.
                            </Explain>
                            <Stepper>
                                <span>
                                상담신청
                                </span>
                            </Stepper>
                        </TextContainer4>
                        <div className="imgdiv">
                        <img alt="thumb" src={serviceimage4}></img>
                        </div>
                    </ImageRow>
                </Section>

                <BlackButton>
                    <span>무료 체험하기</span>
                </BlackButton>
            </ServiceSEctionWrapper>
        </ServiceSectionContainer>
        
        <SectionContainer>
            <Bar>
                <TextContainer5>
                    <TopTitle5>아티코 디자인<span>은</span></TopTitle5>
                    <div className="titlespan">
                        <p>
                        25년간의 B2B/B2C 인테리어 데이터를 기반으로 디자인을 AI로 추천하여
                        </p>
                        <p>
                        3D디자인 시뮬레이션과 디자이너 메타컨설팅을 통해 같은 예산으로 퀄리티 높은 디자인과 결과를 드립니다.
                        </p> 
                    </div>
                    <BeigeButton>더 알아보기</BeigeButton>
                </TextContainer5>
            </Bar>
        </SectionContainer>

        <SectionContainer2>
            <TopTitle4>주요기능</TopTitle4>
            <GridSection2>
                <Griditem>
                    <img alt="img" src={feature1}></img>
                        <Steps2>01. 편리한 공간 디자인 설계 </Steps2>
                        <span>사용자의 취향을 분석해 <br/>
    성향에 맞는 공간 디자인을 생성합니다</span>
                </Griditem>
                <Griditem>
                    <img alt="img" src={feature2}></img>
                        <Steps2>02. 인테리어 설계 </Steps2>
                        <span>사용자의 취향을 분석해 <br/>
    성향에 맞는 공간 디자인을 생성합니다</span>
                </Griditem>
                <Griditem>
                    <img alt="img" src={feature3}></img>
                        <Steps2>03. 직관적인 편집 기능 </Steps2>
                        <span>사용자의 취향을 분석해 <br/>
    성향에 맞는 공간 디자인을 생성합니다</span>
                </Griditem>
                <Griditem>
                    <img alt="img" src={feature4}></img>
                        <Steps2>04. 라이브러리 </Steps2>
                        <span>사용자의 취향을 분석해 <br/>
    성향에 맞는 공간 디자인을 생성합니다</span>
                </Griditem>
                <Griditem>
                    <img alt="img" src={feature5}></img>
                        <Steps2>05. 3D 시뮬레이션 </Steps2>
                        <span>사용자의 취향을 분석해 <br/>
    성향에 맞는 공간 디자인을 생성합니다</span>
                </Griditem>
                <Griditem>
                    <img alt="img" src={feature6}></img>
                        <Steps2>06. History 관리 </Steps2>
                        <span>사용자의 취향을 분석해 <br/>
    성향에 맞는 공간 디자인을 생성합니다</span>
                </Griditem>
            </GridSection2>
        </SectionContainer2>
        
        <SectionContainerExs>
            <ImageRow2>
                <RowSection className="classsection">
                    <ColContainer className="container">
                        <img className="rowimage1" alt="rowimage" src="https://cdn.imweb.me/thumbnail/20230619/71dca5b97cb2e.png" />
                        <TextContainer6 className="textcontainer">
                            <Strong>일반 인테리어 디자인 회사</Strong>
                            <span>수작업으로 진행되기에 오래 걸리는 3D작업</span>
                            <Strong>단순한 시공업체</Strong>
                            <span>분별력있는 업체 사진을 제공할 수 있는 기술 전무</span>
                        </TextContainer6>
                    </ColContainer>
                </RowSection>
                <RowSection>
                    <ColContainer>
                        <img className="rowimage2" alt="rowimage" src="https://cdn.imweb.me/thumbnail/20230619/cf3e30d25b938.png" />
                        <TextContainer6>
                            <span>30분 내로 AI가 만들어주는 꿈에 그리던 공간</span>
                            <strong>아티코 3D 디자인</strong>
                            <span>30분 이내로 생생한 현장감을 주는 아티코 3D 디자인 완성</span>
                        </TextContainer6>
                    </ColContainer>
                </RowSection>
            </ImageRow2>
        </SectionContainerExs>

        <SectionContainer2>
            <TopTitle3>활용예시</TopTitle3>
            <GridSection3>
                <Griditem2>
                    <img alt="img" src="https://cdn.imweb.me/thumbnail/20230620/edfbe44062d3f.png"></img>
                        <Steps3>3D 메타버스 모델하우스</Steps3>
                </Griditem2>
                <Griditem2>
                    <img alt="img" src="https://cdn.imweb.me/thumbnail/20230620/05624dccb3a27.jpg"></img>
                        <Steps3>아파트단지 3D 메타버스</Steps3>
                </Griditem2>
                <Griditem2>
                    <img alt="img" src="https://cdn.imweb.me/thumbnail/20230620/a8a4e5dd76a35.png"></img>
                        <Steps3>전문 디자이너 툴</Steps3>
                </Griditem2>
            </GridSection3>
        </SectionContainer2>

        <SectionContainer4>
            <TopTitle3>SAMPLE</TopTitle3>
            <GridSection4>
                <Griditem3>
                    <img alt="img" src="https://cdn.imweb.me/thumbnail/20230620/95085733136be.jpg"></img>
                </Griditem3>
                <Griditem3>
                    <img alt="img" src="https://cdn.imweb.me/thumbnail/20230620/806d5f537293b.jpg"></img>
                </Griditem3>
                <Griditem3>
                    <img alt="img" src="https://cdn.imweb.me/thumbnail/20230620/3caa5d552cd29.jpg"></img>
                </Griditem3>
                <Griditem3>
                    <img alt="img" src="https://cdn.imweb.me/thumbnail/20230620/6b9dd86944281.jpg"></img>
                </Griditem3>
                <Griditem3>
                    <img alt="img" src="https://cdn.imweb.me/thumbnail/20230620/e6771ad54e3e4.jpg"></img>
                </Griditem3>
                <Griditem3>
                    <img alt="img" src="https://cdn.imweb.me/thumbnail/20230620/a9124bff2d388.jpg"></img>
                </Griditem3>
                <Griditem3>
                    <img alt="img" src="https://cdn.imweb.me/thumbnail/20230620/e4ceb87df2e02.jpg"></img>
                </Griditem3>
                <Griditem3>
                    <img alt="img" src="https://cdn.imweb.me/thumbnail/20230620/5f8c5066c2b0e.jpg"></img>
                </Griditem3>
            </GridSection4>
        </SectionContainer4>
        </BodyWrapper>
    </Body>
    {ModalVisibility === true && <LandingDetailModal />}
    </>
  );

};

export default Landing;

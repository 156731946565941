import { Radar } from 'react-chartjs-2';
import { STYLESKR } from "../store/atoms";
import styled from "styled-components";

// eslint-disable-next-line no-unused-vars
import chartjs from 'chart.js/auto';

const StRadar = styled.div`
    display: flex;
    @media ${props => props.theme.mediaQuery.desktop} {
        position: absolute;
        top: 15rem;
        left: 5rem;
        width: 50%;
        height: 50%;
        padding: 1rem;
    }
    @media ${props => props.theme.mediaQuery.tablet} {
        width: 50%;
        height: 50%;
        padding: 1rem;
        // bottom: 5rem;
        // left: 5rem;
    }
    @media ${props => props.theme.mediaQuery.phone} {
        width: 31rem;
        height: 31rem;
    }
`;

const COLOR = {
    ORANGE_1: '#FF6C3D',
    GRAY_9E: '#9E9E9E',
    GREEN: '#4cce1f82',
    BLACK: '#000000',
    WHITE: '#FFFFFF',
    BEIGE: '#E0D7C0',
    TRNASPARENT: 'transparent',
    TAMARILLO: 'rgba(143, 16, 16, 0.6)'
};

const chartOptions = {
    elements: {
        line: {
            borderWidth: 1,
            borderColor: COLOR.BEIGE,
        },
        // point: {
        //     pointBackgroundColor: COLOR.BEIGE,
        //     radius: 8,
        //     cursor: 'pointer',
        // },
    },
    scales: {
        r: {
            ticks: {
                stepSize: 0.1,
                display: false,
                color: COLOR.BLACK,
                backdropColor: COLOR.TRNASPARENT
            },
            grid: {
                color: COLOR.BLACK,
            },
            pointLabels: {
                font: {
                    size: 16,
                    weight: '900',
                    family: 'Noto Sans KR',
                },
                color: COLOR.BLACK,
            },
            angleLines: {
                display: false,
            },
            suggestedMin: 0,
            suggestedMax: 0.1,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
    },
    onHover: (event, chartElement) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    animation: {
        duration: 0,
    },
};

const RadarChart = ({ metaData }) => {
    if (metaData.length > 0 ) {
        const parsedData = JSON.parse(JSON.stringify(metaData).replace(/'/g, '"').slice(1, -1));
        const inputData = Object.values(parsedData);
        const chartData = {
            labels: STYLESKR,
            datasets: [
                {
                    label: '스타일 분포',
                    data: inputData,
                    backgroundColor: COLOR.BEIGE,
                },
            ],
        };

        return (
            <StRadar>
                <Radar data={chartData} options={chartOptions} />
            </StRadar>
        );
    }
};

export default RadarChart;

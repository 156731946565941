import React from 'react';

// import cat3 from 'assets/catspinner/cat3.png';
// import cat4 from 'assets/catspinner/cat4.png';
// import cat5 from 'assets/catspinner/cat5.png';
// import cat6 from 'assets/catspinner/cat6.png';
// import cat7 from 'assets/catspinner/cat7.png';
// import cat8 from 'assets/catspinner/cat8.png';
// import cat9 from 'assets/catspinner/cat9.png';
// import cat10 from 'assets/catspinner/cat10.png';
// import cat11 from 'assets/catspinner/cat11.png';
// import cat12 from 'assets/catspinner/cat12.png';
// import cat13 from 'assets/catspinner/cat13.png';
// import cat14 from 'assets/catspinner/cat14.png';
// import cat15 from 'assets/catspinner/cat15.png';
// import cat16 from 'assets/catspinner/cat16.png';
// import cat17 from 'assets/catspinner/cat17.png';
// import cat18 from 'assets/catspinner/cat18.png';
import catspinner from 'assets/catspinner/catspinner.gif';

const CatSpinner = () => {
  return (
    <div className='overlay' style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      zIndex: '1000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    <div
      className="spinner"
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <img src={catspinner} style={{
        'width' : '15rem',
        'height' : 'auto'
      }}alt="Loading.." />
    </div>
    </div>
  )
//   const [currentImage, setCurrentImage] = useState(0);
//   const images = useMemo(() => [cat3, cat4, cat5, cat6, cat7, cat8, cat9, cat10, cat11, cat12, cat13, cat14, cat15, cat16, cat17, cat18], []);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentImage((currentImage + 1) % images.length);
//     }, 80);

//     return () => clearInterval(interval);
//   }, [currentImage, images]);


//   return (
//     <div
//       className="spinner"
//       style={{
//         position: 'fixed',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         alignItems: 'center',
//         justifyContent: 'center',
//       }}>
//       <img src={images[currentImage]} alt="spinner" />
//     </div>
//   );
// };
};

export default CatSpinner;

import React, { useEffect,useState }  from "react";
import styled, { keyframes } from "styled-components";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";
// import { useRecoilState } from "recoil";

//recoil
// import {isNoticeModalState} from 'store/atoms';

// comps
import AppDown from '../components/mobile/AppDown';
import Stories from '../components/mobile/Stories';
import ServicesIntro from '../components/mobile/ServicesIntro';
import Services1 from '../components/mobile/Services1';
import Services2 from '../components/mobile/Services2';
import PromotionComp from '../components/mobile/PromotionComp';
import Threed from '../components/mobile/Threed';
import PortfolioGrid from '../components/mobile/PortfolioGrid';
import CatSpinner from 'components/CatSpinner';
import VideoComponent from 'components/VideoComponent';
// import NoticeModal from 'components/mobile/NoticeModal';
// import Bigwall from "components/Bigwall";

// elems
import BeigeButton from "element/BeigeButton";

// assets
import googleplay from 'assets/googleplay.png';

const HeadSectionContainer  = styled.div`
    height: auto;
`;

const BodySectionContainer = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 30rem;
`;

const MainBodyTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: center;
    gap: ${props => props.gap ? props.gap : '1rem'};
    justify-content: center;
`;

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1400rem;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 3rem;

`;
const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 20rem;
`;

const ImageRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;
    

    div.imgdiv {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    img {
        width: 70rem;
        height: auto;
    }

`;

const LeftContent1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    gap: 7rem;
    width: 100%;
    height: 100%;
`;

const RowSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    height: 100%;
    gap: 2rem;

    .title {
        border-radius: 5rem;
        background-color: black;
        width: fit-content;
        padding: 1rem 2rem;
    }

    .title span {
        display: flex;
        color: white;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 12px;
        font-style: normal;
        font-weight: 350;
        line-height: 150%; /* 18px */
    }

    h1 {
        color: #000;
        text-align: center;
        font-family: Pretendard;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 37.5px */
        letter-spacing: 1.25px;
    }
    .content {
        color: #666666;
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 350;
        line-height: 150%; /* 15px */
    }
    img {
        width: 95px;
        height: 95px;
    }
`;


const SmallTitle = styled.span`
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #999999;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 10%;
    height: fit-content;
`;

const H2Title = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-family: "Noto Sans KR";
    font-size: 48px;
    font-style: normal;
    font-weight: 900;

    strong {
        font-family: "Noto Sans KR";
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
    }
`;


const MainTitle = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-family: "Noto Sans KR";
    font-size: 96px;
    font-style: normal;
    font-weight: 900;
    padding-top: 1rem;
`;

const Blank = styled.div`
    display: flex;
    width: 100%;
    height: ${props => props.height ? props.height : '20rem'};
`;

const TopTitle = styled.span`
    display: flex;
    align-items: center;
    color: ${props => props.color ? props.color : '#FFF'};
    font-family: 'pretendard';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const TextContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: black;
    z-index: 2;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : 'transparent'};
`;

const ImageContainers = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;
    width: fit-content;

    & > div {
        position: relative;
        align-items: center;
        justify-content: center;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

`;

const SpanWrapper = styled.span`
    display: flex;
    position: absolute;
    left: 40%;
    top: 85%;
    transform: translate(-50%, -50%);
    z-index: 1;

    color: #FFF;
    white-space: nowrap;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
`;


const ColorBall = styled.div`
    display: flex;
    position: absolute;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    z-index: 1;
    ${props => props.style};
`;

const Smallspan = styled.span`
    display: flex;
    color: #000;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    justify-content: center;
    font-family: "Playfair Display";
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
`;
const MoreButtomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const MoreButtom = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    border: 1px solid #8B8B8B;
    font-size: 1.6rem;
    font-weight: 500;
    color: #8B8B8B;
    padding: 0.8rem 5rem;
    cursor: pointer;
`;

const SplitSection = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: fit-content;
    align-items: stretch; // 여기를 수정했습니다.
    justify-content: center;
    gap: 5rem;
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    align-items: center;
    gap: 8rem;
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: fit-content;
    gap: 2.7rem;
`;

const FirstRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: top;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    gap: 5rem;
`;


const RowText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .title { 
        white-space: nowrap;
        color: #000;
        font-family: Pretendard;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
    }
    .content {
        color: #000;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
`;


const AppDownFrame = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
`;

const moveDown = keyframes`
    0% {    
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0%);
    }
`;

const ImageContainer = styled.div`
    display: flex;
`;

const Thumbnail = styled.img`
    height: 50rem;
    // width: 25%;
    // animation: ${moveDown} 15s linear infinite;
`;


const Wall = styled.section`
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    justify-content: space-between;
    width: 100%;
    height: 80vh;
`;

const TextWrapper = styled.div`
    margin-top: 8rem;
    display: flex;
    width: 40.4rem;
    height: 23.6rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;

    .headercontent {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2rem;
    }
    .titletext  {
        span {
            color: ${props => props.color ? props.color : '#000'};
            font-family: Pretendard;
            font-size: 64px;
            font-style: normal;
            font-weight: 800;
            line-height: 100%; /* 72px */
            letter-spacing: 3px;
        }
    }
    .titlespan {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 3rem;
        font-size: 18px;
        font-weight: 300;
        font-family: Pretendard;
        font-style: normal;
        line-height: 2;
    }
    img {
        width: 3rem;
        height: auto;
    }
`;

const ImagesContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    align-items: top;
    justify-content: top;
    background-color: ${props => props.ImgBackgroundColor ? props.ImgBackgroundColor : null};
    
    img {
        z-index: 0;
        width: 100%;
        object-fit: cover;
    }

    // &::after {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     background-color: ${props => props.ImgBackgroundColor ? props.ImgBackgroundColor : null};
    //     z-index: 1;
    // }
`;

const PhoneImage = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`;

const RowCenterText = styled.span`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: ${props => props.fontSize ? props.fontSize : '24px'};
    font-family: "Pretendard";
    font-weight: ${props => props.fontWeight ? props.fontWeight : 600};
    width: 100%;
    height: 100%;
`;

const ColContainer1 = styled.div`
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    gap: ${props => props.gap ? props.gap : '0'};
    width: 100%;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 40rem;
    gap: ${props => props.gap ? props.gap : '10rem'};
    max-height: 40rem;
`;

const Image = styled.div`
    display: flex;
    flex-direction: column;

    img, video {
        align-items: center;
        justify-content: ${props => props.side === 'left' ? 'flex-start' : 'flex-end'};
        width: ${props => props.width ? props.width : '40rem'};
        height: ${props => props.height ? props.height : '40rem'};
        object-fit: fill;
    }
`;
const BlackTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 40rem;
    max-width: 40rem;
    color: black;
    align-items: ${props => props.side === 'right' ? 'flex-end' : 'flex-start'};
    text-align: ${props => props.side === 'right' ? 'right' : 'left'};
    
    .titlespan {
        font-size: 18px;
        font-weight: 500;
        font-family: Pretendard;
        font-style: normal;
        line-height: 2;
    }
`;

const TopTitle2 = styled.strong`
    width: 100%;
    color: #000;
    font-family: "Pretendard";
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    white-space: nowrap;
`;

const H2Title1 = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-family: Pretendard;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
`;

const ColorBox = ({text, color, img}) => {
    const Box = styled.div`
        width: 25.4rem;
        height: 12.7rem;
        position: relative;
        
        img {
            width: 100%;
            height: 12.7rem;
            z-index: 1;
        }

        .bar {
            z-index: 2;
            height: 12.7rem;
            width: 100%;
            color: #FFF;
            background-color: ${color ? color : 'transparent'};
        }

        .text {
            z-index: 2;
            position: absolute;
            top: 5%;
            left: 5%;
            color: #FFF;
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
        }
    `;

    return (
        <Box className="boxcontainer">
            {img && 
                <img src={img} alt={img} />
            }
            {color ? 
            <div className="bar">
                <span className="text">{text}</span>
            </div> 
            : 
            <span className="text">{text}</span>
            }
        </Box>
    )
}

const MediaContainer = styled.div`
    width: fit-content;
    height: fit-content;

    video, img {
        border-radius: 10px;
        width: 25rem;
        height: 25rem;
        object-fit: cover;
    }
`;

const TitleSpan = styled.span`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .title {
        font-size: 18px;
        font-weight: 600;
        font-family: "Pretendard";
        font-style: normal;
        line-height: 1.2;
    };

    .titlecontent {
        color: #444444;
        font-size: 18px;
        font-weight: 400;
        font-family: "Prentendard";
        font-style: normal;
        line-height: 1.39;
    }
`;

const ImageContainerTag = ({ index, children, color, spanContent, imgSrc, smallSpanContent }) => {
    const [ballStyle, setBallStyle] = useState({});

    useEffect(() => {
        const imgElement = document.querySelector('.ball'+index);
        if (imgElement) {
            setBallStyle({
                top: '-2.5rem',
                left: '-2.5rem',
            });
        }
    }, [color, index]);

    return (
        <div>
            <ColorBall style={ballStyle} color={color} />
            <SpanWrapper>{spanContent}</SpanWrapper>
            <MediaContainer className={'ball'+index}>
                <VideoComponent src={imgSrc} loading="lazy"/> 
                {/* <img className={'ball'+index} src={imgSrc} alt="description" loading="lazy"/> */}
            </MediaContainer>
            <Smallspan>{smallSpanContent}</Smallspan>
            {children}
        </div>
    );
};

const Main = () => {
    const matches = useMediaQuery('(max-width: 1015px)');
    // const [isVisibleNoticeModal, setVisibNoticeModal] = useRecoilState(isNoticeModalState);
    // const [ModalVisibility, setModalvisibility] = useRecoilState(isLandingDetailModal);
    const navigate = useNavigate();
    const Gridimages = [
        ['https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainport1.mp4','#B0562F','카페같은 거실에서 빈티지하고','유니크하게 커피 한잔','vintage'], 
        ['https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainport2.mp4','#496F72','휴양지에 온 듯한 북유럽풍의','고급스러운 방에서의 단장','antique'], 
        ['https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainport3.mp4','#C88C61', '오크톤의 호텔 라운지에서','낭만을 즐기는 시원한 휴식','retro'], 
        ['https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainport4.mp4','#6D6D6D' ,'자연 속에 있는 듯 깔끔하고','시원한 거실에서 여유로운 아침','modern'], 
        ['https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainport5.mp4','#060100', '카페같은 거실에서 빈티지하고','유니크하게 커피 한잔','industrial'], 
        ['https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainport6.mp4','#652F10', '꼴라쥬 액자 속에 들어온 듯한','세련된 분위기의 서재에 머무름','natural']
    ];
    const [loading, setLoading] = useState(true);

    const handleDetailButtonClick = (modalName) => {
        navigate(modalName);
    };

    const redirecting = (url) => {
        window.location.href = url;
      }

    useEffect(() => {
        setTimeout(() => setLoading(false), 100);
    }, []);
    if (loading) {
        return <CatSpinner />
    }

    return (
    <>
      {matches ? (
        <>
        <AppDownFrame>
            <ImageContainer>
                <Thumbnail src="https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/homestyling.png" alt="thumbnail" />
                {/* <Thumbnail src="https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/homestyling.png" alt="thumbnail" />
                <Thumbnail src="https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/homestyling.png" alt="thumbnail" />
                <Thumbnail src="https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/homestyling.png" alt="thumbnail" /> */}
            </ImageContainer>
          
            <AppDown gap={'3rem'} title="하루만에 일어나는 마법같은 홈스타일링" content="지금, 바로 여기에" button="앱 다운로드"/>
            <Blank height={'15rem'}/>
            <Stories />
            <Blank height={'15rem'}/>
            <ServicesIntro />
            <Blank height={'15rem'}/>
            <Services1 />
            <Blank height={'15rem'}/>
            <Services2 />
            <Blank height={'3rem'}/>
            <PromotionComp />
            <Blank height={'15rem'}/>
            <Threed />
            <Blank height={'15rem'}/>
            <PortfolioGrid />
            <Blank height={'10rem'}/>
        </AppDownFrame>
        </>
      ) : (
        <>
        <HeadSectionContainer>
            <Wall className="wallpaper">
                <TextContainer className="textcont" backgroundColor={'transparent'}>
                    <TextWrapper color={'black'}>
                        <div className="headercontent">
                            <TopTitle color={'black'} >하루만에 일어나는 마법같은 홈스타일링</TopTitle>
                            <div className="titletext">
                                <span>지금, 바로 여기에</span>
                            </div>
                        </div>
                        <span className="titlespan">
                            스마트 AI를 통해 개인의 라이프스타일을 담은 세상에 단 하나,<br/>
                            꿈꾸던 나만의 맞춤 공간을 지금 당장 만나보세요.
                        </span>
                        <BeigeButton width='17rem' height='5rem' onClick={() => redirecting('https://play.google.com/store/apps/details?id=com.aticodesign.mdh&pcampaignid=web_share')}>
                            <img alt="googleplay" src={googleplay}/>앱 다운로드</BeigeButton>
                    </TextWrapper>
                </TextContainer>
                <ImagesContainer className="imagecon" ImgBackgroundColor={'transparent'}>
                    <img src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainwallpaper2+(1).png'} alt="wall" loading="lazy"/>
                </ImagesContainer> 
            </Wall>
        </HeadSectionContainer>
        <Blank />
        <BodySectionContainer className="body">
            {/* Section 1 */}
            <MainBodyTitle id="ourTechnology" className="bodyTitle">
                <BodyWrapper className="wrapper" gap={'4rem'}>
                    <MainTitle>지금, 바로 꿈의 집으로</MainTitle>
                    <RowCenterText fontWeight={'500'} fontSize={'24px'}>
                        <Blank height={'4rem'}/>
                        단순히 인테리어를 넘어서 당신의 라이프스타일까지 생각하는<br/> 
                        1:1 맞춤형 디자인 메타디자인하우스는 결국 '당신'입니다.<br/>
                         AI를 기반으로 라이프스타일 분석과 실시간 3D시뮬레이션을 통해. 
                         <Blank height={'1rem'}/>
                    </RowCenterText>
                    <RowCenterText fontWeight={'800'} fontSize={'40px'}>
                        상상만 하던 공간이 현실화되는 모습을<br/> 
                        실시간으로 즉시, 보여드릴 것입니다.
                    </RowCenterText>
                </BodyWrapper>
            </MainBodyTitle>
            {/* Section 2 */}
            <ColContainer1 className="content" gap={'20rem'}>
                <Content className="leftcont">
                    <Image side={'left'}>
                        {/* <VideoComponent loop={true} autoPlayOnLoad={true} src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/knowhow.mp4'} loading="lazy"/> */}
                        <img alt="img" src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/knowhow.png'} loading="lazy"></img>
                    </Image>
                    <BlackTextContainer side={'left'}>
                        <TopTitle2>25년 경력의<br/> 노하우</TopTitle2>
                        <div className="titlespan">
                            25년동안의 인테리어 실무 경험으로<br/> 
                            축적된 소비자 피드백 데이터를<br/>
                             기반으로 연구 개발되었습니다.
                        </div>
                        <BeigeButton position="left" onClick={() => handleDetailButtonClick('career')}>자세히 보기</BeigeButton>
                    </BlackTextContainer>
                </Content>

                <Content className="rightcont">
                    <BlackTextContainer side={'right'}>
                        <TopTitle2>생성형 3D AI기반<br/>맞춤형 디자인 솔루션</TopTitle2>
                        <div className="titlespan">
                            사용자의 취향과 스타일을 <br/>
                            AI가 분석해 맞춤형 디자인을 제안하고, <br/>
                            이를 실시간 3D 시뮬레이션으로<br/>
                            실현하는 서비스를 제공합니다.
                        </div>
                        <BeigeButton position="right" onClick={() => handleDetailButtonClick('career')}>자세히 보기</BeigeButton>
                    </BlackTextContainer>
                    <Image side={'right'}>
                        <img alt="img2" src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/pad.png'} loading="lazy"></img>
                    </Image>
                </Content>

                <Content className="leftcont">
                    <Image width="auto" side={'left'}>
                        <img alt="img" src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/message.png'}></img>
                    </Image>
                    <BlackTextContainer>
                        <TopTitle2>공간 디자이너와 고객의<br />즉각적인 소통</TopTitle2>
                        <div className="titlespan">
                            기존의 ‘주거공간 3D 디자인 시장’에서<br />
                            고객의 시간적 효율과 만족도가 낮을<br />
                            수 밖에 없는 문제를 인식하고,<br />
                            서비스를 제공합니다.
                        </div>
                        <BeigeButton onClick={() => handleDetailButtonClick('career')}>자세히 보기</BeigeButton>
                    </BlackTextContainer>
                </Content>
            </ColContainer1>

           {/* Section 3 */}
            <ColContainer1 gap={'15rem'}>
                <BodyWrapper className="wrapper">
                    <H2Title>포트폴리오</H2Title>
                    <SmallTitle>이런 스타일 어때요?</SmallTitle>
                </BodyWrapper>
                <ImageContainers className="images">
                    {Gridimages.map((data, index) => (
                        <ImageContainerTag
                            key={index}
                            index={index}
                            color={data[1]}
                            spanContent={<>{data[2]}<br />{data[3]}</>}
                            imgSrc={data[0]}
                            smallSpanContent={data[4]}
                        />
                    ))}
                </ImageContainers>
                <MoreButtomContainer>
                    <MoreButtom onClick={() => navigate('/portfolio')}>
                        더보기
                    </MoreButtom>
                </MoreButtomContainer>
            </ColContainer1>

            {/* Section 4 */}
            <ColContainer1 gap={'10rem'} className="magazine">
                <BodyWrapper className="wrapper">
                    <H2Title>읽을거리</H2Title>
                    <SmallTitle>2024 인테리어 트렌드를 알아보세요</SmallTitle>
                </BodyWrapper>
                <SplitSection className="splitcon">
                    <LeftContainer>
                        <FirstRow textAlign={'left'}>
                            <img src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainimg4.4f4907efcbb5b58d19a1.png'} alt="main4" loading="lazy"/>
                            <RowText >
                                <span className="title">자연친화적인 요소로 <br /> 감성적이고 치유되는 공간</span>
                                <span className="content">
                                    차가운 색조에서 벗어나 따뜻한<br />
                                    중성색으로 공간을 채워보세요.<br />
                                    집안의 분위기가 더욱 포근하고<br />
                                    편안해질 거예요.
                                </span>
                            </RowText>
                        </FirstRow>
                        <FirstRow textAlign={'right'}>
                            <RowText>
                                <span className="title">맥시멀리즘과 모던을 결합해 <br /> 기능적이고 감각적으로</span>
                                <span className="content">
                                    자신의 취향과 취미를 공간에<br />
                                    감각적으로 담아보세요.<br />
                                    내가 좋아하는 것들로 가득한<br />
                                    나만의 집이 생길거예요.
                                </span>
                            </RowText>
                            <img src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainimg5.6b18edb5e1c6402e4c14.png'} alt="main5" loading="lazy" />
                        </FirstRow>
                    </LeftContainer>
                    <RightContainer className="rightcont">
                        <ColorBox color={'#65371E'} text={'Color'}/>
                        <ColorBox img={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainimg6.63c7d5acbe38fb2b0b80.png'} text={'Pattern'} loading="lazy"/>
                        <ColorBox img={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainimg7.5dfc6a2a9505d2057c0c.png'} text={'Texture'} loading="lazy"/>
                        <ColorBox img={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainimg8.1685a63c02fffdcb5665.png'} text={'furniture'} loading="lazy"/>
                    </RightContainer>
                </SplitSection>
                {/* <MoreButtomContainer>
                    <MoreButtom onClick={() => navigate('/portfolio')}>
                        더보기
                    </MoreButtom>
                </MoreButtomContainer> */}
            </ColContainer1>

            {/* Section 5 */}
            <ColContainer1 gap={'10rem'} className="servicedesc">
                <BodyWrapper className="wrapper">
                    <H2Title>서비스 이용 안내</H2Title>
                    <SmallTitle>우리 집 도면으로 내 스타일에 맞는 공간 디자인을 만나보세요</SmallTitle>
                </BodyWrapper>
                <RowWrapper className="rowwrapper">
                    <LeftContent1>
                        <RowSection>
                            <div className="title"><span>STEP 01</span></div>
                            <img alt='find' src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainicons1.png'}/>
                            <h1>우리집 찾기</h1>
                            <span className="content">상담 신청하기 버튼을 눌러 신청서를 작성하고, <br />
                            인테리어 취향 테스트를 통해 자신의 라이프<br />
                            스타일에 대해 알아보세요.</span>
                        </RowSection>
                        <RowSection>
                            <div className="title"><span>STEP 02</span></div>
                            <img alt="thumb" src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainicons2.png'}></img>
                            <h1>성향 분석</h1>
                            <span className="content">상담 신청하기 버튼을 눌러 신청서를 작성하고, <br />
                            인테리어 취향 테스트를 통해 자신의 라이프<br />
                            스타일에 대해 알아보세요.</span>
                        </RowSection>
                        <RowSection>
                            <div className="title"><span>STEP 03</span></div>
                            <img alt="thumb" src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainicons3.png'}></img>
                            <h1>3D공간 디자인</h1>
                            <span className="content">상담 신청하기 버튼을 눌러 신청서를 작성하고, <br />
                            인테리어 취향 테스트를 통해 자신의 라이프<br />
                            스타일에 대해 알아보세요.</span>
                        </RowSection>
                        <RowSection>
                            <div className="title"><span>STEP 04</span></div>
                            <img alt="thumb" src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainicons4.png'}></img>
                            <h1>실시간 컨설팅</h1>
                            <span className="content">전문 디자이너와의 실시간 상담을 통해<br />
                            나만을 위한 디자인을 완성해보세요.</span>
                        </RowSection>
                    </LeftContent1>
                </RowWrapper>
                <Blank height={'1rem'}/>
                <ColumnWrapper className="colWrapper">
                    <ImageRow>
                        <Content className="leftcontent" gap={'5rem'}>
                            <Image side={'left'} width={'70rem'} height={'40rem'}>
                                <img alt="img" src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/serviceimage1.png'} loading="lazy"></img>
                            </Image>
                            <BlackTextContainer>
                                <TopTitle2>나의 라이프<br /> 스타일 진단하기</TopTitle2>
                                <TitleSpan>
                                    <div className="title">
                                        당신의 취향을<br /> AI가 정밀하게 분석 공간디자인
                                    </div>
                                    <div className="titlecontent">
                                        좋아하는 스타일의 사진을 선택해주세요.<br />
                                        라이프스타일을 분석해 당신의 스타일에<br />
                                        맞는 정보를 찾아줍니다.
                                    </div>
                                </TitleSpan>
                                <BeigeButton width={'20rem'} height={'4.5rem'} onClick={() => navigate('/fav/pick/1')}>진단하기</BeigeButton>
                            </BlackTextContainer>
                        </Content>
                    </ImageRow>
                    <ImageRow>
                        <Content className="rightcont" gap={'5rem'}>
                            <BlackTextContainer side={'right'}>
                                <TopTitle2>원하는 디자인<br /> 스타일 선택하기</TopTitle2>
                                <TitleSpan>
                                    <div className="title">
                                        AI가 제안하는<br />공간디자인 선택하기
                                    </div>
                                    <div className="titlecontent">
                                        좋아하는 공간디자인을 선택해주세요.<br />
                                        당신이 좋아할 스타일을 만들기 위해,<br />
                                        AI가 디자인을 생성합니다.
                                    </div>
                                </TitleSpan>
                                <BeigeButton width={'20rem'} height={'4.5rem'} position={'right'} onClick={() => navigate('fav/pick/1')}>둘러보기</BeigeButton>
                            </BlackTextContainer>
                            <Image side={'right'} width={'70rem'} height={'40rem'}>
                                <img alt="img" src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/serviceimage2.png'}></img>
                            </Image>
                        </Content>
                    </ImageRow>
                    <ImageRow>
                        <Content className="leftcont" gap={'5rem'}>
                            <Image side={'left'} width={'70rem'} height={'40rem'}>
                                <img alt="img" src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/serviceimage3.png'}></img>
                            </Image>
                            <BlackTextContainer side={'left'}>
                                <TopTitle2>AI가 생성하는<br />하나뿐인 공간</TopTitle2>
                                <TitleSpan>
                                    <div className="title">
                                        당신을 위한 공간디자인<br />자동으로 생성되는 라이프스타일
                                    </div>
                                    <div className="titlecontent">
                                        1분 이내에 AI가 라이프스타일에 맞는 공간<br />
                                        디자인을 구축합니다. 디자인된 공간을 3D<br />
                                        화면으로 둘러볼 수 있도록 준비됩니다.
                                    </div>
                                </TitleSpan>
                                <BeigeButton width={'20rem'} height={'4.5rem'} position={'left'} onClick={() => navigate('https://play.google.com/store/apps/details?id=com.aticodesign.mdh')}>둘러보기</BeigeButton>
                            </BlackTextContainer>
                        </Content>
                    </ImageRow>

                    <ImageRow>
                        <Content className="rightcont" gap={'5rem'}>
                            <BlackTextContainer side={'right'}>
                                <TopTitle2>3D로 생성된<br />우리집 둘러보기</TopTitle2>
                                <TitleSpan>
                                    <div className="title">
                                        하루만에 만들어지는<br />마법같은 내 공간 둘러보기
                                    </div>
                                    <div className="titlecontent">
                                        이제 3D화면으로 내 스타일에 맞는 공간을<br />
                                        둘러볼 수 있습니다. 원한다면 가구를 직접<br />
                                        배치하거나, 다른 제품으로 교체할 수도<br />
                                        있습니다.
                                    </div>
                                </TitleSpan>
                                <BeigeButton width={'20rem'} height={'4.5rem'} position={'right'} onClick={() => navigate('/interiorcall')}>상담신청</BeigeButton>
                            </BlackTextContainer>
                            <Image side={'right'} width={'70rem'} height={'40rem'}>
                                <img alt="img" src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/serviceimage2.png'} loading="lazy"></img>
                            </Image>
                        </Content>
                    </ImageRow>
                </ColumnWrapper>
            </ColContainer1>

            {/* Section 6 */}
           <ColContainer1 gap={'8rem'}>
                <BodyWrapper className="wrapper" gap={'0.1rem'}>
                    <H2Title1>지금, 바로 경험해보세요!</H2Title1>
                    <SmallTitle>당신만의 독특하고 매력적인 공간을 쉽고 빠르게</SmallTitle>
                </BodyWrapper>
                <PhoneImage>
                    <img alt="img" src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/phone_035.png'} loading="lazy"></img>
                </PhoneImage>
                <BeigeButton radius={'8px'} width={'23rem'} height={'6rem'} position={'center'} onClick={() => redirecting('https://play.google.com/store/apps/details?id=com.aticodesign.mdh')}>무료 체험하기</BeigeButton>
            </ColContainer1>
            <Blank />
        </BodySectionContainer>
        </>
      )
      }
    {/* {isVisibleNoticeModal && <NoticeModal isVisibleNoticeModal={isVisibleNoticeModal} setVisibNoticeModal={setVisibNoticeModal} />} */}
    </>
  );
};

export default Main;




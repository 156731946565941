import {useState, useEffect} from 'react'
import styled from "styled-components";
import useMediaQuery from '@mui/material/useMediaQuery';
import StrokeLine from "element/StrokeLine";

// assets

// components
import AppDown from "components/mobile/AppDown";
import PortfolioFilter from "components/PortfolioFilter";
import Bigwall from "components/Bigwall";
import PortfolioFrame from "components/mobile/PortfolioFrame";
import CatSpinner from 'components/CatSpinner';
import VideoComponent from 'components/VideoComponent';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: fit-content;
`;

const FilterWrapper= styled.section`
    display: flex;
    background-color: white;
    width: 100%;
    z-index: 999;
`;

const FilterResultsWrapper = styled.section`
    display: flex;
    background-color: white;
    padding: 3.2rem 0;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    white-space: wrap;
`;

const HeadSectionContainer  = styled.div`
    height: auto;
`;


const TextContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center-left;
    flex-direction: column;
    z-index: 3;
    gap: 2rem;

    .titlespan {
        p {
            color : black;
            font-family: "Pretendard";
            font-size: 24px;
            font-weight: 400;
            letter-spacing: 2%;
        }
    }
`;

const TopTitle = styled.h1`
    display: flex;
    align-items: center;
    color: black;
    font-family: Pretendard;
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1.1;
    width: 100%;
    justify-content: flex-start;
`;



const AppDownFrame = styled.div`
    display: flex;
    flex-direction: column;
    width: 100&;
    height: 100%;
`;
const Thumbnail = styled.img`
    display: flex;
    height: 100%;
    max-height: 55rem;
    width: auto;
    margin-top: 6rem;
`;

const Blank = styled.div`
    display: flex;
    width: 100%;
    height: ${props => props.height ? props.height : '20rem'};
`;

const Portfolio = () => {
    const { apiData, FilterSection } = PortfolioFilter();
    const matches = useMediaQuery('(max-width: 1015px)');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => setLoading(false), 500);
    }, []);
    if (loading) {
        return <CatSpinner />
    }

    return (
        <>
            {matches ? (
                <>
                <AppDownFrame>
                    <Thumbnail src="https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/portthumnail.png" alt="mthumbnail">
                    </Thumbnail>
                    <AppDown title="메타디자인하우스의 인테리어를 만나보세요." content="포트폴리오"/>
                    <Blank height={'15rem'}/>
                    <PortfolioFrame />
                </AppDownFrame>
                </>
            ) : (
            <Container >
                {/* 메인 이미지 */}
                <HeadSectionContainer className="sectioncont">
                    <Bigwall  backgroundColor={'transparent'} loop={true} autoPlayOnLoad={true} video={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/portfoliowall.mp4'}>
                    {/* <Bigwall image={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/aticohomestyling+(1).jpg'}> */}
                        <TextContainer>
                            <TopTitle>포트폴리오</TopTitle>
                            <div className="titlespan">
                                <p>
                                맞춤형 인테리어 디자인을 둘러보세요.
                                </p>
                            </div>
                        </TextContainer>
                    </Bigwall>
                </HeadSectionContainer>

                {/* 필터링 */}
                <FilterWrapper>
                    {FilterSection}
                </FilterWrapper>

                {/* 필터 결과 */}
                <FilterResultsWrapper className='resultwrapper'>
                    <FilterResultsContainer>
                        {apiData?.map((data, i) => {
                            return (
                                <PortfolioElem key={i} href={"/portfolio/"+data.id} className='portfolioelem'>
                                    <div className='styletitle'>{data.style.toUpperCase()}</div>
                                    {data.thumbnail_movie 
                                        ? <VideoComponent src={data.thumbnail_movie}/> 
                                        : <img src={data.image} alt={data.id}/>
                                    }
                                    <PortText>
                                        <TagContainer>
                                            {data?.tags?.map((tag, i) => {
                                                return (
                                                    <Tag key={i}>{tag}</Tag>
                                                )
                                            })}
                                        </TagContainer>
                                        <StrokeLine Linecolor={'black'} width={'100%'} height={'1px'} position={'center'} marginTop ={'1rem'}></StrokeLine>
                                        <p className="title" dangerouslySetInnerHTML={{ __html: data.name.replace(/\n/g, '<br/>') }}></p>
                                    </PortText>
                                </PortfolioElem>
                            );
                            })
                        }
                    </FilterResultsContainer>
                </FilterResultsWrapper>
            </Container>
            )
            }
        </>
    );
};

export default Portfolio;

const FilterResultsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-width: 130rem;
    padding: 0 3.2rem;
    gap: 5rem 3.5rem;
`;

const PortfolioElem = styled.a`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: calc(100% / 3);
    max-width: 40rem;
    cursor: pointer;
    text-decoration: none;
    gap: 0rem;
    video {
        width: 40rem;
        height: 25.7rem;
        object-fit: cover;
    }
    .styletitle {
        width: 100%;
        height: fit-content;
        align-items: center;
        
        font-size: 1.4rem;
        font-weight: 900;
        font-family: "Noto Sans KR";
        text-align: left;
        color: black;
    }
    
    img {
        -webkit-user-drag: none;
        width: 40rem;
        height: 25.7rem;
        object-fit: cover;
    }
`;

const PortText = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1.6rem 0.8rem 0 0.8rem;

    .title {
        color: #000000;
        font-family: "Noto Sans KR";
        font-size: 20px;
        font-weight: 900;
        line-height: 150%; /* 21px */
    }
   
`;

const TagContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3rem;
`;

const Tag = styled.div`
    display: flex;
    color: #666;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size: 14px;
    font-weight: 500;
    line-height: 150%; /* 15px */
`;
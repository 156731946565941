import React from "react";
import styled from "styled-components";

const CustomBtn = styled.button`
  cursor: pointer;
  width: 29.7rem;
  height: 5rem;
  background-color: #F3F0E7;
  pointer-events: inherit;
  font-size: 2rem;
  font-weight: ${props => props.fontWeight || 'normal'};
  font-family: Pretendard;
  letter-spacing: 5%;
  line-height: 126%;
  color: black;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${props => props.position === 'left' ? 'left' : 'right'};
`;

const SquareBeigeButton = ({ children, onClick, disabled=false, position='left',fontWeight }) => {
  return (
    <ButtonContainer position={position}>
      <CustomBtn type="submit" onClick={onClick} disabled={disabled} fontWeight={fontWeight} >
        {children}
      </CustomBtn>
    </ButtonContainer>
  );
};

export default SquareBeigeButton;


import React from 'react'
import styled from 'styled-components'
import CatSpinner from 'components/CatSpinner';

const ServiceSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
`;
const Text = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 0;
    gap: 0.8rem;

    .title {
        color: #222222;
        font-family: Pretendard;
        font-size: 1.3rem;
        font-weight: regular;
        text-align: center;
    }
    .content {
        color: #222222;
        font-family: Pretendard;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 0 3.2rem;
    justify-content: center;
    align-items: center;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    max-width: 32.7rem;
    margin-top: 12rem;
    
    justify-content: center;
    align-items: center;
`;

const CardMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    gap: 12rem;
    padding: 0rem 2.4rem;
`;

const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 29.7rem;
    height: fit-content;
    gap: 2.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.8rem;
`;

const ColorBall = styled.div`
    display: flex;
    width: 4.3rem;
    height: 4.3rem;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    z-index: 1;
    top: -3.5rem;
    left: 2.5rem;
`;

const TagWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
`;

const Tag = styled.span`
    display: flex;
    font-family: "Noto Sans KR";
    font-size: 1.4rem;
    font-weight: 700;
    color: #000;
    letter-spacing: 0.42px;
`
const ImageGridWrapper = styled.div`
    display: flex;
    margin-top: 12rem;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.color};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.8rem;
    padding: 2rem;
    img {
        width: 100%;
        height: auto;
        border-radius: 0;
    }
`;
const Imagespan = styled.span`
    display: flex;
    width: 32.7rem;
    height: 12rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    justify-content: center;
    align-items: center;
    font-family: "Noto Sans KR";
    font-size: 1.4rem;
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.50);
    text-align: center;
    line-height: 150%;
`;

const ImageWrapper = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    img {
        width: 32.7rem;
        height: 12rem;
        border-radius: 1rem;
    }
`;




const CardMenu = ({ color, tags = [], imagespan, pfwideimages }) => {
    return (
        <CardMenuWrapper className='cardmenu'>
            <CardItem>
                <TagWrapper>
                    {tags.map((tag, index) => {
                        return (
                        <Tag key={index}>{tag}</Tag>
                        )
                    })}
                </TagWrapper>
                {/* <div style={{position: 'absolute', width: '100%', height: '100%', backgroundColor: 'black', opacity: '0.2', borderRadius: '1rem'}}></div> */}
                <ImageWrapper>
                    <Imagespan dangerouslySetInnerHTML={{__html:imagespan}} />
                    <img src={pfwideimages} alt="pfwideimages" />
                </ImageWrapper>
            </CardItem>
            <ColorBall color={color}/>
        </CardMenuWrapper>
    );
}

const ImageGridTag = ({ color, portfolioimages }) => {
    return (
        <ImageGridWrapper className='ImageGrid' color={color}>
            {portfolioimages.map((image, index) => (
                <img src={image} alt={`portfolioimage${index}`} key={index} />
            ))}
        </ImageGridWrapper>
    )
};


const PortfolioFrame = () => {
    const color = ['#000000', '#AB704E', '#496F72','#4E2818','#D7BFA9']
    const tags = [['# 파티룸','# 빈티지','# 인더스트리얼'],
    ['# 펜션','# 빈티지','# 북유럽'],
    ['# 아파트','# 엔티크','# 북유럽'],
    ['# 싱글','# 레트로','# 빈티지'],
    ['# 싱글','# 레트로','# 빈티지']]
    
    const imagespan = ['카페같은 거실에서 빈티지하고 <br />유니크하게 커피 한잔',
        '오크톤의 호텔 라운지에서 <br/>낭만을 즐기는 시원한 휴식',
        '휴양지에 온 듯한 북유럽풍의 <br/>고급스러운 방에서의 단장',
        '나만의 취향과 감각을 담아낸<br/>유니크한 서재에서 잠깐의 휴식',
        '따뜻한 무드 속 야경을 바라보며<br/>나만의 싱글라이프를 럭셔리하게'
    ]
    const portfolioimages = [
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port1.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port2.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port3.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port4.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port5.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port6.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port7.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port8.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port9.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port10.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port11.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port12.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port13.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port14.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port15.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port16.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port17.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port18.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port19.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port20.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port21.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port22.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port23.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port24.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port25.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port26.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port27.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port28.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port29.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/port30.png"
    ]
    const pfwideimages = [
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/pfwide1.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/pfwide2.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/pfwide3.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/pfwide4.png",
        "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/pfwide5.png"
    ]
    // const [portfolioimages, setportfolioimages] = useState([]);
    // const [pfwideimages, setpfwideimages] = useState([]);

    // useEffect(() => { // 이미지 불러오기
        
    //     const loadImages = async () => {
    //         const loadedImages = [];
    //         const loadedWideImages = [];
    //         for (let i = 1; i <= 30; i++) {
    //             const image = await import(`assets/mobile/portfolio/pfwide${i}.png`);
    //             loadedImages.push(image.default);
    //             if (i <= 5) {
    //                 const wideimage = await import(`assets/mobile/portfolio/pf${i}.png`);
    //                 loadedWideImages.push(wideimage.default);
    //             }
    //         }
    //         setpfwideimages(loadedWideImages);
    //         setportfolioimages(loadedImages);
    //     };
    //     loadImages();
    // }, []);

    if (!pfwideimages || !portfolioimages) {
        return <CatSpinner />
    }

    

    return (
        <>
        <ServiceSection>
        <Header className='serviceheader'>
            <Text>
                <div className="title">홈스타일링 / 인테리어</div>
                <div className="content">PORTFOLIO</div>
            </Text>
        </Header>
        {pfwideimages && portfolioimages && pfwideimages.map((image, index) => {
            return (
            <>
                <Body className='servicebody'>
                    <BodyWrapper>
                        <CardMenu color={color[index]} tags={tags[index]} imagespan={imagespan[index]} pfwideimages={image} />
                    </BodyWrapper>
                </Body>
                <ImageGridTag color={color[index]} portfolioimages={portfolioimages.slice(index*6, index*6+6)} />
            </>)
        })}
        </ServiceSection>
        </>
    );
}

export default PortfolioFrame;
import * as React from 'react';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import BigPicModal from 'components/BigPicModal';

const Item = styled.div`
    position: relative;
    cursor: pointer;
    img {
        width: 300px;
    }
    span {
        font-size: 1.2rem;
    }  
`;



export default function ResponsiveGrid( images ) {
    const imagesList = images.image;
    console.log("IMAGESLIST", imagesList)
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    
    const handleClickOpen = (images) => {
        console.log("IMAGES", images);
        setSelectedImage(images);
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        console.log('checkopen state', open);
    }
    , [open]);

    return (
        
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} >
                {imagesList.map((data, index) => 
                (
                    <Grid item xs={2} sm={4} md={6} key={index}>
                    <Box component="div">
                        <Item >
                            <img 
                                className='hoveringimage' 
                                src={data?.file} 
                                alt={data?.description? data.description : '1'} 
                                onClick={() => handleClickOpen(imagesList)}
                            />
                            <span 
                            className='desc'
                            >
                                {data?.description? data.description : ''}

                            </span>
                        </Item>
                    </Box>
                    </Grid>
                )
                )}
            </Grid>
            <BigPicModal open={open} handleClose={handleClose} selectedImage={selectedImage}/>
        </Box>
    );
}
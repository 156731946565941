import React from 'react'
import styled from 'styled-components'

// import BlackButton from 'element/BlackButton';
import BeigeButton from 'element/BeigeButton';
const ServiceSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 4rem 3.2rem;
    justify-content: center;
    align-items: center;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 32.7rem;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const CardMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding: 0rem 2.4rem;
`;

const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 29.7rem;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 0.8rem;
`;

const PromotionTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: left;
    padding: 2.5rem 0 1.8rem 0;

    .content {
        color: #232120;
        font-size: 2.8rem;
        font-weight: 900;
        font-family: Pretendard;
        line-height: 110%;
        letter-spacing: 0%;
        text-align: left;
    }
    .title {
        padding: 0.5rem 0;
        color: #232120;
        font-size: 1.4rem;
        font-weight: 500;
        font-family: Pretendard;
        text-align: left;
    }
`;


const FreePromotion = () => {
    return (
        <>
        <CardMenuWrapper>
            <CardItem>
                <PromotionTextWrapper>
                    <div className="content">이 모든 일이 <br />하루만에!</div>
                    <div className="title">하루만에 만들어지는<br /> 마법같은 내공간 둘러보기</div>
                </PromotionTextWrapper>
                <BeigeButton className='service1button' position={'center'} width={'29.7rem'} height={'6.5rem'} fontsize={'2rem'} fontweight={'900'} margintop={'0'} bcolor={'#F3F0E7'} radius={'0'}>무료 체험하기</BeigeButton>
                {/* <BlackButton position={'center'} fontWeight={'600'} fsize={'2rem'} bradius={'0'} width={'29.7rem'} height={'6.4rem'} fontFamily={'inherit'} >무료 체험하기</BlackButton> */}
            </CardItem>
        </CardMenuWrapper>
        </>
    );
}

const PromotionComp = () => {
    return (
        <>
        <ServiceSection>
            <Body className='servicebody'>
                <BodyWrapper>
                    <FreePromotion />
                </BodyWrapper>
            </Body>
        </ServiceSection>
        </>
    );
}

export default PromotionComp;
import React from "react";
import styled from "styled-components";
import { boxBorderRadius } from "../utils/style/mixins";

const CustomBtn = styled.button`
  cursor: pointer;
  width: 100%;
  height: 4.8rem;
  font-size: 1.5em;
  font-weight: 700;
  pointer-events: inherit;
  ${boxBorderRadius};
  background-color: #566fee;
  color: white;
`;

const Button = ({ children, onClick, disabled=false }) => {
  return (
    <CustomBtn type="submit" onClick={onClick} disabled={disabled}>
      {children}
    </CustomBtn>
  );
};

export default Button;
import React from 'react'
import styled from 'styled-components'

//images
import MobileBeigeButton from 'element/Mobile/MobileBeigeButton';
import { useNavigate } from 'react-router-dom';

const ServiceSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 0rem;
`;
const Text = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;

    .title {
        color: #222222;
        font-family:Pretendard;
        font-size: 1.3rem;
        font-weight: regular;
        text-align: center;
    }
    .content {
        color: #222222;
        font-family: Pretendard;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    
    padding: 13rem 3.2rem 4rem 3.2rem;
    justify-content: center;
    align-items: center;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 32.7rem;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const CardMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    padding: 0rem 2.4rem;
`;

const CardItem = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 29.7rem;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 0.8rem;
    img {
        width: 19rem;
        height: 19rem;
        border-radius: 1rem;
    }
`;

const ColorBall = styled.div`
    display: flex;
    position: absolute;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    z-index: 1;
    top: -2.5rem;
    left: 3rem;
`;

const SpanWrapper = styled.span`
    display: flex;
    position: absolute;
    left: 50%;
    top: 85%;
    transform: translate(-50%, -50%);
    z-index: 1;

    color: #FFFFFF;
    white-space: nowrap;
    text-shadow: dropshadow 1px 1px 1px #000000;
    font-family: "Noto Sans KR";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
`;


const CardMenu = () => {
    const cardTitles = ["카페같은 거실에서 빈티지하고<br/> 유니크하게 커피 한잔", 
    "휴양지에 온 듯한 북유럽풍의<br/>고급스러운 방에서의 단장",
    "오크톤의 호텔 라운지에서<br/>낭만을 즐기는 시원한 휴식"]
    const cardImages = ["https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainport1.png", 
    "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainport2.png",
    "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainport3.png"]
    const color = ['#060100', '#496F72', '#C88C61']
    
    return (
        <>
        <CardMenuWrapper>
        {cardTitles.map((title, index) => {
            return (
                <>
                <CardItem key={index}>
                    <ColorBall color={color[index]}/>
                    <SpanWrapper dangerouslySetInnerHTML={{ __html: title}}></SpanWrapper>
                    <img className={'ball'+index} src={cardImages[index]} alt="description" />
                </CardItem>
                </>
            );
        })}
        </CardMenuWrapper>
        </>
    );
}



const PortfolioGrid = () => {
    const navigate = useNavigate();
    return (
        <>
        <ServiceSection>
            <Header className='serviceheader'>
                <Text>
                    <div className="title">홈스타일링 / 인테리어</div>
                    <div className="content">PORTFOLIO</div>
                </Text>
            </Header>
            <Body className='servicebody'>
                <BodyWrapper>
                    <CardMenu />
                </BodyWrapper>
            </Body>
            <Footer>
                <MobileBeigeButton onClick={()=> navigate('/portfolio')} position={'center'} width={'31.1rem'} height={'3.4rem'} fontsize={'1.2rem'} fontweight={'800'} margintop={'0'} bcolor={'#E3DAC5'}>더보기</MobileBeigeButton>
            </Footer>
        </ServiceSection>
        </>
    );
}

export default PortfolioGrid;

import React from 'react';
import styled from 'styled-components';
import SquareBeigeButton from 'element/Mobile/SquareBeigeButton';

const ImageWrapper2 = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
    }
`;


const PromotionTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 11.4rem;
    justify-content: flex-start;
    align-items: left;
    padding: 2.5rem 0 1.5rem 0;

    .content {
        color: #232120;
        font-size: 2.8rem;
        font-weight: 900;
        font-family: Pretendard;
        line-height: 110%;
        letter-spacing: 0%;
        text-align: left;
    }
`;

const PromoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 4rem 1.5rem 0 1.5rem;
`;


const CardMenuWrapper1 = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 29.7rem;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 0.8rem;
`;

const FreePromotion = () => {
    return (
        <>
        <PromoWrapper>
            <ImageWrapper2 className='imagewrapper'><img src="https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/phone.png" alt='career'></img></ImageWrapper2>
            <CardMenuWrapper1>
                <CardItem>
                    <PromotionTextWrapper>
                        <div className="content">지금, 바로 <br />경험해보세요!</div>
                    </PromotionTextWrapper>
                    <SquareBeigeButton position={'center'} fontWeight={'800'}>무료 체험하기</SquareBeigeButton>
                </CardItem>
            </CardMenuWrapper1>
        </PromoWrapper>
        </>
    );
}

export default FreePromotion;
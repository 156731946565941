import React from 'react';
import styled from 'styled-components';
import Button from 'element/Button';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
// import { getFavPicDetail, postFavPicCreate, putFavPicEdit } from 'utils/api/api';
import { isAdminEditModalState } from 'store/atoms';
import { useRecoilValue } from 'recoil';
import { getCookie } from 'utils/cookie/cookie';

const Headerele = styled.h2`
    font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue',
    'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'sans-serif !important';
    font-weight: bold;
    font-size: 27px;
    margin-bottom: 0.8rem;
    width: fit-content;
    align-self: flex-start;
`;

const SignForm = styled.form`
    width: 100%;
    display: flex;
    align-items: left;
    justify-content: flex-start;
    flex-direction: column;

    label.submitfield {
        display: flex;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1rem;
        padding: 0.3rem 0;
        color: rgb(165, 165, 165);
        pointer-events: none;
    }

    button {
        margin-top: 1.5rem;
    }
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
    padding-right: 30px;
    flex: 1;
    height: 3rem;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    right: 10px;

    padding: 0 1rem;
    color: black;
    font-weight: 500;

    border-radius: 0.5rem;
    border: 0.15rem solid ${(props) => props.theme.borderColor};
    &:focus {
        outline: none;
    }
`;

// 퍼센트 기호 스타일
const PercentSymbol = styled.span`
    position: absolute;
    right: 10px;
    top: 36%;
    pointer-events: none;
`;

const InputField = ({ label, type, register, error }) => (
    <>
    <label className='submitfield' htmlFor={type}>{label}</label>
    <InputWrapper>
      <StyledInput type={type} {...register} />
      <PercentSymbol>%</PercentSymbol>
    </InputWrapper>
    {error && <span className="error">{error.message}</span>}
    </>
  );

const HiddenFileInput = styled.input`
  display: none;
`;

const ImageLabel = styled.label`

  cursor: pointer;
  width: 8rem;
  height: 8rem;
  padding: 1rem 0;
  border-radius: 0.5rem;
  border: 0.05rem solid ${(props) => props.theme.borderColor};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
    cursor: pointer;
  }
`;

const ImageForm = ( {selectedImage, setSelectedImage}) => {
    const { register, handleSubmit, formState: { errors, isValid } } = useForm({
      defaultValues : {
        classic: selectedImage.meta.classic*100,
        natural: selectedImage?.meta?.natural*100,
        nordic: selectedImage?.meta?.nordic*100,
        modern: selectedImage?.meta?.modern*100,
        vintage: selectedImage?.meta?.vintage*100,
        pop: selectedImage?.meta?.pop*100
      }
    });
    const open = useRecoilValue(isAdminEditModalState);
    const token = getCookie('jwt');
    const [image, setImage] = useState(null);

    const onImageChange = (data) => {
        setSelectedImage({...selectedImage, images:data});
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setImage(reader.result);
            onImageChange(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };

    const onsubmit = async (data, e) => {
        e.preventDefault();
        console.log("SUBMIT", data);
        // await onSave(data);
    };

    console.log("image", image);
    console.log("errors", errors);
    console.log("token", token);

    useEffect(() => {
        console.log("IMAGE", selectedImage);
        console.log("selectedImage?.meta?.classic", selectedImage?.meta?.classic);
    })

    return (
        open ?
        <>
        <Headerele>상품 수정/등록</Headerele>
        <SignForm onSubmit={handleSubmit(onsubmit)}>
            <ImageLabel htmlFor="fileInput">
              {selectedImage?.images ? (
                <>
                <HiddenFileInput id="fileInput" type="file" accept="image/*" onChange={handleImageChange}/>
                <img src={selectedImage?.images} alt="preview" />
                </>
                ) : (
                  <input type="file" id="fileInput" accept="image/*" onChange={handleImageChange} />
              )}
            </ImageLabel>
            <InputField
              label="클래식"
              type="text"
              register={register("classic", {
                    required: "클래식 값을 입력해주세요."
                })}
            />
            <InputField
              label="내추럴"
              type="text"
              register={register("natural", {
                    required: "내추럴 값을 입력해주세요."
                })}
            />
            <InputField
              label="노르딕"
              type="text"
              register={register("nordic", {
                    required: "노르딕 값을 입력해주세요."
                })}
            />
            <InputField
              label="모던"
              type="text"
              register={register("modern", {
                    required: "모던 값을 입력해주세요."
                })}
            />
            <InputField
              label="빈티지"
              type="text"
              register={register("vintage", {
                    required: "빈티지 값을 입력해주세요."
                })}
            />
            <InputField
              label="팝"
              type="text"
              register={register("pop", {
                    required: "팝 값을 입력해주세요."
                })}
            />
            <Button disabled={!isValid} >등록</Button>
        </SignForm>
    </>
    :null
    );
    
};

export default ImageForm;
import React, { useRef } from 'react';
import {  useRecoilState } from 'recoil';
import { isAdminEditModalState } from 'store/atoms';
import styled from 'styled-components';
import { modalVariants } from 'utils/animation/variants';
import { boxBorderRadius, flexColumnCenter, flexRowCenter } from 'utils/style/mixins';
import { IoMdClose } from 'react-icons/io';
import ImageForm from 'components/admin/ImageForm';


const ModalWrapper = styled.div`
    z-index: 999;
    position: fixed;
    ${flexRowCenter};
    left: 0;
    top: 0;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    
    padding-top: 38rem;
    padding-bottom: 20rem;
    background-color: rgba(10, 10, 10, 0.35);
    h1 {
        font-size: 1.6rem;
    }
`;

const ModalLayout = styled.div`
    position: relative;
    overflow: auto;
    width: 36rem;
    ${boxBorderRadius}
    ${flexColumnCenter}
    background-color: white;
    flex-direction: column;
    padding:  3rem;
    display: flex;
`;

const CloseBox = styled.div`
    display: flex;
    position: flex;
    align-self: flex-end;
    cursor: pointer;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: ${props => props.show ? 'block' : 'none'};
`;

const EditModal = ({selectedImage, setSelectedImage} ) => {

    const modalRef = useRef(null);
    const [isVisibleAdminEditModal, setIsAdminEditModalState] =useRecoilState(isAdminEditModalState);

    return (
        <>
        <Overlay show={isVisibleAdminEditModal}  onClick={() => setIsAdminEditModalState(false)}/>
        <ModalWrapper
            ref={modalRef}
            onClick={(e) => {
                if (modalRef.current === e.target) {
                    console.log("CLOSE");
                    setIsAdminEditModalState(false);
                }
            }}
        >
            <ModalLayout variants={modalVariants} initial="start" animate="animate" >
                <CloseBox>
                    <IoMdClose size={30} onClick={() => setIsAdminEditModalState(false)} />
                </CloseBox>
                <ImageForm selectedImage={selectedImage} setSelectedImage={setSelectedImage}/>
            </ModalLayout>
        </ModalWrapper>
        </>
    );
};

export default EditModal;
import React from "react";
import styled from "styled-components";
import { flexColumnCenter } from "utils/style/mixins";
import { useForm, Controller } from 'react-hook-form';
import { useAuth } from "../Auth";
import ProgressBar from 'element/ProgressBar';
import {signupFormState} from 'store/atoms';
import { useRecoilState } from 'recoil';
import DateDropdownV2 from 'element/DateDropdownV2';
import DropdownButtonElem from 'element/Mobile/DropdownButtonElem';

const FormWrapper = styled.div`
    width: 55vw;
    height: 100%;
    align-items: center;
    background-color: white;
    gap: 7.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${props => props.theme.mediaQuery.desktop} {
        padding: 5.6rem 5.6rem 7.6rem 5.6rem;
        width: 70vw;
        height: 100%;
    };

    @media ${props => props.theme.mediaQuery.tablet} {
        padding: 5.6rem 5.6rem 7.6rem 5.6rem;
        width: 100vw;
        height: fit-content;
    };

    @media ${props => props.theme.mediaQuery.phone} {
        padding: 0 3rem 7.6rem 3rem;
        width: 100vw;
        height: fit-content;
    };
`;

const HeaderProgressBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${props => props.theme.mediaQuery.desktop} {
        padding: 0 12.9rem;
    };

    @media ${props => props.theme.mediaQuery.tablet} {
        padding: 0 0;
    };

    @media ${props => props.theme.mediaQuery.phone} {
        padding: 0 0;
    };
`;

const TItleWrapper = styled.div`
    width: 100%;
    height: 100%;
    ${flexColumnCenter}
`;

const BodyWrapper = styled.div`
    width: 100%;
    height: 100%;
    ${flexColumnCenter}
    gap: 5.9rem;
`;

const SubmitWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

`;

const TItleH1 = styled.span`
    font-family: Spoqa Han Sans Neo;
    font-size: 3.2rem;
    font-weight: 700;
    color: #222;
    letter-spacing: -2.56px;
`;

const TItlespan = styled.span`
    color: #757E89;
    text-align: center;
    font-family: Spoqa Han Sans Neo;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -1.28px;
`;

const SignForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 34.6rem;
    height: 100%;
    gap: 4rem;
`;

const InputContainer =  styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4.8rem;
    width: 100%;
    height: 100%;
`;


const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    mrgin-bottom: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0.8rem;


    
    input {
        display: flex;
        font-size: 1.55rem;
        letter-spacing: 0.1rem;
        width: 100%;
        height: 0.5rem;
        border: 0.1rem solid #747C87;
        padding: 1.6rem 1.6rem;
        filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12));
    
        &:focus {
            outline: none;
        }
    }
`;

const InputWrapper2 = styled.div`
    display: flex;

    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
`;
const RoundedBtn = styled.button`
    display: flex;
    width: 148px;
    height: 48px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    border-radius: 500px;
    background: #CDB36E;
    color: #FFF;
    cursor: pointer;

    span {
        color: #FFF;
        font-family: Spoqa Han Sans Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    &:active {
        background-color: #black;
        color: white;
    }
    &:focus {
        background-color: black;
        color: white;
    }

`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 7.2rem;
`;

const Labelcon = styled.div`
    align-self: flex-start;
    label {
        color: #222;
        font-family: Spoqa Han Sans Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -1.28px;
    }

    span {
        color: #FF7235;
        font-family: Spoqa Han Sans Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1.28px;
    };
`;

const TextContainer2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.9rem;
    width: 100%;

    input {
        display: flex;
        font-size: 1.55rem;
        letter-spacing: 0.1rem;
        width: 100%;
        height: 100%;
        border: 0.1rem solid #747C87;
        padding: 1.6rem 1.6rem;
        filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12));
    
        &:focus {
            outline: none;
        }
    }
    `;

const InputContainer1 = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 8px;

    input {
        height: 50px;
    }

    span {
        color: ${props => props.color};
        font-family: Spoqa Han Sans Neo;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.96px;

    }
`;

const InputContainer2 = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    gap: 8px;
`;

const ColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 8px;
`;

const InputField = ({  type, register, error, maxLength }) => (
    <>
        <ColWrapper>
        <input type={type} {...register} maxLength={maxLength}/>
        {error && <span className="error">{error.message}</span>}
        </ColWrapper>
    </>
);

const InputUserInfo = () => {
    const [signupForm, setSignupForm] = useRecoilState(signupFormState);
    const { handleSignup } = useAuth();
    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        control,
        watch,
    } = useForm({ mode: 'all' });
    const watchBirthday = watch("birthday");
    console.log("watchBirthday : ", watchBirthday)
    const onSubmit = (data) => {
        if (watchBirthday && watchBirthday.year && watchBirthday.month && watchBirthday.day) {
            data.birthday = `${watchBirthday.year}-${watchBirthday.month}-${watchBirthday.day}`;
        }
        setSignupForm((prevForm) => {
            const updateForm = {
            ...prevForm,
            // lastsignupstage: 3, // 마지막 단계
            ...data,
            };
            try {
                handleSignup(updateForm);
            } catch (error) {
                console.log("error : ", error);
            }
        }
        );
    };

    return (
        <>
        <FormWrapper>
            <HeaderProgressBar>
                <ProgressBar activePoint={signupForm.lastsignupstage}/>
            </HeaderProgressBar>

            <TItleWrapper>
                <TItleH1>회원정보</TItleH1>
                <TItlespan>회원정보는 기능 활용에  필요합니다.</TItlespan>
            </TItleWrapper>

            <BodyWrapper>
                <SignForm onSubmit={handleSubmit(onSubmit)} onClick={(e) => e.stopPropagation()}>
                    <InputContainer>
                        <InputWrapper>
                            <Labelcon>
                                <label htmlFor="name">이름(닉네임)
                                <span className="required"> (필수)</span>
                                </label>
                            </Labelcon>
                            <InputContainer1 color="#FF3535">
                                <InputField
                                    type="text"
                                    register={register("name", {
                                        required: "이름을 입력해주세요.",
                                        minLength: {
                                            value: 2,
                                            message: "이름은 최소 2자 이상 입력해주세요."
                                        }
                                    })}
                                    error={errors.name}
                                />
                            </InputContainer1>
                        </InputWrapper>
                        <InputWrapper2>
                            <TextContainer2>
                                <Labelcon>
                                    <label htmlFor="birthday">생년월일
                                    <span className="required"> (필수)</span>
                                    </label>
                                </Labelcon>
                                <InputContainer2>
                                <Controller
                                    name="birthday"
                                    control={control}
                                    defaultValue={{ year: "년도", month: "월", day: "일" }}
                                    render={({ field: { onChange, value } }) => (
                                        <DateDropdownV2
                                            selectedYear={value.year}
                                            setSelectedYear={(year) => { value.year = year; onChange(value); }}
                                            selectedMonth={value.month}
                                            setSelectedMonth={(month) => { value.month = month; onChange(value); }}
                                            selectedDay={value.day}
                                            setSelectedDay={(day) => { value.day = day; onChange(value); }}
                                        />
                                    )}
                                />
                                </InputContainer2>
                            </TextContainer2>
                        </InputWrapper2>
                        <InputWrapper2>
                            <TextContainer2>
                                <Labelcon>
                                    <label htmlFor="text">휴대전화
                                    <span className="required"> (필수)</span>
                                    </label>
                                </Labelcon>
                                <InputContainer1 color="#FF3535">
                                    <InputField
                                        id = "phonefirst"
                                        type="text"
                                        register={register("phonefirst")}
                                        maxLength={3}
                                    />
                                    <InputField
                                        type="phonemiddle"
                                        register={register("phonemiddle")}
                                        maxLength={4}
                                    />
                                    <InputField
                                        type="phonelast"
                                        register={register("phonelast")}
                                        maxLength={4}
                                    />
                                </InputContainer1>
                            </TextContainer2>
                        </InputWrapper2>
                        <InputWrapper2>
                            <TextContainer2>
                                <Labelcon>
                                    <label htmlFor="password">성별
                                    </label>
                                </Labelcon>
                                <InputContainer2>
                                <Controller
                                    name="gender"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <DropdownButtonElem 
                                        selectedVal={value}
                                        setSelectedVal={onChange}
                                        />
                                    )}
                                    />
                                </InputContainer2>
                            </TextContainer2>
                        </InputWrapper2>
                    </InputContainer>
                    <SubmitWrapper>
                        <ButtonContainer>
                            <RoundedBtn type="submit" disabled={!isValid}><span>다음</span></RoundedBtn>
                        </ButtonContainer>
                    </SubmitWrapper>
                </SignForm>
            </BodyWrapper>
        </FormWrapper>
        </>
    );  
};

export default InputUserInfo;

import React  from "react";
import styled from "styled-components";
import useMediaQuery from '@mui/material/useMediaQuery';

// import {SkeletonListElement} from '../element/SkeletonListElement';

// comps
import Bigwall from './../components/Bigwall';
// import VideoComponent from 'components/VideoComponent';

// elems
import StrokeLine from 'element/StrokeLine';

// images
import promis from '../assets/promis.png';
import st1 from '../assets/st1.png';
import st2 from '../assets/st2.png';
import st3 from '../assets/st3.png';
import BeigeButton from "element/BeigeButton";

const HeadSectionContainer  = styled.div`
    height: auto;
`;

const BodySectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 15rem;
`;


const MainBodyTitle = styled.div`
    display: flex;
    justify-content: center;
`;

const BodyTitle = styled.div`
    display: flex;
    position: absolute;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 4rem;
    font-weight: 900;
    font-family: Notosans KR;
    margin-left: 10rem;
`;

const RBodyTitle = styled.div`
    display: flex;
    z-index: 2;
    position: absolute;
    margin-left: -35rem;
    text-align: right;
    justify-content: center;
    font-size: 4rem;
    font-weight: 900;
    font-family: Notosans KR;
`;


const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

const H2Title = styled.h2`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4.8rem;
    font-weight: bold;
    font-family: 'Pretendard';
    color: black;
`;

// const SmallTitle = styled.span`
//     display: flex;
//     align-items: center;
//     text-align: center;
//     justify-content: center;
//     color: #999999;
//     text-align: center;
//     font-family: Noto Sans KR;
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 200;
//     letter-spacing: 2%;
//     width: 100%;
//     height: fit-content;
//     margin-top: 2rem;
// `;


const TopSmallTitle = styled.span`
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #FFFFFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 200;
    letter-spacing: 2%;
    width: 100%;
    height: fit-content;
    margin-top: 2rem;
`;

const BodyFeatures = styled.section`
    display: flex;
    flex-direction: rows;
    width: 100%;
    height fit-content;
    align-items: center;
    justify-content: center;
    gap: 10rem;
    margin-left: auto;
    margin-right: auto;
`;

const TextWrapper  = styled.div`
    display: flex;
    flex-direction: column;
    height: 1
    padding: 0 4rem;
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    

    video {
        height: 100%;
        width: 48.1rem;
    }
`;

const H3Title = styled.h3`
    display: flex;
    align-items: left;
    justify-content: flex-start;
    font-size: 2.4rem;
    font-weight: 700;
    font-family:Pretendard;
    margin-top: 1.5rem;

`;

const UlComponent = styled.ul`

    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
    color: #444444;
    li {
        list-style-type : disc;
        margin-top: 1rem;
        font-size: 1.8rem;
        font-family: Pretendard;
        font-weight: normal;
    }
`;

const ImageWrapper3 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 63.6rem;
    img {
        width: 100%;
        height: auto;
        object-fit: cover;
        
    }
`;

const Bodybar = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    padding: 0 12rem;
    gap: 4rem;
    margin-left: auto;
    margin-right: auto;
    img {
        width: 100%;
        height: auto;
    }
`;

const H1Title = styled.h1`
    display: flex;
    align-items: left;
    justify-content: flex-start;
    font-size: 3.6rem;
    font-weight: bold;
    font-family: 'Notosans KR';
    color: black;
    margin-top: 3rem;
`;

const TextWrapper2 = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
`;

const Barspan = styled.span`
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
    font-size: 1.6rem;
    font-weight: 100;
    font-family: 'Noto Sans KR';
    color: #000;
    white-space: nowrap;
`;

const BodyStrength = styled.section`
    display: flex;
    flex-direction: rows;
    width: 85rem;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 15rem;
    margin-left: auto;
    margin-right: auto;
`;

// make Coloredbox and img tags are insdie  Bodyimage but overlay each other
const Bodyimage = styled.div`
    display: flex;
    width: fit-content;
    height: auto;
    position: relative;

    img {
        width: 33.5rem;
        height: 33.5rem;
        display: flex;
        position: absolute;
        top: 8rem;
        left: 8rem;
        object-fit: cover;
    }
`;

const RBodyimage = styled.div`
    display: flex;
    width: fit-content;
    height: auto;
    position: relative;

    img {
        width: 33.5rem;
        height: 33.5rem;
        display: flex;
        position: absolute;
        top: 8rem;
        right: 8rem;
    }
`;


const Coloredbox = styled.div`
    display: flex;
    position: relative;
    width: 38rem;
    height: 38rem;
    background-color: #EFE8DD;
`;

const RColoredbox = styled.div`
    display: flex;
    position: relative;
    width: 38rem;
    height: 38rem;
    background-color: #EFE8DD;
`;


const BodySpan = styled.span`
    display: flex;
    position: absolute;
    align-items: flex-end;
    justify-content: flex-end;

    margin-left: 10rem;
    margin-top: 30rem;

    text-align: left;
    font-size: 2rem;
    font-weight: 400;
    font-family: Noto Sans KR;
    line-height: 3rem;
    color: #000;
    white-space: nowrap;
`;

const RBodySpan = styled.span`
    display: flex;
    position: relative;
    align-items: flex-end;
    justify-content: flex-end;

    margin-right: 10rem;
    margin-top: 26rem;

    text-align: right;
    font-size: 2rem;
    font-weight: 400;
    font-family: Noto Sans KR;
    line-height: 3rem;
    color: #000;
    white-space: nowrap;
`;

const BodyText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const Blank = styled.div`
    display: flex;
    width: 100%;
    height: ${props => props.height ? props.height : '10rem'};
`;

const TextContainer = styled.div`
    display: flex;
    position: relative;
    height: fit-content;
    align-items: center-left;
    flex-direction: column;

    .titlespan {
        
        height: fit-content;
        display: flex;
        flex-direction: column;
    }

    .tt {
        color: #616161;
        font-family: "Noto Sans KR";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */
    }

    .span {
        color: #8B8B8B;
        font-family: "Noto Sans KR";
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 131%; /* 20.96px */
    }
`;


const TopTitle = styled.h1`
    display: flex;
    align-items: center;
    color: white;
    font-family: Pretendard;
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 5%;
    width: 100%;
    justify-content: flex-start;
`;

const Stagediv = ({stage}) => {
    return (
        <BeigeButton width={'7.4rem'} height={'2.5rem'} fontsize={'1rem'}>핵심기술{stage}</BeigeButton>
    )
};

const Intro = () => {
    const matches = useMediaQuery('(max-width: 1015px)');
    // const [isLoading, setIsLoading] = useState(true);
    return (
    <>
      {/* 모바일 */}
      {matches ? (
        < ></>
      ) : (
        <>
        <HeadSectionContainer className="sectioncont">
            <Bigwall top={'-15%'} left={'-15%'} image={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/compintro.png'} backgroundColor={'transparent'} >
                <TextContainer className="textcont">
                    <TopTitle>회사 소개</TopTitle>
                    <TopSmallTitle className="titlespan">메타디자인하우스와 함께라면, 설레는 변화를 즐길 수 있습니다.</TopSmallTitle>
                </TextContainer>
            </Bigwall>
        </HeadSectionContainer>
        <Blank  height={'30rem'}/>
        <BodySectionContainer>
            <MainBodyTitle id="ourTechnology" className="bodyTitle">
                <BodyWrapper className="wrapper">
                    <H2Title>핵심 기술</H2Title>
                    {/* <SmallTitle>메타디자인하우스만의 핵심 기술</SmallTitle> */}
                </BodyWrapper>
            </MainBodyTitle>

            <BodyFeatures className="bodyfeatures">
                <ImageWrapper>
                    <ImageWrapper3 className="img2">
                        <img src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/aianalysis.png'} alt="fim1"/>
                    </ImageWrapper3>
                </ImageWrapper>
                <TextWrapper>
                    <Stagediv>{'01'}</Stagediv>
                    <H3Title>AI 기반 고객 성향 분석</H3Title>
                    <StrokeLine marginTop={'3rem'} width={'100%'} Linecolor={'#D8D8D8'} height={'0.1rem'}/>
                    <UlComponent>
                        <li>
                            AI 기반 고객 성향 분석으로 보다 정규화된 오퍼링 체계 마련
                        </li>
                        <li>
                            Big Data 기반 고객 요구사항 분석 및 AI Data 자동 학습 <br />
                            (AI 구현 30,000개 이상의 데이터)
                        </li>
                        <li>
                            학습 DB 30,000개
                        </li>
                    </UlComponent>
                </TextWrapper>
            </BodyFeatures>
            <BodyFeatures className="bodyfeatures">
                <ImageWrapper>
                    <ImageWrapper3 className="img2">
                        <img src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/simul3d.png'} alt="simul3d"/>
                    </ImageWrapper3>
                </ImageWrapper>
                <TextWrapper>
                    <Stagediv>{'02'}</Stagediv>
                    <H3Title>실감형 3D 시뮬레이션</H3Title>
                    <StrokeLine marginTop={'3rem'} width={'100%'} Linecolor={'#D8D8D8'} height={'0.1rem'}/>
                    <UlComponent>
                        <li>
                            디자인 미학 평가에 의한 선호도 예측 시스템 구축
                        </li>
                        <li>
                            실감형 3D 시뮬레이션 기술 개발<br/>
                            (공간 3D 에셋 2,900개, 가구 3D 에셋 360개, 추가 양산 중)
                        </li>
                        <li>
                            학습 DB 3,260개
                        </li>
                    </UlComponent>
                </TextWrapper>
            </BodyFeatures>
            <Blank height={'5rem'}/>
            <BodyWrapper className="wrapper">
                    <H2Title>약속</H2Title>
                    {/* <SmallTitle>입주까지 믿고 맡길 수 있도록 책임지겠습니다</SmallTitle> */}
            </BodyWrapper>
            <Bodybar>
                <img id="promise" src={promis} alt={promis} />
                <TextWrapper2>
                    <H1Title>투명하고 체계적인 인테리어 프로세스</H1Title>
                    <Barspan>아티코디자인은 체계적인 프로세스와 투명한 견적으로 고객의 불안을 해소합니다.<br/>
전용 앱을 통해 모든 공사 현황과 마감 체크를 쉽게 확인할 수 있으며, 공사 후에도 아티코컨시어지의 A/S가 당신을 지원합니다​​.</Barspan>
                </TextWrapper2>
            </Bodybar>
            
            <BodyStrength id="direction">
                <Bodyimage>
                    <Coloredbox />
                    <img src={st1} alt={st1} />
                </Bodyimage>
                <BodyText className="textbody">
                    <BodyTitle className="bodyTitle">
                        편안한 시작부터<br/>
                        완벽한 마무리까지
                    </BodyTitle>
                    <BodySpan>첫 상담부터 공사의 마지막 단계까지,<br/>
                        모든 과정을 매뉴얼화된 프로세스로 진행합니다.<br/>
                        AI 기반의 인테리어 디자인부터 3D 시뮬레이션까지, <br/>
                        당신의 취향을 완벽히 반영한 공간을 설계합니다.
                    </BodySpan>
                </BodyText>
            </BodyStrength>
            <BodyStrength>
                <RBodyTitle className="bodyTitle">
                    체계적이고<br/>
                    효율적인 프로세스
                </RBodyTitle>
                <RBodySpan>당신의 가족이 평생 살 집, <br/>
                    따라서 체계적이고 세심한 서비스가 필요합니다. <br/>
                    계약서부터 공사 진행 상황까지 모든 정보를 <br/>
                    “메타디자인하우스” 전용 앱으로 한눈에 확인하세요.
                </RBodySpan>
                <RBodyimage>
                    <RColoredbox />
                    <img src={st2} alt={st2} />
                </RBodyimage>
            </BodyStrength>
            <BodyStrength id="direction">
                <Bodyimage>
                    <Coloredbox />
                    <img src={st3} alt={st3} />
                </Bodyimage>
                <BodyText className="textbody">
                    <BodyTitle className="bodyTitle">
                        데이터 기반의<br/>
                        투명한 가격정찰제
                    </BodyTitle>
                    <BodySpan>"메타디자인하우스"는 모든 인테리어 비용을 명확하게<br/> 
                    정의합니다. 동일한 평형에 동일한 기준을 적용하여, 견적의. <br/>
                    변동 없이 처음 제시된 가격으로 서비스를 제공합니다.
                    </BodySpan>
                </BodyText>
            </BodyStrength>
            <Blank />
        </BodySectionContainer>
        </>
      )
      }
    </>
  );
};

export default Intro;




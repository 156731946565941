import React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { UnderLine } from 'utils/style/mixins';
import Button from 'element/Button';
import { useForm } from 'react-hook-form';
import { useAuth } from "../Auth";
import { AuthContext } from 'utils/func/AuthContext';

const Headerele = styled.h2`
    font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue',
    'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'sans-serif !important';
    font-weight: bold;
    font-size: 27px;
    margin-bottom: 0.8rem;
    width: fit-content;
    align-self: flex-start;
`;

const SignForm = styled.form`
    width: 36rem;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;

    input {
        width: auto;
        height: 6rem;
        font-size: 1.55rem;
        letter-spacing: 0.1rem;
        padding: 0 1.2rem;
        border: 0.15rem solid ${(props) => props.theme.borderColor};
        &:focus {
            outline: none;
        }
    }
    span {
        line-height: 3rem;
        font-size: 1.55rem;
        color: black;
    }

    label {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        padding: 0.3rem 0;
        color: rgb(165, 165, 165);
        pointer-events: none;
    }

    button {
        margin-top: 1.5rem;
    }
`;

const TogleLine = styled.div`
    width: 100%;
    height: 0.1rem;
    font-size: 1.1rem;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 0;
    &:hover {
        text-decoration: underline;
    }
`;

const InputField = ({ label, type, register, error }) => (
    <>
      <label htmlFor={type}>{label}</label>
      <input type={type} {...register} />
      {error && <span className="error">{error.message}</span>}
    </>
);

const LoginForm = ({onLoginToggle}) => {
    const { register, handleSubmit, formState: { errors, isValid } } = useForm();
    const { signInMutate } = useAuth();
    const { logoutClear } = useContext(AuthContext);

    const onsubmit = async (data, e) => {
        e.preventDefault();
        
        const userData = await signInMutate.mutateAsync(data);
        const result = userData.data;
        if (result) {
            
            // setVisibleLoginModal(false);
            alert('로그인에 성공하였습니다.');
            
        } else {
            logoutClear();
            // setVisibleLoginModal(true);
            alert('로그인에 실패하였습니다.');
            
        }
    };

    return (
        <>
        <Headerele>로그인</Headerele>
        <SignForm onSubmit={handleSubmit(onsubmit)}>
            <InputField
              label="이메일"
              type="text"
              register={register("email", {
                required: "이메일을 입력해주세요.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "유효한 이메일 주소를 입력해주세요.",
                },
                }
                )}
              error={errors.email}
            />
            <InputField
              label="비밀번호"
              type="password"
              register={register("password", {
                required: "비밀번호를 입력해주세요.",
                })}
                error={errors.password}
            />
            <Button disabled={!isValid} >로그인</Button>
        </SignForm>
        <UnderLine />
        <TogleLine isType={false} onClick={onLoginToggle}>
            회원가입..
        </TogleLine>
        </>
    )
    };

export default LoginForm;
import React from "react";
import styled from "styled-components";
import {getDetailPortfolio} from "utils/api/api";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// elements
import StrokeLine from "element/StrokeLine";

// components
import CatSpinner from 'components/CatSpinner';
import VideoComponent from 'components/VideoComponent';

const Containerss = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin-top: 8rem;
    
`;

const HeadSectionContainer  = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 5.3rem 5.7rem;
`;

const TextContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
`;

const TopTitle = styled.span`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.5rem 0;
    color: #000;
    font-family: "Noto Sans KR";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 54px */
    width: 100%;
`;

const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 4.3rem 3.2rem;
    gap: 0.6rem;
`;

const FilterCol = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 4rem;
    gap: 0.6rem;
    div {
        display: flex;
        width: 9.9rem;
        align-items: center;
        justify-content: center;
    }
`;

const FilterSpan = styled.span`
    cursor: pointer;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    font-family: "Noto Sans KR";
    color: #BCBCBC;
    white-space: nowrap;
    ${props => props.active && `
        border-top: 3px solid black;
        font-weight: 900;
        color: black;
    `}
    
`;

const BodyContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 116rem;
    height: 100%;
    gap: 0.6rem;
`;

const Gridcontainer  = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    div.grid1 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 46rem;
        max-height: 46rem;
        max-width: 26.3rem;

        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 3.2rem;
            font-weight: 900;
            font-family: "Noto Sans KR";
            white-space: pre-wrap;
        };

        .content {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-align: center;
            width: 100%;
            font-size: 1.6rem;
            font-weight: 500;
            font-family: "Noto Sans KR";
            line-height: 170%;
            white-space: pre-wrap;
        };
        
        .renderedimage {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            font-size: 1rem;
            font-weight: 500;
            font-family: "Noto Sans KR";
            white-space: nowrap;
        };
        img { 
            display: flex;
            width: 100%;
            max-width: 26.3rem;
        };
        video {
            width: 100%;
            max-width: 26.3rem;
        };

        
    }
    .bigwallpaper {
        display: flex;
        width: 100%;
        max-height: 46rem;
        height: 100%;
        object-fit: cover;
    }
`;

const Divider = styled.span`
    height: 1rem;
    border-left: 0.5px solid #BCBCBC;
    // margin-left: 0.5rem;
    // margin-right: 0.5rem;
`;

const ColImage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 45rem;
    gap: 0.6rem;
    img {
        width: 100%;
        height: 100%;
        max-height: 22.5rem;
        object-fit: cover;
    }
`;

const AddressContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 116rem;
    height: 100%;
    padding: 4.3rem 3.2rem;

    .textcontent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        gap: 5rem;
        padding: 4.3rem 3.2rem;
    }
`;

const AddressTitle = styled.span`
    display: flex;      
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 1.4rem;
    font-weight: 500;
    font-family: "Noto Sans KR";
`;

const AddressContent = styled.span`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    font-size: 2.4rem;
    font-weight: 900;
    font-family: "Noto Sans KR";
    color: black;
`;

const BodyContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 108.5rem;
    height: 100%;
    align-items: center;
    justify-contents: center;
`;

const Blank = styled.div`
    display: flex;
    width: 100%;
    height: ${props => props.height ? props.height : '20rem'};
`;

const PortfolioDetail = () => {
    const [apiData, setApiData] = useState([]);
    const { id } = useParams();
    const [active, setActive] = useState(0);
    // const [filtercols, setFilterCols] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedData, setSelectedData] = useState({});
    
    const handleClickFtiler = (index) => {
        setActive(index);
        setSelectedData(apiData.rooms[index]);
    }

    const RenderedImage = ({selectedData}) => {
        if (!selectedData.movie3dmodel && !selectedData.renderedimage) {
            return null;
        }
    
        const ComponentToRender = selectedData.movie3dmodel 
            ? <VideoComponent src={selectedData.movie3dmodel}/> 
            : <img src={selectedData.renderedimage} alt={selectedData.id}/>;
    
        return (
            <>
                <span className="renderedimage">3D 모델링</span>
                {ComponentToRender}
            </>
        );
    };


    useEffect(() => {
        const fetchData = async () => {
            const response = await getDetailPortfolio(id);
            setApiData(response);
            setSelectedData(response.rooms?.[0]);
            if(response) setTimeout(() => setLoading(false), 500);
        }
        fetchData();
    },[id]);

    if (loading) {
        return <CatSpinner />
    }

    return (
        <Containerss className="containerss">
            {/* 메인 이미지 */}
            <HeadSectionContainer className="headcontainer">
                <TextContainer>
                    <TopTitle >{apiData.portfolioapartment?.name}</TopTitle>
                    <div className="titlespan">
                        {apiData.description}
                    </div>
                </TextContainer>
            </HeadSectionContainer>
            <FilterContainer>
                <FilterCol>
                    {apiData?.rooms?.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                            <div key={index}>
                            <FilterSpan key={index} active={active === index} onClick={() => handleClickFtiler(index)}>{item.roomtype}</FilterSpan>
                            </div>
                            {index < apiData.rooms.length - 1 && <Divider />}
                            </React.Fragment>
                        );
                    })}
                </FilterCol>
                <StrokeLine Linecolor={'#D9D9D9'} width={'100%'} height={'1px'} position={'center'} marginTop ={'1rem'}></StrokeLine>
            </FilterContainer>
            <BodyContentWrapper>
                <BodyContainer>
                    <Gridcontainer className="grid1con">
                        <div className="grid1">
                            <span className="title">{selectedData.name}</span>
                            <span className="content">{selectedData.description}</span>
                            <RenderedImage selectedData={selectedData} />
                        </div>
                    </Gridcontainer>
                    <Gridcontainer>
                        <img className="bigwallpaper" src={selectedData.bigimage} alt={selectedData.id}></img>
                    </Gridcontainer>
                    <Gridcontainer>
                        <ColImage>
                            <img src={selectedData.smallimage} alt={selectedData.id}></img>
                            {selectedData.smallimage2 && 
                            <img src={selectedData.smallimage2} alt={selectedData.id}></img>
                            }
                        </ColImage>
                    </Gridcontainer>
                </BodyContainer>
                
                <AddressContainer>
                    <StrokeLine Linecolor={'#D9D9D9'} width={'100%'} height={'1px'} position={'center'} marginTop ={'5rem'}></StrokeLine>
                    <div className="textcontent">
                        <div className="title">
                            <AddressTitle>아파트</AddressTitle>
                            <AddressContent>{apiData.portfolioapartment?.name}</AddressContent>
                        </div>
                        <div className="title">
                            <AddressTitle>평형</AddressTitle>
                            <AddressContent>{apiData.portfolioapartment?.size}평</AddressContent>
                        </div>
                        <div className="title">
                            <AddressTitle>주소</AddressTitle>
                            <AddressContent>{apiData.portfolioapartment?.address}</AddressContent>
                        </div>
                    </div>
                    <StrokeLine Linecolor={'#D9D9D9'} width={'100%'} height={'1px'} position={'center'} marginTop ={'1rem'}></StrokeLine>
                </AddressContainer>
            </BodyContentWrapper>
            <Blank height={'15rem'}/>
        </Containerss>
    );
};

export default PortfolioDetail;

import StrokeLine from 'element/StrokeLine';
import React from 'react'
import styled from 'styled-components'

// components
import FreePromotion from './FreePromotion';

const ServiceSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 0rem;
`;
const Text = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;

    .title {
        color: #222222;
        font-family:Pretendard;
        font-size: 1.2rem;
        font-weight: regular;
        text-align: center;
    }
    .content {
        color: #222222;
        font-family: Pretendard;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 4rem 0;
    justify-content: center;
    align-items: center;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const CardMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding: 10rem 0 4rem 0;
`;

const CardItems = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 9.4rem;
    justify-content: center;
    align-items: center;
    padding: 4rem 0rem 3rem 0rem;

    .content {
        color: #232120;
        font-family: Pretendard;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
    }
`;

const Blank = styled.div`
    display: flex;
    width: 100%;
    height: ${props => props.height ? props.height : '8rem'};
`;

const CardMenu = () => {
    const cardTitles = ["25년 경력의 노하우", "생성형 3D AI기반 맞춤형 디자인","디자이너와 고객의 즉각적인 소통"]
    const cardImages = ["https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainimg.png",
     "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainimg2.png",
    "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainimg3.png"]
    
    return (
        <>
        <CardMenuWrapper>
        {cardTitles.map((title, index) => {
            return (
                <>
                <CardItems className='cardit'>
                    <ImageWrapper><img src={cardImages[index]} alt='career'></img></ImageWrapper>
                    <TextWrapper>
                        <div className="content">{title}</div>
                    </TextWrapper>
                    {/* <BlackButton position={'center'}>자세히보기</BlackButton> */}
                </CardItems>
                <StrokeLine Linecolor={'#F3F0E7'} width={'100%'} position={'center'} marginTop ={'0'} height={'1.72px'}></StrokeLine>
                </>
            );
        })}
        </CardMenuWrapper>
        </>
    );
}


const ServicesIntro = () => {
    return (
        <>
        <ServiceSection>
            <Header className='serviceheader'>
                <Text>
                    <div className="title">서비스 소개</div>
                    <div className="content">AI로 빠르게<br/> 3D 인테리어 컨설팅 플랫폼</div>
                </Text>
            </Header>
            <Body className='servicebody'>
                <BodyWrapper>
                    <CardMenu />
                    <Blank height={'4rem'}/>
                    <FreePromotion />
                </BodyWrapper>
            </Body>
        </ServiceSection>
        </>
    );
}

export default ServicesIntro;
import styled from "styled-components";
import { getPortfolioFilterSearch } from 'utils/api/api';
import { useQuery } from 'react-query';
import CatSpinner from 'components/CatSpinner';

const MainContentWrapper = styled.section`
    display: flex;

    flex-direction: column;
    padding: 20px;
`;

const Card = styled.div`
  
  overflow: hidden;

`;

const CardHeader = styled.div`
  background-color: transparent;
  padding: 16px;
`;

const CardTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #343a40;
`;

const CardContent = styled.div`
  padding: 16px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  background-color: #f8f9fa;
`;

const TableHeaderCell = styled.th`
  padding: 12px;
  text-align: left;
  font-weight: 600;
  border-bottom: 1px solid #e9ecef;
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
  &:hover {
    background-color: #f1f3f5;
  }
`;
const TableCell = styled.td`
  padding: 12px;
  border-bottom: 1px solid #e9ecef;
`;


const Portfolio = () => {
    // const token = localStorage.getItem('token');
    const { data: portfoliolist } = useQuery('portfoliolist', () => getPortfolioFilterSearch());
    const portfolioData = portfoliolist;
    console.log("portfolioData", portfoliolist)

    return (
        <MainContentWrapper>
        { portfolioData ? (
        <Card>
            <CardHeader>
            <CardTitle>포트폴리오 페이지 관리</CardTitle>
            </CardHeader>
            <CardContent>
            <Table>
                <TableHeader>
                <TableRow>
                    <TableHeaderCell>성함</TableHeaderCell>
                    <TableHeaderCell>SNS 채널</TableHeaderCell>
                    <TableHeaderCell>핸드폰번호</TableHeaderCell>
                    <TableHeaderCell>아파트</TableHeaderCell>
                    <TableHeaderCell>주소</TableHeaderCell>
                    {/* Add more header cells as needed */}
                </TableRow>
                </TableHeader>
                <TableBody>
                { portfolioData.map((obj) => (
                    <TableRow key={obj.user?.email}>
                      <TableCell>{obj.name}</TableCell>
                      <TableCell>{obj.user?.provider}</TableCell>
                      <TableCell>{obj.phone}</TableCell>
                      <TableCell>{obj.apartment}</TableCell>
                      <TableCell>{obj.address}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            {/* <Pagination
                page={pageNm}
                count={10}
                onChange={handleChange}
            /> */}

            </CardContent>
        </Card>
        ) : (
          <CatSpinner />
        )}
    </MainContentWrapper>
    );
}

export default Portfolio;
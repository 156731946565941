import { useContext, useEffect } from 'react';
import fetcher from 'utils/api/fetcher';
import styled from 'styled-components';
import { AuthContext } from 'utils/func/AuthContext';
import CatSpinner from 'components/CatSpinner';

const SnsLoginCallback = () => {
    const { loginSave, logoutClear } = useContext(AuthContext);
    
    useEffect(() => {  
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        if (token) {
            localStorage.setItem('token', token);
            const fetchUser = async () => {
                const res = await fetcher('/member/lgcheck', 'GET', null, token);
                const result = res.data;
                if (result) {
                    loginSave(result);
                    // alert('로그인에 성공하였습니다.');
                    window.close();
                } else {
                    alert('로그인에 실패하였습니다.');
                    logoutClear();
                }
            }
            fetchUser();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
      <Section>
      <CatSpinner/>
      </Section>
  );
};

export default SnsLoginCallback;


const Section = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: 8rem;
`;

const { Cookies } = require("react-cookie");

const cookies = new Cookies();

export const setCookie = (name, value, domain) => {
  if (domain) {
    return cookies.set(name, value, { domain: domain });
  } else {
    return cookies.set(name, value);
  }
};


export const removeCookie = (name) => {
  return cookies.remove(name);
};

export const getCookie = (name) => {
  return cookies.get(name);
};

export const popCookie = (name) => {
  const value = cookies.get(name);
  cookies.remove(name);
  return value;
}
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import useMediaQuery from '@mui/material/useMediaQuery';

//images
import blacklogo from '../assets/mobile/blacklogo.svg';
import typologo from '../assets/mobile/typologo.png';
import mlogo from 'assets/logos/mlogo.svg';

const Footiguefootfoot = styled.footer`
    display: flex;
    bottom: 0;
    width: 100%;
    background-color: #000000; 
    padding: 7rem 8rem;
    flex-shrink: 0;
`;

const FooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const LogoImg = styled.img`
    cursor: pointer;
    width: 7rem;
    height: auto;
    padding-bottom: 2rem;
`;

const MLogoImg = styled.img`
    width: 3.378rem;
    height: 2.4rem;
`;

const MTypoLogoImg = styled.img`
    width: 14.6rem
    height: 1rem;
`;
const NavWrappper = styled.nav`
    display: flex;
    flex-direction: row;
    gap: 3.2rem;
    padding-bottom: 3rem;
    padding-right: 11rem;

    strong {
        white-space: nowrap;
        font-size: 1.8rem;
        color: #BCBCBC;
        font-weight: 500;
    }
`;

const MNavWrappper = styled.nav`
    display: flex;
    flex-direction: row;
    width: 100%;

    strong {
        white-space: nowrap;
        font-size: 1.4rem;
        color: #222222;
        font-weight: 900;
    }
`;
const BusinessInfo = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 15rem;
    padding-bottom: 3rem;
    span {
        font-size: 1.2rem;
        font-weight: 300;
        color: #BCBCBC;
    }
`;

const AddressContainer = styled.div`
    display: flex;
    flex-direction: column;

    * {
        font-style: normal;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: 300;
        color: #868E96;
    }
`;        

const MAddressContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    * {
        line-height: 1rem;
        font-style: normal;
        font-family: Pretendard;
        text-decoration: none;
        font-size: 1.3rem;
        font-weight: 500;
        color: #757E89;
    }
`;      

const Csbox = styled.div`

    display: flex;
    flex-direction: column;
    * {
        white-space: nowrap;
        font-size: 1.4rem;
        color: #868E96;
    }
`;

const PolicyBox = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3rem;
    * {
        cursor: pointer;
        text-decoration: none;
        font-size: 1.4rem;
        color: #868E96;
    }
`;


const MPolicyBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;

    .privacy::after {
        content: "|";
        padding: 0 1rem;
        color: #757E89;
    }
    * {
        cursor: pointer;
        text-decoration: none;
        font-size: 1.3rem;
        font-weight: 700;
        font-family: Pretendard;
        color: #757E89;
    }
`;
const Rowrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15rem;
    padding: 1rem 7rem;
    width: 100%;

`;

const CompanyInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    span {
        cursor: pointer;
        font-size: 1.2rem;
        font-weight: 300;
        color: #868E96;
    }
`;

const ServiceInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    span {
        cursor: pointer;
        font-size: 1.2rem;
        font-weight: 300;
        color: #868E96;
    }
`;

const H2 = styled.h2`
    font-size: 2rem;
    font-weight: 800;
    color: white;
    padding-bottom: 3rem;
    white-space: nowrap;
`;

const HasLinkTag = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    a {
        white-space: nowrap;
        text-decoration: none;
        cursor: pointer;
        font-size: 1.6rem;
        font-weight: 400;
        color: #868E96;
    }
`;

const FooterSection = styled.div`
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F3F0E7;
    padding: 2.4rem 2.4rem 5.6rem 2.4rem;
    gap: 1.6rem;
`;

const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: left;
    align-items: center;
    gap: 1.2rem;
    cursor: pointer;
 
`;

const MCsbox = styled.div`
    display: grid;
    padding: 0 0 2.4rem 0;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.8rem;
    align-items: left;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
    div {
        width: 10rem;
        a {
            width: 10rem;
            text-decoration: none;
            text-align: left;
            font-family: Pretendard;
            font-size: 1.4rem;
            font-weight: 500;
            color: #222222;
        }
    }
`;

const Footer = () => {
    const matches = useMediaQuery('(max-width: 1015px)');
    const navigate = useNavigate();
    return (
        <>
        {matches ? ( 
        <FooterSection>
            <LogoContainer onClick={() => navigate("/")}>
                <MLogoImg  src={blacklogo} title="logo" />
                <MTypoLogoImg  src={typologo} title="lotygo" />
            </LogoContainer>

            <MNavWrappper>
                <strong>(주)아티코디자인</strong>
            </MNavWrappper>

            <MAddressContainer>
                    <p>경기도 성남시 분당구 성남대로 779번길 6 KT 분당빌딩</p>
                    <p>경기벤처지원센터 104-3 </p>
                    <p>대표이사 : 김의현 사업자등록번호 : 108-87-02002</p>
                    <p>전화 : 070-8743-0000</p>
                    <p>ALL RIGHTS RESERVED. 2024 @ aticodesign</p>
            </MAddressContainer>

            <MPolicyBox id="policy">
                <a href="/privacy" className="privacy">개인정보 처리방침</a>
                <a href="/terms" >이용약관</a>
            </MPolicyBox>

            <MCsbox className="grid">
                <div><a href="/services">회사소개</a></div>
                <div><a href="/">문의/상담하기</a></div>
                <div><a href="/">서비스</a></div>
                <div><a href="/">고객센터</a></div>
                <div><a href="/">공지사항</a></div>
            </MCsbox>
        </FooterSection>
        ) : (
            <Footiguefootfoot id="footer">
                <FooterWrapper>
                    <LogoImg
                        onClick={() => navigate("/")}
                        src={mlogo} title="logo"
                    />

                    <NavWrappper>
                        <strong>(주)아티코디자인</strong>
                    </NavWrappper>

                    <BusinessInfo>
                        <AddressContainer>
                            <address>
                                <p><strong>Business Registration Number:</strong> 108-87-02002 | <strong>CEO:</strong> 김의현</p>
                                <p><strong>파트너쉽 문의:</strong> <a href="mailto:cs@atico.co.kr">partnership@atico.co.kr</a></p>
                                <p><strong>Address:</strong> 경기도 성남시 분당구 성남대로 779번길 6 KT 분당빌딩 경기벤처지원센터 104-3 </p>
                                <p><strong>Email:</strong> <a href="mailto:cs@atico.co.kr">cs@atico.co.kr</a></p>
                            </address>
                        </AddressContainer>
                    </BusinessInfo>

                    <PolicyBox id="policy">
                        {/* <div onClick={() => setVisibleTermsModal(true) }> 이용 약관</div> */}
                        <a href="/terms" >이용 약관</a>
                        <a href="/privacy">개인정보 처리방침</a>
                    </PolicyBox>

                </FooterWrapper>
                <FooterWrapper className="footers">
                    <Rowrapper>
                        <CompanyInfo >
                            <H2>회사 소개</H2>
                            <HasLinkTag>
                                <HashLink smooth to="/about#ourTechnology">우리만의 기술</HashLink>
                                <HashLink smooth to="/about#promise">약속</HashLink>
                            </HasLinkTag>
                        </CompanyInfo>
                        <ServiceInfo>
                            <H2>회원권</H2>
                            <HasLinkTag>
                            <HashLink smooth to="/services#">구매</HashLink>
                            <HashLink smooth to="/services#promise">혜택</HashLink>
                            <HashLink smooth to="/services#promise">FAQ</HashLink>
                            </HasLinkTag>
                        </ServiceInfo>
                        <Csbox>
                            <p>고객센터: 070-8743-0000</p> 
                            <p>운영시간:10:00 ~ 18:00</p>
                        </Csbox>
                    </Rowrapper>
                </FooterWrapper>
            </Footiguefootfoot>
        )}
        </>
    );
};



export default Footer;
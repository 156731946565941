import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { v4 } from 'uuid';

const { persistAtom } = recoilPersist();

export const STYLES = ['pop', 'modern', 'nordic', 'classic', 'natural', 'vintage'];
export const STYLESKR = ['클래식', '모던', '내추럴', '북유럽', '팝', '빈티지'];
export const PAGES = ['/fav/pic', '/fav/pic2'];

export const isLoginModalState = atom({
    key: `state${v4()}`,
    default: false,
    effects_UNSTABLE: [persistAtom],
});

export const isNoticeModalState = atom({
    key: `state${v4()}`,
    default: true,
    effects_UNSTABLE: [persistAtom],
});

export const isLandingDetailModal = atom({
    key: `state${v4()}`,
    default: '',
});

export const signupFormState = atom({
    key: 'signupformState',
    default: {
        lastsignupstage: 1,
        privacyconscent: false, // 개인정보 수집 동의
        termsconscent: false, // 이용약관 동의
        individualconscent: false, // 개인정보 제3자 제공 동의
        codeverify: false, // 인증번호 확인
        email: '',
        password: '',
        passwordConfirm: '',
        referal: '',
        name: '',
        phonefirst: '',
        phonemiddle: '',
        phonelast: '',
        birthday: '',
        gender: '',
    },
});

export const currentisSignupForm = atom({
    key: `signupstage`,
    default: 1,
    effects_UNSTABLE: [persistAtom],
});

export const SignupAgreementFormState = atom({
    key: `state${v4()}`,
    default: false,
});

export const isAdminEditModalState = atom({
    key: `state${v4()}`,
    default: false,
});


export const isAdminLoginModalState = atom({
    key: `state${v4()}`,
    default: false,
});

export const isMyPageModalState = atom({
    key: `state${v4()}`,
    default: false,
});

export const isAdminMyPageModalState = atom({
    key: `state${v4()}`,
    default: false,
});

// 회원가입이냐 로그인이냐
export const isSignUp = atom({
    key: `state${v4()}`,
    default: false,
});

// 로그인된 유저이냐 익명이냐
export const isUserState = atom({
    key: `state${v4()}`,
    default: false,
    effects_UNSTABLE: [persistAtom],
});

export const isBottomState = atom({
    key: `state${v4()}`,
    default: false,
});

export const isVisibleTermsState = atom({
    key: `state${v4()}`,
    default: false,
});

export const isVisiblePrivacyState = atom({
    key: `state${v4()}`,
    default: false,
});


// export const isLogin = atom({
//     key : `state${v4()}`,
//     default : false,
// })

export const isHotelAddState = atom({
    key: `state${v4()}`,
    default: false,
});

export const isFilterState = atom({
    key: `state${v4()}`,
    default: false,
});

export const isSearchState = atom({
    key: `state${v4()}`,
    default: false,
});

export const globalUserInfoState = atom({
    key: `state${v4()}`,
    default: null,
    effects_UNSTABLE: [persistAtom],
});

export const selectedCheckboxesState = atom({
    key: `selectedCheckboxesState`,
    default: [],
    effects_UNSTABLE: [persistAtom],
});

export const PreviousImagesState = atom({
    key: `PreviousImagesState`,
    default: [],
    effects_UNSTABLE: [persistAtom],
});

export const selectedColorsState = atom({
    key: `selectedColorsState`,
    default: [],
});
export const selectedTagsState = atom({
    key: `selectedTagsState`,
    default: [],
});

export const selectedMatState = atom({
    key: `selectedMatState`,
    default: [],
});

export const houseState = atom({
    key: "houseState",
    default: [],
});
  
export const SearchisLoadingState = atom({
    key: `state${v4()}`,
    default: false,
});

export const userNamePersistState = atom({
    key: `username`,
    default: '',
    effects_UNSTABLE: [persistAtom],
}); // deprecated

export const errorMessageState = atom({
    key: `errorMessageState`,
    default: '',
});

// 검색 필터값
export const searchValueState = atom({
    key: `state${v4()}`,
    default: null,
});

export const changeState = atom({
    key: `state${v4()}`,
    default: false,
});

// tag 모두보기
export const isTotalTagsModal = atom({
    key: `state${v4()}`,
    default: false,
});

// tog modal true일때 가져갈 houseId
export const totalTagsHouseId = atom({
    key: `state${v4()}`,
    default: null,
});
import StrokeLine from 'element/StrokeLine';
import React from 'react'
import styled from 'styled-components'

//images
import home from 'assets/mobile/home.svg';
import thumbs from 'assets/mobile/thumbs.svg';
import threed from 'assets/mobile/threed.svg';
import consulting from 'assets/mobile/consulting.png';


const ServiceSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: top-center;
    padding: 0.8rem 0rem;
`;
const Text = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: top-center;
    gap: 0.8rem;

    .title {
        color: #222222;
        font-family:Pretendard;
        font-size: 1.3rem;
        font-weight: regular;
        text-align: center;
    }
    .content {
        color: #222222;
        font-family: Pretendard;
        font-size: 2rem;
        font-weight: 900;
        text-align: center;
        line-height: 110%;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const CardMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
    padding: 10rem 2.4rem 3.2rem 2.4rem;
`;

const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.8rem 0.8rem 4.8rem 0.8rem;
    width: 100%;
    height: 18.4rem;
    justify-content: center;
    align-items: center;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: top-center;
    align-items: top-center;
    padding: 1.5rem 0 0 0rem;
    gap: 0.8rem;

    .title {
        color: #222222;
        font-family: Pretendard;
        font-size: 1.6rem;
        font-weight: bold;
        text-align: center;
    }

    .content {
        color: #232120;
        font-family: Pretendard;
        font-size: 1.4rem;
        font-weight: medium;
        text-align: center;
        white-space: nowrap;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    img {
        border: none;
        width: 4.35rem;
        height: 4rem;
    }
`;


const CardMenu = () => {
    const ListTitles = ["우리집 찾기", "성향분석", "3D 공간 디자인", "실시간 컨설팅"]
    const ListContents = ["우리집 도면을 찾아 디자인해보세요.","자신의 라이프스타일에 맞는 디자인을 알아보세요.", 
    "내 취향에 알맞는 맞춤형 AI스타일링을<br/> 실시간 3D 시뮬레이션을 통해 만나보세요.",
    "전문 디자이너와의 실시간 상담을 통해<br />나만을 위한 디자인을 완성해보세요"]
    const cardImages = [home, thumbs, threed, consulting]
    
    return (
        <>
        <CardMenuWrapper className='cardit'>
            {ListTitles.map((title, index) => {
                return (
                    <>
                    <StrokeLine padding={'1rem'} Linecolor={'#F3F0E7'} width={'100%'} position={'center'} marginTop ={'0'} height={'1.68px'}></StrokeLine>
                    <CardItem>
                        <ImageWrapper><img src={cardImages[index]} alt='altimg'></img></ImageWrapper>
                        <TextWrapper>
                            <div className="title">{title}</div>
                            <div className="content" dangerouslySetInnerHTML={{ __html: ListContents[index] }}></div>
                        </TextWrapper>
                    </CardItem>
                    </>
                );
            })}
            <StrokeLine Linecolor={'#F3F0E7'} width={'100%'} position={'center'} marginTop ={'2rem'} height={'1.68px'}></StrokeLine>
        </CardMenuWrapper>
        </>
    );
}


const Services1 = () => {
    
    return (
        <>
        <ServiceSection>
            <Header className='service1header'>
                <Text>
                    <div className="title">서비스 이용 안내</div>
                    <div className="content">우리집 도면으로<br/> 내 손안에 맞는 공간 디자인</div>
                </Text>
            </Header>
            <Body className='service1body'>
                <BodyWrapper>
                    <CardMenu/>
                </BodyWrapper>
            </Body>
        </ServiceSection>
        </>
    );
}

export default Services1;
import React from "react";
import styled from "styled-components";

const CustomBtn = styled.button`
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  pointer-events: inherit;
  border-radius: 0.5rem;
  background-color: rgb(48, 79, 251);
  color: white;
`;

const Button = ({ children, onClick, disabled=false }) => {
  return (
    <CustomBtn type="submit" onClick={onClick} disabled={disabled}>
      {children}
    </CustomBtn>
  );
};

export default Button;
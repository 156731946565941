import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1.6rem 0 1.6rem;
    gap: 2rem;
    width: 100%;
    height: fit-content;
`;

const StyledTitle = styled.h1`
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
    color: #333;
    white-space: nowrap;
`;

const StyledContent = styled.span`
    font-family: 'Pretendard', sans-serif;
    font-size: 2.8rem;
    font-weight: 900;
    text-align: center;
    color: #333;
`;

const StyledContent1 = styled.span`
    font-family: 'Pretendard', sans-serif;
    font-size: 1.6rem;
    font-weight: 900;
    line-height: 1.8;
    text-align: center;
    color: #333;
`;



const StyledTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8 ;
    text-align: center;
    color: #333;
    font-family: 'Pretendard', sans-serif;
`;

const Top = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;


const Stories = () => {
    return (
        <StyledSection>
            <Top>
                <StyledTitle>혁신적인 인테리어 디자인 경험을 즉시 실현시킵니다.</StyledTitle>
                <StyledContent>지금, 바로 꿈의 집으로</StyledContent>
            </Top>

            <StyledTextWrapper>
                단순히 인테리어를 넘어서 당신의 라이프스타일까지 생각하는<br/> 1:1 맞춤형 디자인 메타디자인하우스는 결국 '당신'입니다.<br/>
                AI를 기반으로 라이프스타일 분석과 실시간 3D시뮬레이션을 통해
            </StyledTextWrapper>

            <StyledContent1>상상만 하던 공간이 현실화되는 모습을<br/> 실시간으로 즉시, 보여드릴 것입니다.</StyledContent1>
        </StyledSection>
    )
};

export default Stories;
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useEffect } from 'react';

const statusmapping = [
    '주문 처리중',
    '현장 방문',
    '디자인 작업 중',
    '시공 중',
    '시공 완료',
    '고객 확인 완료',
]

export default function HorizontalLinearAlternativeLabelStepper({ status }) {
    const [activeStep, setActiveStep] = React.useState(0);

    useEffect(() => {
      setActiveStep(statusmapping.indexOf(status));
    }
    , [status]);
    
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {statusmapping.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
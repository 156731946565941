import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import BeigeButton from 'element/BeigeButton';
import { useNavigate } from "react-router-dom";

import CatSpinner from 'components/CatSpinner';
import FreePromotion from './FreePromotion';

const ServiceSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
`;
const Text = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 0;
    gap: 0.8rem;

    .title {
        color: #222222;
        font-family: Pretendard;
        font-size: 1.3rem;
        font-weight: regular;
        text-align: center;
    }
    .content {
        color: #222222;
        font-family: Pretendard;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 10rem 3.2rem;
    justify-content: center;
    align-items: center;
    gap: 10rem;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 25rem;
    height: 25rem;
    background-color: ${(props) => props.color};
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
`;

const CardMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 25rem;
    height: 25rem;
    justify-content: center;
    align-items: center;
`;

const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    
    gap: 4rem;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
`;

const Imagespan = styled.span`
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    font-family: "Noto Sans KR";
    font-size: 2.4rem;
    font-weight: 900;
    color: black;
    text-align: left;
    letter-spacing: 0.05rem;
    line-height: 150%;
`;

const ImageWrapper = styled.div`
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.color};
`;


const CardMenu = ({ color,imagespan, buttonspan ,url }) => {
    const navigate = useNavigate();
    return (
        <CardMenuWrapper className='cardmenu'>
            <CardItem>
                <ImageWrapper className='imagewr' color={color}>
                    <Imagespan dangerouslySetInnerHTML={{__html:imagespan}} />
                </ImageWrapper>
                <BeigeButton onClick={()=> navigate(url)} className='linkbutton' radius={'5.3rem'} position={'center'} width={'19rem'} height={'4rem'} fontsize={'1.4rem'} bcolor={'white'}>{buttonspan}</BeigeButton>
            </CardItem>
        </CardMenuWrapper>
    );
}
const MobileCreatemyhome = () => {
    const imagespan = ['나의 <br/>라이프스타일이<br/>궁금해!',
        '나의 로망이<br/>담겨진 꿈의 집을<br/>만나고싶어!',
        '지금 바로 집을<br/>더 멋있게<br/>꾸미고싶어!'
    ]
    const buttonspan = [
        '인테리어 취향테스트',
        '3D 공간디자인 체험',
        '상담 신청하기'
    ]
    const urls = [
        "/fav/pick/1",
        "appurl",
        "/interiorcall"
    ]
    const colors = ['#E3DAC5', '#F4EEE0', '#E3DAC5']

    // check if the page is loading or not render Catspinner
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => setLoading(false), 500);
    }, []);
    if (loading) {
        return <CatSpinner />
    }
    return (

        <ServiceSection>
        <Header className='serviceheader'>
            <Text>
                <div className="title">나를 위한 디자인, 고민하지말고 지금 바로 만나보세요!</div>
                <div className="content">우리집 만들기</div>
            </Text>
        </Header>
        <Body className='servicebody' >
            {imagespan.map((contents, index) => {
                return (
                <>
                <BodyWrapper color={colors[index]}>
                    <CardMenu url={urls[index]} imagespan={contents} buttonspan={buttonspan[index]} />
                </BodyWrapper>
                </>)
            })}
            <FreePromotion />
        </Body>
        
        </ServiceSection>
    );
}

export default MobileCreatemyhome;
import React from "react";
import styled from "styled-components";
import { mobileButton } from "utils/style/mixins";

const CustomBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: Pretendard;
    font-size: 1.2em;
    font-weight: bold;
    pointer-events: inherit;
    ${mobileButton};
    background-color: white;
    color: #668C8F;
`;

const AppDownButton = ({ children, onClick, disabled=false }) => {
  return (
    <CustomBtn type="submit" onClick={onClick} disabled={disabled}>
      {children}
    </CustomBtn>
  );
};

export default AppDownButton;
const Privacytext = `
개인정보 수집 및 이용에 대한 동의

1. 수집·이용 목적 및 항목
ATICO는 사이트 회원 등록 및 관리, 출품 지원, 참가 등록, 전시 및 부대행사 운영 등 원활한 행사 운영 및 서비스 제공을 목적으로 아래와 같이 개인정보를 수집·이용합니다

2. 수집·이용 항목
아이디/비밀번호/이름(국문/영문)/생년월일/휴대전화/이메일주소/국적

3. 보유·이용기간
회원 탈퇴 시까지 (단, 관련 법령에 따라 보존이 필요한 경우는 그에 따름)

4. 동의 거부권 안내
귀하는 개인정보의 수집·이용을 거부할 수 있으나, 거부 시에는 회원 가입이 불가합니다.

`;

export default Privacytext;
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useRef } from 'react';
import styled from 'styled-components';

const PriceRangeWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  align-items: center;
  
  span {
    font-size: 1.8rem;
    font-weight: 600;
    margin-right: 1rem;
  }
  p {
    font-size: 1.5rem;
  }
`;

export default function CustomSlider() {
  const [value, setValue] = useState([20, 50]);
  const sliderRef = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  useEffect(() => {
    console.log("SLIDERREF :", sliderRef.current)
    return 
  }, []);
  return (
    <PriceRangeWrapper >
      <p>{value[0] === 1 && value[1]===100 ? '전체' : `${value[0]}~${value[1]}`}</p>
        <Box sx={{ width: 450 }}>
        <Slider
            getAriaLabel={() => '가격 범위'}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
        />
        </Box>
    </PriceRangeWrapper>
  );
}

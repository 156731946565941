import React from 'react'
import styled from 'styled-components'
import { useNavigate } from "react-router-dom";
//images
import BeigeButton from 'element/BeigeButton';


const ServiceSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: top-center;
    padding: 0.8rem 0rem;
`;
const Text = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;

    .title {
        color: #222222;
        font-family:Pretendard;
        font-size: 1.3rem;
        font-weight: regular;
        text-align: center;
    }
    .content {
        color: #222222;
        font-family: Pretendard;
        font-size: 2rem;
        font-weight: 900;
        text-align: center;
        line-height: 110%;
        white-space: nowrap;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 2.4rem 0 3.2rem 0;
    justify-content: center;
    align-items: center;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const CardMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
    
`;

const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.8rem 0.8rem 2.4rem 0.8rem;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0 0 0rem;
    gap: 0.8rem;

    .title {
        width: 100%;
        color: #232120;
        text-align: left;
        font-family: 'Pretendard';
        font-size: 1.6rem;
        font-weight: 900;
    }

    .content {
        width: 100%;
        color: #424242;
        font-family: 'Pretendard', sans-serif;
        font-size: 1.4rem;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: auto;
        border: none;
    }
`;

const HeaderTitle = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 0rem;
`;

const HeaderText = styled.div`
    color: #668C8F;
    width: 100%;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 1.2rem;
    font-weight: 900;
    text-align: left;
`;


const CardMenu = () => {
    const navigate = useNavigate();
    const ListTitles = ["나의 라이프 스타일 진단하기", "AI가 생성하는 하나뿐인 공간 만들기", "3D로 생성된 집 상담받기"]
    const ListContents = ["좋아하는 스타일의 사진을 선택해주세요. <br /> AI가 라이프스타일을 분석해 당신의 스타일에 맞는 <br/> 정보를 찾아줄 거에요.",
    "1분 이내에 AI가 당신의 취향을 분석해 라이프스타일에<br/>맞는 공간 디자인을 자동으로 생성해요. 당신이 꿈꾸던<br/>공간을 3D 화면으로 둘러볼 수 있게 될 거예요.", 
    "이제 3D화면으로 내 스타일에 맞는 공간을<br/>둘러보고 실시간으로 상담할 수 있어요. 원한다면 가구를 직접<br/>배치하거나, 다른 제품으로 교체할 수도 있어요."]
    const ListButtons =["진단하기", "둘러보기","상담신청"]
    const ListUrls = ['/fav/pick/1',
    'https://play.google.com/store/apps/details?id=com.aticodesign.mdh&pcampaignid=web_share',
    '/interiorcall']
    const cardImages = ["https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainimg4.png",
     "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainimg5.png",
    "https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mainimg6.png"]
    
    return (
        <>
        <CardMenuWrapper>
        {ListTitles.map((title, index) => {
            return (
                <>
                <CardItem>
                    <HeaderTitle>
                        <HeaderText>STEP 0{index + 1}</HeaderText>
                    </HeaderTitle>
                    <ImageWrapper><img src={cardImages[index]} alt='altimg' loading='lazy'></img></ImageWrapper>
                    <TextWrapper>
                        <div className="title">{title}</div>
                        <div className="content" dangerouslySetInnerHTML={{ __html: ListContents[index] }}></div>
                    </TextWrapper>
                    <BeigeButton className='service1button' onClick={() => navigate(ListUrls[index])} position={'left'} width={'13rem'} height={'3.4rem'} fontsize={'1.2rem'} fontweight={'800'} margintop={'2.4rem'}>{ListButtons[index]}</BeigeButton>
                </CardItem>
                </>
            );
        })}
        </CardMenuWrapper>
        </>
    );
}


const Services2 = () => {
    
    return (
        <>
        <ServiceSection>
            <Header className='service1header'>
                <Text>
                    <div className="title">서비스 이용 안내</div>
                    <div className="content">당신을 기다리는 서비스를 소개합니다.</div>
                </Text>
            </Header>
            <Body className='service1body'>
                <BodyWrapper>
                    <CardMenu/>
                </BodyWrapper>
            </Body>
        </ServiceSection>
        </>
    );
}

export default Services2;
import { useForm } from 'react-hook-form';
import Button from 'element/Button';
import { useAuth } from "../Auth";
import { flexRowCenter, UnderLine } from 'utils/style/mixins';
import styled from 'styled-components';

const Headerele = styled.h2`
    font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue',
    'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'sans-serif !important';
    font-weight: bold;
    
    font-size: 27px;
    margin-bottom: 0.8rem;
    width: fit-content;
    align-self: flex-start;
    
    
`;

const CheckContainer = styled.div`
    ${flexRowCenter}
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    `;

const TogleLine = styled.div`
    width: 100%;
    height: 0.1rem;
    font-size: 1.55rem;
    align-items: center;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

const SignForm = styled.form`
    width: 100%;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
    padding: 1rem;

    input {
        width: 100%;
        height: 6rem;
        font-size: 1.55rem;
        letter-spacing: 0.1rem;
        padding: 0 1.2rem;
        border: 0.15rem solid ${(props) => props.theme.borderColor};
        &:focus {
            outline: none;
        }
    }
    span {
        line-height: 3rem;
        font-size: 1.55rem;
        color: black;
        margin-top: 1rem;
    }
    label {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        color: rgb(165, 165, 165);
        pointer-events: none;
    }
    button {
      margin-top: 1.5rem;
    }
`;

const SignupForm = ({ onLoginToggle }) => {
    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        watch
      } = useForm({ mode: 'all' });
    const { handleSignup } = useAuth();
  
    const onsubmit = async (data) => {
        console.log("SUBMITING DATA : ", data);
        await handleSignup(data, true).then(
            (res) => console.log("RES : ", res),
            (err) => console.log("ERR : ", err)
        )
        .catch((err) => console.log("ERR : ", err));
    };
    
    return (
      <>
        <Headerele>회원 가입</Headerele>
        <SignForm onSubmit={handleSubmit(onsubmit)}>
          <CheckContainer>
            <InputField
              label="이메일"
              type="text"
              register={register("email", {
                required: "이메일을 입력해주세요.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "유효한 이메일 주소를 입력해주세요.",
                },
              })}
              error={errors.email}
            />
            <InputField
              label="비밀번호"
              type="password"
              register={register("password", {
                required: "비밀번호를 입력해주세요.",
                minLength: {
                  value: 2,
                  message: "비밀번호는 2자 이상이어야 합니다.",
                },
              })}
              error={errors.password}
            />
            <InputField
              label="비밀번호 확인"
              type="password"
              register={register("passwordConfirm", {
                required: true,
                validate: (value) => {
                  return (
                    value === watch('password') || "비밀번호가 일치하지 않습니다."
                  );
                },
              })}
              error={errors.passwordConfirm}
            />
          </CheckContainer>
          <Button disabled={!isValid}>회원가입</Button>
        </SignForm>
        <UnderLine />
        <TogleLine isType={false} onClick={onLoginToggle}>
          로그인..
        </TogleLine>
        
      </>
    );
  };
  
  const InputField = ({ label, type, register, error }) => (
    <>
      <label htmlFor={type}>{label}</label>
      <input type={type} {...register} />
      {error && <span className="error">{error.message}</span>}
    </>
  );
  
  export default SignupForm;
  

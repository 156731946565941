import styled, { css } from "styled-components";

export const flexRowCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const flexColumnCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const scrollBarStyle = css`
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 42%, 0.49);
    border-radius: 100px;
    width: 0rem;
  }
`;

export const boxBorderRadius = css`
  border-radius: 1rem;
`;

export const mobileButton = css`
  border-radius: 2rem;
  width: 10.4rem;
  height: 3.4rem;
`;


export const boxShadow = css`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

export const AptGridLayoutStyle = css`
  padding: 3rem 0;
  width: auto;
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  place-items: center;
  @media (min-width: 1180px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1630px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 1180px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 905px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
  transition: 0.2s filter;
`;

export const SkeletonListElementWrapperStyle  = css`
  padding: 1.8rem;
  
  flex-direction: column;
  max-width: 40rem;
  
  cursor: pointer;
  ${boxBorderRadius}

  @media (min-width: 1630px) {
    width: 18.5vw;
  }

  @media (max-width: 1629px) {
    width: 22vw;
  }

  @media (max-width: 1180px) {
    width: 30vw;
  }

  @media (max-width: 905px) {
    width: 45vw;
    max-width: 45rem;
  }

  @media (max-width: 550px) {
    width: 88vw;
    max-width: 500px;
  }

  img {
    object-fit: cover;
    object-position: center;
    ${boxBorderRadius}
    width: 100%;
    height: 70%;
  }
  background-color: white;
  transition: 0.3s filter;
  &:hover {
    filter: brightness(85%);
}`;

export const SkeletonNaverMapWrapperStyle = css`
  margin-left: 1.8rem;
  display: flex;
  max-width: 40rem;
  height: 50rem;
  cursor: pointer;
  

  @media (min-width: 1630px) {
    width: 18.5vw;
  }

  @media (max-width: 1629px) {
    width: 22vw;
  }

  @media (max-width: 1180px) {
    width: 30vw;
  }

  @media (max-width: 905px) {
    width: 45vw;
    max-width: 45rem;
  }

  @media (max-width: 550px) {
    width: 88vw;
    max-width: 500px;
  }

  img {
    object-fit: cover;
    object-position: center;
    ${boxBorderRadius}
    width: 100%;
    height: 70%;
  }
  background-color: white;
  transition: 0.3s filter;
  
`;
export const SkeletonElementWrapperStyle = css`
  padding: 0.3rem;
  cursor: pointer;
  ${boxBorderRadius}

  @media (min-width: 1630px) {
    width: 18.5vw;
  }

  @media (max-width: 1629px) {
    width: 22vw;
  }

  @media (max-width: 1180px) {
    width: 30vw;
  }

  @media (max-width: 905px) {
    width: 45vw;
    max-width: 45rem;
  }

  @media (max-width: 550px) {
    width: 88vw;
    max-width: 500px;
  }

  img {
    object-fit: cover;
    object-position: center;
    ${boxBorderRadius}
    width: 100%;
    height: 70%;
  }
  
  background-color: white;
  transition: 0.3s filter;
  &:hover {
    filter: brightness(85%);
    color: #566fee;
  }
`;

export const SkeletonElementTextWrapperStyle = css`
  
  padding: 1.2rem;
  padding-top: 1.6rem;
  
  .apartment-header {
    align-items: center;
    display: flex;
        
    h1 {
      margin-left: 0.6rem;
      font-size: 2rem;
      font-weight: 600;
      color: black;
    }


  }
  .apartment-body {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    margin-top: 1.3rem;
    p {
      font-size: 1.3rem;
      color: ${(props) => props.theme.selectColor1};
    }
  }
`;

export const HeaderFooterStyle = css`
  ${flexRowCenter};
  justify-content: space-between;
  width: 100%;
  height: 8rem;
  padding: 5rem 5rem;
  border-bottom: 1px solid #f1f3f5;
`;

export const SwipeHeaderFooterStyle = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 6rem;
  padding: 0px 1.6rem;
`;

export const PageMargin = css`
  margin-top: 10rem;
  padding-bottom: 8rem;
`;

export const UnderLine = styled.section`
  display: flex;
  margin-top: 0.8rem;
  width: 100%;
  height: 0.3rem;
  background-color: ${(props) => props.theme.unselectColor1};
`;



export const AdminPagePadding = css`
  padding: 1rem 2.8rem;
`;
import React, { useState, useEffect} from "react";
import { getCookie } from "utils/cookie/cookie";
import styled from "styled-components";
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomBtn from "../element/Button";
import { getFavPicList } from 'utils/api/api';
import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
// images 
import taste from '../assets/taste.png';
import { useNavigate } from "react-router-dom";
import { selectedCheckboxesState } from "store/atoms";

const Contents = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
`;

const TastSection = styled.section`
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    

    img {
        display: flex;
        align-items: center;
        width: 45rem;
        height: auto;
    }
`;

const TasteWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap : 1rem;
`;

const Tasteh1 = styled.h1`
    font-size: 4rem;
    font-weight: 700;
    color: #000000;
    padding: 1rem
`;

const Tastespan2 = styled.span`
    font-size: 1.8rem;
    font-weight: 500;
    color: #000000;
    padding: 1rem
`;
const Tastespan3 = styled.span`
    font-size: 1.8rem;
    font-weight: 500;
    color: #000000;
    padding: 1rem;
    
`;

const Fav = () => {
  const matches = useMediaQuery('(max-width: 1015px)');
  const { data: favimagelist, isError, error, refetch } = useQuery('favimagelist', () => getFavPicList(token), {
    retry: false,
  });
  const token = getCookie('jwt');
  const [pageNm, setPageNm] = useState(1);
  const navigate = useNavigate();
  const setCheckboxState = useSetRecoilState(selectedCheckboxesState);

  useEffect(() => {
    setCheckboxState([]); // 체크박스 초기화
    console.log("favimagelist", favimagelist);
    console.log("error", error);
    console.log("isError", isError);
    console.log("pageNM", pageNm);
    console.log("setPageNm", setPageNm);
    console.log("refetch", refetch);
  },[setCheckboxState, favimagelist, error, isError, pageNm, refetch]);
  return (
    <>
    <Contents>
      {/* 모바일 */}
      {matches ? (
        <TastSection>
          
        </TastSection>
      ) : (
        <TastSection>
            <img src={taste} alt="taste" />
            <TasteWrapper>
                <TasteWrapper>
                    <Tastespan2>인테리어 취향 테스트</Tastespan2>
                    <Tasteh1>내 취향에 딱 맞는 <br></br>인테리어?</Tasteh1>
                    <Tastespan3>내 스타일에 맞는 맞춤 제안</Tastespan3>
                    <CustomBtn onClick={() => navigate("/fav/pick/1")}>시작하기</CustomBtn>
                </TasteWrapper>
            </TasteWrapper>
        </TastSection>
        )
      }
    </Contents>
    </>
  );
};

export default Fav;

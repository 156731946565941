import React, { useRef, useState } from 'react';
import { useSetRecoilState,  useRecoilState, useRecoilValue } from 'recoil';
import { isLoginModalState, isSignUp, signupFormState } from 'store/atoms';
import styled from 'styled-components';
import { modalVariants } from 'utils/animation/variants';
import { boxBorderRadius, flexColumnCenter, flexRowCenter, scrollBarStyle } from 'utils/style/mixins';
import { IoMdClose } from 'react-icons/io';
import { useMediaQuery } from '@mui/material';

import LoginForm from './LoginForm';
import EmailSignup from './sociallogin/EmailSignup';
import SignupAgreementsForm from './sociallogin/SignupAgreementsForm';
import InputUserInfo from 'components/sociallogin/InputUserInfo';
import MobileLoginForm from './mobile/MobileLoginForm';

const ModalWrapper = styled.div`
    z-index: 999;
    position: fixed;
    ${flexRowCenter};
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    h1 {
        font-size: 1.6rem;
    }
`;

const ModalLayout = styled.div`
    position: relative;
    align-items: normal;
    ${boxBorderRadius}
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
    flex-direction: column;
    background-color: white;
    ${scrollBarStyle};

    @media ${props => props.theme.mediaQuery.desktop} {
        width: fit-content;
        // max-width: 75rem;
        max-height: 95vh;
    };

    @media ${props => props.theme.mediaQuery.tablet} {
        width: 100vw;
        height: 100vh;
    };

    @media ${props => props.theme.mediaQuery.phone} {
        width: 100vw;
        height: fit-content;
    };
`;

const CloseBox = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    padding: 1rem;
    cursor: pointer;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:black;
  display: ${props => props.show ? 'block' : 'none'};
`;

const HeaderWrapper = styled.div`
    width: 100%;
    height: fit-content;
    background-color: white;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 5rem;
`;

const HeaderText = styled.div`
    width: 100%;
    height: 100%;
    ${flexColumnCenter}
    color: black;

    @media ${props => props.theme.mediaQuery.desktop} {
        padding: 6rem 11rem;
    };

    @media ${props => props.theme.mediaQuery.tablet} {
        padding: 5rem 1rem;
    };

    @media ${props => props.theme.mediaQuery.phone} {
        padding: 3rem 1rem;
    };

`;

const HeadTitle = styled.span`
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 4rem;
    font-family: Spoqa Han Sans Neo;
`;

// const HeaderSpan = styled.span`
//     font-size: 2.8rem;
//     font-weight: 400;
//     font-family: Spoqa Han Sans Neo;
//     text-align: center;
// `;

const LoginModal = () => {
    console.log('LoginModal');
    const matches = useMediaQuery('(max-width: 1024px)');
    const isVisibleLoginModal = useSetRecoilState(isLoginModalState);
    const [Signup, setIsSignUp] = useRecoilState(isSignUp);
    const modalRef = useRef(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const isSignupForm = useRecoilValue(signupFormState);

    const handleSignupClick = () => {
        setIsSignUp(true);
    };

    const renderForm = () => {
        if (Signup) {
            switch (isSignupForm.lastsignupstage) {
                case 1:
                    return <SignupAgreementsForm />;
                case 2:
                    return <EmailSignup />
                case 3:
                    return <InputUserInfo />
                default:
                    return null;
            }
        } else {
            return (
                <>
                    <HeaderWrapper>
                        <CloseBox>
                            <IoMdClose size={32} onClick={toggleOverlay} />
                        </CloseBox>
                        <HeaderText className='header'>
                            <HeadTitle>META DESIGN HOUSE</HeadTitle>
                            {/* <HeaderSpan>로그인을 하시면<br/> 인테리어 디자인이<br/> 
                            더 가까워집니다!</HeaderSpan> */}
                        </HeaderText>
                    </HeaderWrapper>
                    <BodyWrapper>
                        <LoginForm
                            onSignup={handleSignupClick} 
                            toggleOverlay={toggleOverlay}
                        />
                    </BodyWrapper>
                </>
            );
        }
    };
    
    const toggleOverlay = () => {
        isVisibleLoginModal(false);
        setShowOverlay(false);
    };

    return (
        <>
        {matches ? ( // 모바일 화면 440px 이하
            <MobileLoginForm toggleOverlay={toggleOverlay} renderForm={renderForm} setIsSignUp={setIsSignUp} Signup={Signup} onClick={() => {
                isVisibleLoginModal(false);
            }}/>
        ) : (
            <>
            <Overlay className="overlay" show={showOverlay}  onClick={() => isVisibleLoginModal(false)}/>
            <ModalWrapper
                ref={modalRef}
                onClick={(e) => {
                    if (modalRef.current === e.target) {
                        isVisibleLoginModal(false);
                    }
                }}
            >
                <ModalLayout className="modallayout" variants={modalVariants} initial="start" animate="animate" >
                    {renderForm()}
                </ModalLayout>
            </ModalWrapper>
            </>
        )
        };
        </>
    );};

export default LoginModal;
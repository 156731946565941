
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Sidebar = () => {

    const menuItems = [
        {
            href: "/aticoadmin",
            title: "홈",

        },
        {
            href: "/aticoadmin/accounts",
            title: "회원",

        },
        {
            href: "/aticoadmin/apply",
            title: "상담신청건",

        },
    ];
    const servicePages = [
        {
            href: "/aticoadmin/home",
            title: "메인화면",
        },
        {
            href: "/aticoadmin/favtest",
            title: "취향테스트",
        },
        {
            href: "/aticoadmin/portfolio",
            title: "포트폴리오",
        },
    ];
    const CustomNavLink = ({ activeClassName, ...props }) => (
        <NavLink {...props} activeClassName={activeClassName} />
      );

    return (
            <BodyLeftTab>
                <Title><h2>관리페이지</h2></Title>
                {menuItems.map((item) => (
                    <BodyLeftTabItem key={item.title}>
                        <CustomNavLink to={item.href} activeClassName="active">
                            <Button>{item.title}</Button>
                        </CustomNavLink>
                    </BodyLeftTabItem>
                ))}
                <Title><h2>서비스 화면</h2></Title>
                {servicePages.map((item) => (
                    <BodyLeftTabItem key={item.title}>
                        <CustomNavLink to={item.href} activeClassName="active">
                            <Button>{item.title}</Button>
                        </CustomNavLink>
                    </BodyLeftTabItem>
                ))}
            </BodyLeftTab>
    );
}


export default Sidebar;


const Title = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    margin-left: 0.7rem;
    padding: 1rem 0;
    width: 9rem;
    padding-left: 1rem;

    h2 {
        color: #171B1A;
        font-size: 1rem;
        font-weight: 700;
        white-space: nowrap;
        font-family: '__Inter_332a6e', '__Inter_Fallback_332a6e'
    }
`;


const Button = styled.button`
    background: none;
    border: none;
    padding: 0;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    color: #343a40;
    cursor: pointer;
    &:hover {
        color: #343a40;
    }
`;


const BodyLeftTab = styled.section`
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e9ecef;
    margin-top: 1rem;
    width: 100%;
    * {
        font-size: 14px;
    }
`;
const BodyLeftTabItem = styled.ul`
    display: flex;
    justify-content: top-left;
    width: auto;
    height: auto;
    flex-direction: column;
    padding:0.5rem 1.6rem;
    margin:0;

    cursor: pointer;
    white-space: nowrap;

    &:hover {
        background-color: #e9ecef;
        border-radius: 0.5rem;
    }

`;

// const Item = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: top-left;
//     cursor: pointer;
//     white-space: nowrap;
//     width: 9rem;
//     height: auto;

//     padding-left: 1rem;
//     padding-top: 0.5rem;
//     padding-bottom: 0.5rem;

//     a {
//         text-decoration: none;
//         color: inherit;
//     }
//     span {
//         font-weight: 400;
//     }

//     &.active {
//         background-color: #e9ecef;

//         border-radius: 0.5rem;
//         font-weight: 600;
//     }

//     &:hover {
//         background-color: #e9ecef;

//         border-radius: 0.5rem;
//     }
// `;
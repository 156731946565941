import React, { useState } from 'react';
import styled from 'styled-components';
import { flexColumnCenter } from '../utils/style/mixins';
import { getInfiniteFilterSearch } from 'utils/api/api';
import { SkeletonMapElement } from '../element/SkeletonMapElement';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useInfiniteQuery } from "react-query";
import { useRecoilState } from "recoil";
import { isBottomState  } from "store/atoms";
import { useEffect } from 'react';
import OrderResultList from 'components/OrderResultList';
import { useNavigate } from 'react-router-dom';
import { getCookie } from 'utils/cookie/cookie';

const getInfinityOrders =  async ({ pageParam = 1, searchValue  }) => {
        const token = getCookie('jwt');
        const res = await getInfiniteFilterSearch('order', pageParam, searchValue, token);
        return res;
    };

function Orders() {
    const searchValue = useState('');
    const [orderList, setOrderList] = useState([]);
    const [isBottom, setIsBottom] = useRecoilState(isBottomState);
    const navigate = useNavigate();
    
    const { 
        data, 
        isLoading, 
        isError,
        error,
        fetchNextPage, 
        hasNextPage, 
        isFetchingNextPage 
      } = useInfiniteQuery(
        searchValue,
        getInfinityOrders, 
        {  
        getNextPageParam: (lastPage) => {
            if (lastPage.next == null) return undefined;
            const url = new URL(lastPage.next);
            return url.searchParams.get('page');
        }}
        );

    if(isError) {
      console.log("error :: ", error);
    }
    useEffect(() => {
        if (data && !isLoading) {
          let tempData = [];
          data.pages.forEach((page) => {
            const pageData = page.data;
            tempData.push(...pageData);
          });
          if (tempData.length > 0) {
            setOrderList(tempData);
          }
        }
        if (isBottom && !isFetchingNextPage && hasNextPage) {
          fetchNextPage();
          setIsBottom(false);
        }
        if (isFetchingNextPage || hasNextPage) {
          console.log("NEXT PAGE IS NONE OR FETCHING")
          return;
        }
    
      }, [data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isBottom, setIsBottom]);

    return (
        <MyPageContainer>
            <ListContainer>
                <TitleWrapper>
                    <Prev onClick={() => navigate(-1)}>
                        <ArrowBackIosNewIcon fontSize='large'/>
                    </Prev>
                    <h1> 예약 내역</h1>
                </TitleWrapper>
                {isLoading ? (
                    <ColumnContainer >
                        <SkeletonMapElement />
                        <SkeletonMapElement />
                        <SkeletonMapElement />
                        <SkeletonMapElement />
                        <SkeletonMapElement />
                    </ColumnContainer>
                ) :  !isLoading && orderList.length === 0 ? (
                    <MessageBox>
                      <Message>예약한 인테리어 없습니다.</Message>
                    </MessageBox>
                  ) : (
                    <ColumnContainer >
                      {orderList?.map(( list, id ) => {
                          return (
                          <OrderResultContainer key={id}>
                            <OrderResultList order={list}/>
                          </OrderResultContainer>
                          )
                      })}
                    </ColumnContainer>
                  )}
                </ListContainer>
              </MyPageContainer>
            );
          }
          

export default Orders;


const OrderResultContainer = styled.div`
    display: flex;
`;

const MyPageContainer = styled.section`

    display: flex;
    margin: 12rem auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 620px;
    height: auto;
`;
const ListContainer = styled.div`

`;

const TitleWrapper = styled.div`
    
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 2rem;
    gap: 1rem;

    .MuiSvgIcon-root {
        cursor: pointer;
        font-size: 2rem;
    }
    h1 {
        font-size: 3.2rem;
        font-weight: 600;
    }
`;
const Prev = styled.div`
    display: flex;
    margin-right: 1rem;
    cursor: pointer;
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    border-width: 1px;
    background-color: rgb(248, 249, 250);
    box-shadow: rgba(73, 80, 87, 0.06) 0px 2px 8px;
    border-radius: 16px;
    border-color: rgb(241, 243, 245);
    border-style: solid;
    gap: 16px;
`;

const MessageBox = styled.div`
    height: 100px;
    ${flexColumnCenter}
`;
const Message = styled.span`
    font-size: 1.5rem;
`;

import { postSignup, postSignin } from 'utils/api/api';
import { useMutation } from 'react-query';
import { removeCookie } from 'utils/cookie/cookie';
import { globalUserInfoState, 
  isUserState, 
  userNamePersistState,  
  isSignUp, 
  errorMessageState,
  isAdminLoginModalState,
  isMyPageModalState
 } from 'store/atoms';
 import { useContext } from 'react';
 import { AuthContext } from "utils/func/AuthContext";
import { useSetRecoilState,useResetRecoilState } from 'recoil';

export const useAuth = () => {
  const setIsSignUp = useSetRecoilState(isSignUp);
  const setIsUser = useSetRecoilState(isUserState);
  const setErrorMessage = useSetRecoilState(errorMessageState);
  const setVisibleLoginModal = useSetRecoilState(isAdminLoginModalState);
  const isVisibleMyPageModal = useSetRecoilState(isMyPageModalState);
  const { loginSave } = useContext(AuthContext);

  const resetUserName = useResetRecoilState(userNamePersistState);
  const reset = useResetRecoilState(globalUserInfoState);

  const signInMutate = useMutation(postSignin, {
    onSuccess: (response) => {
      if (response.code === "ERR_BAD_REQUEST") {
        // 서버문제인 경우
        setErrorMessage('로그인 정보가 올바르지 않습니다.');
        return;
      }
      if (!response.data?.email){
          setErrorMessage('로그인 정보가 올바르지 않습니다.');
          return;
      }
      loginSave(response.data);
    },
    onError: (error) => {
        if (error.response.data?.code === "token_not_valid") {
            setErrorMessage('쿠키가 만료되었습니다. 다시 로그인해주세요');
            localStorage.clear();
            removeCookie("token");
            setVisibleLoginModal(true);
            resetUserName();
            reset();
            setIsUser(false);
            isVisibleMyPageModal(false);
            window.location.href = "/";
        }
        setErrorMessage('로그인 정보가 올바르지 않습니다.');
    },
  }); //로그인 mutation

  const signUpMutate = useMutation((data) => postSignup(data), {
    onSuccess: (res) => {
      console.log("SIGN UP : ", res);
      setIsSignUp(false);
    },
  }); //회원가입 mutation

  const handleSignup = (data) => {
    const { password, passwordConfirm } = data;

    if (password !== passwordConfirm) {
      console.log("비밀번호가 같지 않습니다. 다시 작성해주세요.");
      setErrorMessage("비밀번호가 같지 않습니다. 다시 작성해주세요.");
      return;
    }

    signUpMutate.mutateAsync({ data })
    .then(() => {
      setErrorMessage("회원가입이 완료되었습니다.");
    })
    .catch((error) => {
      console.log("ERORR : ", error);
      setErrorMessage(error.response.data.message);
    });
  };
  return { handleSignup, signInMutate, signUpMutate };
};



import React from "react";
import styled from "styled-components";
import HorizontalLinearAlternativeLabelStepper from 'element/Stepper';

const OrderResultList = ({ order }) => {
    return (
        <OrderResultWrapper>
            <Stepper>
                <HorizontalLinearAlternativeLabelStepper status={ order.status }/>
            </Stepper>
            <Title>
                <a
                    href={`/${order.apartments_data.id}`}
                >{order.apartments_data.fullName}</a>
                <Description>
                    <span id="address">{order.apartments_data.address}</span>
                    <div className="border"></div>
                    <span id="year">{order.apartments_data.year}</span>
                </Description>
            </Title>
            
            <Content>
                <SectionWrapper>
                    <TextSection>
                        <span id="designer"> 담당 디자이너 : {order.designer ? order.designer : '미배정'} </span>
                        <span id="payments"> 결제 금액 : { order.payments[0]?.amount } </span>
                        <span id="payments_method"> { order.payments[0]?.payment_method } </span>
                    </TextSection>
                    <DetailButton
                    as="a"
                    href={`orders/${order.number}`}>
                        <span>자세히 보기</span>
                    </DetailButton>
                </SectionWrapper>

                <ImageSection>
                    { order.apartments_data.floorplan? <img src={ order.apartments_data.floorplan } alt="img" width="350rem" height="auto" /> : <> </>}
                </ImageSection>
                
            </Content>
        </OrderResultWrapper>
    );
  };
  
export default OrderResultList;

const Description = styled.div`
    padding: 0.5rem 0;
    display: flex;
    flex-direction: row;
    color: rgb(134, 142, 150);
    .border {
        height: 10px;
        margin: 0px 8px;
        align-self: center;
        border-left: 1px solid rgb(222, 226, 230);
    }
    span {
        font-size: 14px;
    }
`;

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding: 0 0 2rem 0;
    border-bottom: 1px solid rgb(233, 236, 239);
`;

const DetailButton = styled.div`
    display: flex;
    width: 120px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #1976d2;
    background-color: #1976d2;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    
    span {
        white-space: nowrap;
        font-size: 12px;
        font-weight: 400;
        color: white;
    }
`;

const ImageSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem 0;
    img {
        width: 100%;
        height: auto;
    }
`;

const TextSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    span {
        color: rgb(134, 142, 150);
    }
    #designer {
        color: rgb(66, 90, 213);
        font-size: 17px;
        font-weight: 700;
    }
    #payments {
        font-size: 1.2rem;
        font-weight: 600;
    }
    #payments_method {
        font-size: 1.2rem;
        font-weight: 600;
    }
`;

const Stepper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    padding: 2.3rem 0;
    font-size: 1.2rem;
    text {
        font-size: 1.5rem;
    }
    span {
        font-size: 1.2rem;
        font-weight: 600;
    }
`;
const Title = styled.div`
    display: inline-block;
    margin-top: 1rem;
    flex-direction: column;
    padding: 0 0 2rem 2rem;

    a {

        text-decoration: none;
        cursor: pointer;
        font-size: 20px;
        font-weight: 700;
        flex-wrap: wrap;
        color : rgb(42, 48, 55);
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding : 1rem 2rem;
    span {
        font-size: 14px;
    }
`;
const OrderResultWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 20px;
    background-color: white;
    
`;
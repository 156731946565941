import React from "react";
import styled from "styled-components";

const ProgressBarWrapper = styled.ul`
    list-style-type: none; 
    display: flex; 
    justify-content: space-between; 
    gap: 14rem;
    align-items: center;
    position: relative;
    top: 1;
    width: 100%;
    height: 5.6rem;
    @media (max-width: 1024px) {
        display: none;
    }
`;

const Point = styled.div`
    position: relative;
    z-index: 2;
    width: 32.595px;
    height: 32.595px;
    border-radius: 50%;
    background-color: ${props => props.active ? '#E0D7C0' : '#E5E5E5'};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${props => props.active ? 'white' : '#757E89'};
    font-family: Spoqa Han Sans Neo;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
`;

const PointText = styled.span`
    display: flex;
    align-items: center;
    position: absolute;
    top: 100%;
    white-space: nowrap;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    font-family: Spoqa Han Sans Neo;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.28px;
`;

const SvgLine = styled.svg`
    position: absolute;
    top: 26.5px;
    width: 90%;
    height: 2px;
    left: 5%;
    z-index: 1;
    
`;

const SignupProgress = ['약관동의','가입방법선택','회원정보','가입완료'];
const ProgressBar = ({ activePoint }) => (
    <ProgressBarWrapper>
        <SvgLine>
            <line x1="0" y1="1" x2="688" y2="1" stroke="#E5E5E5" strokeWidth="2"/>
        </SvgLine>
        {SignupProgress.map((item, i) => (
            <li key={i} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Point active={i < activePoint}>{i+1}</Point>
                <PointText active={i < activePoint}>{item}</PointText>
            </li>
        ))}
    </ProgressBarWrapper>
);

export default ProgressBar;
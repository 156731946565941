import { createContext } from 'react';
import { removeCookie } from "utils/cookie/cookie";
import { useSetRecoilState, useResetRecoilState } from 'recoil';

import { isAdminLoginModalState, 
    globalUserInfoState,
    isUserState,
    userNamePersistState,
    isMyPageModalState,
    isLoginModalState
 } from 'store/atoms';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const setVisibleAdminLoginModal = useSetRecoilState(isAdminLoginModalState);
    const setIsUser = useSetRecoilState(isUserState);
    const reset = useResetRecoilState(globalUserInfoState);
    const resetUserName = useResetRecoilState(userNamePersistState);
    const isVisibleMyPageModal = useSetRecoilState(isMyPageModalState);
    const setGlobalUserInfo = useSetRecoilState(globalUserInfoState);
    const setVisibleLoginModal = useSetRecoilState(isLoginModalState);
    
    const logoutClear = () => {
        try {
            // setVisibleAdminLoginModal(true);
            localStorage.clear();
            removeCookie("jwt");
            setIsUser(false);
            reset();
            setGlobalUserInfo(null);
            isVisibleMyPageModal(false);
            resetUserName();
            setVisibleLoginModal(true);
        } catch (error) {
            console.error('Error occurred during logout:', error);
        }
    };

    const loginSave = (data) => {
        setIsUser(true);
        setGlobalUserInfo({
            id: data.id,
            email: data.email,
            nickname: data.nickname ? data.nickname : "",
            role: data.role
        });
        localStorage.setItem("nickname", data.nickname);
        setVisibleAdminLoginModal(false);
        setVisibleLoginModal(false);
        isVisibleMyPageModal(true);
    }

    return (
        <AuthContext.Provider value={{ logoutClear, loginSave }}>
            {children}
        </AuthContext.Provider>
    );

}
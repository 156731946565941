import React, { useRef } from 'react';

function VideoComponent({ src, autoPlayOnLoad, loop }) { // 함수 인자를 수정했습니다.
  const videoRef = useRef();

  const handleMouseEnter = () => {
    if (videoRef.current) {
        videoRef.current.play().catch(error => console.error("IGNORE THIS : play() failed : ", error));
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
    }
  };


  return (
    <video 
      ref={videoRef} 
      loop={loop}
      muted 
      autoPlay={autoPlayOnLoad}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
}

export default VideoComponent;

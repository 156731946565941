import Axios from './axios';
import { getCookie } from "utils/cookie/cookie";

const fetcher = async (url, method, data = null, token = null, options={}) => {
    const csrftoken = await getCookie('csrftoken');
    const option = {
        method,
        url,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': csrftoken,
            'Authorization': token ? `Bearer ${token}` : ''
        },
        timeout: 10000,
        ...options,
    };

    if (data) {
        option.data = data;
    }
    option.baseURL = process.env.REACT_APP_API_URL;

    const axiosInstance = new Axios(url);

    try {
        const res = await axiosInstance.instance(option);
        if (res.statusText === 'OK' 
        || res.statusText === 'Created' 
        || res.statusText === 'Accepted'
        || res.statusText === '') 
        {
            return res;
        } else {
            const error = new Error(res.statusText);
            error.response = res;
            return error;
        }
    } catch (err) {
        return err;
    }
};

export default fetcher;
import React from 'react';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';

const HeaderWrapper = styled.div`
    
    gap: 1.6rem;
    background-color: white;
    border-radius: 8px;
    border: 0.5px solid #C1C1C1;
    overflow: auto;

    @media ${props => props.theme.mediaQuery.desktop} {
        width: 50vw;
        height: 90vh;
        padding: 2.4rem 3.2rem;
    };

    @media ${props => props.theme.mediaQuery.tablet} {
        width: 100vw;
        height: 100vh;
        padding: 2.4rem 3.2rem;
    };

    @media ${props => props.theme.mediaQuery.phone} {
        width: 100vw;
        height: 100vh;
        padding: 2.4rem 3.2rem;
    };


`;
const HeaderContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 16px 0px 8px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
`;

const HeaderText = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: black;
`;

const HeadTitle = styled.span`
    display: flex;
    flex-direction: row;
    font-size: 2.8rem;
    font-weight: 700;
    font-family: Spoqa Han Sans Neo;
`;

const CloseBox = styled.div`
    display: flex;
    justify-content: flex-end;
    color: black;
    padding: 1rem;
    cursor: pointer;
`;

const SubTitle = styled.span`
    font-size: 1.6rem;
    font-weight: 400;
    font-family: Spoqa Han Sans Neo;
    margin-bottom: 2rem;
    color: #757E89;
    letter-spacing: -1.28px;
`;

const BodyText = styled.div`
    
    color: black;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    overflow-y: scroll;
    overflow-x: hidden;
    background: var(--sub_color_5, #F5F8FA);
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    @media ${props => props.theme.mediaQuery.desktop} {
        width: 100%;
        height: 100%;
    };

    @media ${props => props.theme.mediaQuery.tablet} {
        width: 100%;
        height: 60vh;
    };

    @media ${props => props.theme.mediaQuery.phone} {
        width: 100%;
        height: 60vh;
    };

`;

const TextWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 8px;
    color: black;
    flex: 1 0 0;
    align-self: stretch;
    white-space: pre-wrap;
    font-size: 1.6rem;
    font-weight: 400;
    font-family: Noto Sans SC;
    
`;
const ButtonWrapper = styled.div`
    display: flex;
    padding: 8px 0px 16px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    margin-top: 4.8rem;
`;

const Button = styled.button`
    display: flex;
    width: 320px;
    padding: 16px 76px;
    justify-content: center;
    align-items: center;

    border-radius: 500px;
    background: #CDB36E;
    color: #FFF;
    cursor: pointer;

    span {
        font-family: Spoqa Han Sans Neo;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    &:active {
        background-color: #5433D4;
        color: white;
    }
    &:focus {
        background-color: #5433D4;
        color: white;
    }
`;

const AgreementComp = ({  title, body, onHide }) => {
    return (
        <>
        <HeaderWrapper>
            <HeaderContainer>
                <HeaderText className='header'>
                    <HeadTitle>{title}</HeadTitle>
                    <CloseBox>
                        <IoMdClose size={32} onClick={onHide}/>
                    </CloseBox>
                </HeaderText>
                <SubTitle>메타디자인하우스 회원가입을 위해 아래 약관에 동의해주세요.</SubTitle>
            </HeaderContainer>

            <BodyText>
                <TextWrapper>
                {body}
                </TextWrapper>
            </BodyText>

            <ButtonWrapper>
                <Button onClick={onHide}><span>확인</span></Button>
            </ButtonWrapper>
        </HeaderWrapper>
        
        </>
    );
}
export default AgreementComp;

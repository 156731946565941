import styled from "styled-components";
import { getMemberList } from 'utils/api/api';
import { useQuery } from 'react-query';
import {  useState } from 'react';
import Spinner from 'components/Spinner';
import { getCookie } from 'utils/cookie/cookie';
import Pagination from '@mui/material/Pagination';

const MainContentWrapper = styled.section`
    display: flex;

    flex-direction: column;
    padding: 20px;
`;

const Card = styled.div`
  overflow: hidden;

`;

const CardHeader = styled.div`
  padding: 16px;
`;

const CardTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #343a40;
`;

const CardContent = styled.div`
  padding: 16px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  background-color: #f8f9fa;
`;

const TableHeaderCell = styled.th`
  padding: 12px;
  text-align: left;
  font-weight: 600;
  border-bottom: 1px solid #e9ecef;
`;

const TableBody = styled.tbody`
  tr:hover {
    background-color: #f8f9fa;
  }
`;

const TableRow = styled.tr`
  
  
`;
const TableCell = styled.td`
  padding: 12px;
  border-bottom: 1px solid #e9ecef;
`;

const PaginationSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const Accounts = () => {
    const [pageNm, setPageNm] = useState(1);
    const token = getCookie('jwt');
    const { data: memblist, isError, error, refetch } = useQuery('memberList', () => getMemberList(pageNm, token), {
      retry: false, // Disable automatic retry
    });

    if (isError) {
      return (
        <MainContentWrapper>
        <div>
          <p>Error: {error.message}</p>
        </div>
        </MainContentWrapper>
      );
    }
    // Access memblist.data
    const memberData = memblist?.data?.results;
    const numPages = memblist?.num_pages;


    const handlePageChange = (e,value) => {
        console.log("PAGE", value, "e", e);
        setPageNm(value);
        refetch();
    }

    return (
        <MainContentWrapper>
        { memberData && memberData.length === 0 ? (
        <Spinner/>
        ) : (
        <Card>
            <CardHeader>
            <CardTitle>계정</CardTitle>
            </CardHeader>
            <CardContent>
            <Table>
                <TableHeader>
                <TableRow key="HEAD">
                    <TableHeaderCell>이메일</TableHeaderCell>
                    <TableHeaderCell>성함</TableHeaderCell>
                    <TableHeaderCell>핸드폰번호</TableHeaderCell>
                    <TableHeaderCell>가입채널</TableHeaderCell>
                    <TableHeaderCell>가입날짜</TableHeaderCell>
                    {/* Add more header cells as needed */}
                </TableRow>
                </TableHeader>
                <TableBody>
                {memberData?.map((account) => (
                    <TableRow key={account.email}>
                    <TableCell>{account.email}</TableCell>
                    <TableCell>{account.fullname}</TableCell>
                    <TableCell>{account.phone}</TableCell>
                    <TableCell>{account.provider}</TableCell>
                    <TableCell>{account.date_joined}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            <PaginationSection>
            <Pagination
                count={numPages}
                onClick={handlePageChange}
            />
            </PaginationSection>
            </CardContent>
        </Card>
        )}
    </MainContentWrapper>
    );
}

export default Accounts;
import React from 'react';
import styled from 'styled-components';
import StrokeLine from 'element/StrokeLine';
import AppDownButton from 'element/Mobile/AppDownButton';


const AppDownSection = styled.section`
    display: flex;
    height: 25.2rem;
    width: 100%;
    flex-direction: column;
    padding: 5.5rem 3.2rem;
    background-color: ${(props) => props.backgroundColor ? props.backgroundColor : '#BBA38B'};
`;

const TextFrame = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: ${(props) => props.gap ? props.gap : '6rem'};
    align-items: top-left;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: flex-start;
    align-items: top-left;

    .title {
        color: #FFF;
        font-family: "Noto Sans KR";
        font-size: 16px;
        font-weight: 400;
        text-align: left;
    }
    .content {
        color: #FFF;
        font-family: "Noto Sans KR";
        font-size: 24px;
        font-weight: 900;
        letter-spacing: 2.4px
        text-align: left;
    }
`;

const ButtonFrame = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: flex-start;
    align-items: top-left;
    margin-top: 2rem;
`;

const AppDown = ({gap, title, content, button, backgroundColor}) => {
    return (
        <AppDownSection className='appdownsection' backgroundColor={backgroundColor}>
            <TextFrame className='frame' gap={gap}>
                <TextContainer className='con' >
                    <span className="title">{title}</span>
                    <span className="content">{content}</span>
                </TextContainer>
                <StrokeLine Linecolor={'white'} width={'100%'} position={'center'} marginTop={'0'} height={'1px'}></StrokeLine>
            </TextFrame>
            {button && 
            <ButtonFrame>
                <AppDownButton>{button}</AppDownButton>
            </ButtonFrame>
            }
        </AppDownSection>
    );
}

export default AppDown;
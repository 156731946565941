import React, { useContext } from 'react';
import styled from 'styled-components';

import { isAdminLoginModalState, 
    isAdminMyPageModalState,
    globalUserInfoState } 
    from 'store/atoms';

import { AdminPagePadding } from 'utils/style/mixins';
import { AuthContext } from 'utils/func/AuthContext';

import { useSetRecoilState,
    useRecoilState } 
    from 'recoil';

const Headers = () => {
    const { logoutClear } = useContext(AuthContext);
    const [userinfo, setUserinfo] = useRecoilState(globalUserInfoState);
    const setVisibleLoginModal = useSetRecoilState(isAdminLoginModalState);
    const setVisibleMyPageModal = useSetRecoilState(isAdminMyPageModalState);

    const handleLogout = () => {
        logoutClear();
        setUserinfo(null);
    };
    const handleLogin = () => {
        setVisibleLoginModal(true);
        setVisibleMyPageModal(false);
    };

    return (
        <>
            <Header>
                <HeaderTitle>ATICO 관리자 페이지</HeaderTitle>
                <HeaderUser>

                    {userinfo?.email ? (
                        <>
                            <HeaderUserText>{userinfo.email}</HeaderUserText>
                            <HeaderUserLogout onClick={handleLogout}>Logout</HeaderUserLogout>
                        </>
                    ) : (
                        <HeaderUserText onClick={handleLogin}>로그인</HeaderUserText>
                    )}
                </HeaderUser>
            </Header>
        </>
    );
}

export default Headers;

const Header = styled.header`
    ${AdminPagePadding};
    display: flex;
    justify-content: space-between;
    align-items: center; 
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecef;

    * {
        font-size: 14px;
    }
`;

const HeaderTitle = styled.h1`
    display: flex;
    justify-content: center;
    font-weight: 600;
`;

const HeaderUser = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const HeaderUserText = styled.span`
    font-weight: 600;
    cursor: pointer;
`;

const HeaderUserLogout = styled.button`
    background: none;
    border: none;
    padding: 0;
    outline: none;
    
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    color: rgb(63, 68, 66);
    cursor: pointer;
`;
import React from 'react';
import styled from 'styled-components';
import PrivacyText from 'assets/PrivacyandTerms/PrivacyText';


const Layout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
`;

const ContentDiv = styled.div`
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
    width: 80%;
    height: 100%;

    h1 {
        font-size: 2rem;
        font-weight: 600;
        border-bottom: 3px solid black;
        text-align: left;
        width: 100%;
        padding: 1.6rem 0;
    }
    .text {

        padding: 1.6rem 0 15rem 0;
    }

    * {
        white-space: pre-wrap;
        font-size: 1.4rem;
    }
`;
// ready txt file


const Privacy = () => {
    return (
        <>
        <Layout >
            <ContentDiv>
                <h1>개인정보처리방침</h1>
                <div className="text">
                    {PrivacyText}
                </div>
            </ContentDiv>
        </Layout>
        </>
    );
};
export default Privacy;

import React from 'react';
import styled from 'styled-components';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
// elems
import BeigeButton from "element/BeigeButton";

// comps
import Bigwall from '../components/Bigwall';
import AppDown from "components/mobile/AppDown";
import MobileCreatemyhome from "components/mobile/MobileCreatemyhome";

//images
import lamp from 'assets/lamp.svg';
import house from 'assets/house.svg';
import chat from 'assets/chat.svg';


const HeadSectionContainer  = styled.div`
    height: 100%;
`;

const TextContainer = styled.div`
    display: flex;
    position: relative;
    height: fit-content;
    align-items: center-left;
    flex-direction: column;
    color : black;

    .titlespan {
        padding-top: 1rem;
        height: fit-content;
        display: flex;
        flex-direction: column;
    }

    .tt {
        color: #FFFFFF;
        font-family: Pretendard;
        font-size: 24px;
        font-style: normal;
        font-weight: 200;
        line-height: 150%; /* 30px */
        letter-spacing: 1px;
    }

    .span {
        color: #8B8B8B;
        font-family: "Noto Sans KR";
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 131%; /* 20.96px */
    }


`;

const TopTitle = styled.h1`
    display: flex;
    align-items: center;
    color: white;
    font-family: Pretendard;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 6.4px;
    width: 100%;
    justify-content: flex-start;
`;


const BodySectionContainer = styled.div`
    padding-top: 15rem;
    height: auto;
`;


const MainBodyTitle = styled.div`
    display: flex;
    justify-content: center;
    font-size: 4rem;
    font-weight: 900;
    font-family: Notosans KR;
`;


const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: center;
    gap: ${props => props.gap ? props.gap : '0'};
    justify-content: center;
`;


const SmallTitle = styled.span`
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #999999;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 10%;
    height: fit-content;
`;
const H2Title = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-family: "Noto Sans KR";
    font-size: 48px;
    font-style: normal;
    font-weight: 900;

    strong {
        font-family: "Noto Sans KR";
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
    }
`;

const GridWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-top: 15rem;
`;

const Boxes = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    gap: 5.6rem;
    justify-content: center;
    align-items: center;
    padding: 1rem;
`;

const BoxTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
    width: 100%;
    color: #000;
    text-align: left;
    font-family: "Noto Sans KR";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;

`;

const Box = styled.div`
    display: flex;
    width: 36rem;
    height: 36rem;
    background-color: ${(props) => props.color};
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    cursor: pointer;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.25);

`;

const BoxTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 5rem 6rem;
    width: 100%;
    gap: 2rem;

    .img {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }
`;

const BoxTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const AppDownFrame = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;
const Thumbnail = styled.img`
    display: flex;
    height: 50rem;
    width: auto;
    margin-top: 6rem;
`;

const Blank = styled.div`
    display: flex;
    width: 100%;
    height: ${props => props.height ? props.height : '20rem'};
`;

const ColContainer1 = styled.div`
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    gap: ${props => props.gap ? props.gap : '0'};
    width: 100%;
`;

const PhoneImage = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`;

const GridImages = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 3rem;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    img {
        width: 130%;
        height: auto;
    }
`;


const Createmyhome = () => {
    const matches = useMediaQuery('(max-width: 1015px)');
    const navigate = useNavigate();

    const redirecting = (url) => {
        window.location.href = url;
    };

    return (
    <>
    { matches ? (
        <>
        <AppDownFrame>
        <Thumbnail src="https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/mthumbnail.png" alt="mthumbnail">
        </Thumbnail>
        <AppDown title="내 취향에 딱 맞는 인테리어?" content="우리집 만들기" backgroundColor={'#668C8F'}/>
        <Blank height={'15rem'}/>
        <MobileCreatemyhome />
        </AppDownFrame>
        </>
    ) : (
        <>
        <HeadSectionContainer>
            <Bigwall loop={true} autoPlayOnLoad={true} video={'https://aticos3.s3.amazonaws.com/dev/media/pcport6.mp4'} >
            <TextContainer>
                <TopTitle >우리집 만들기</TopTitle>
                <div className="titlespan">
                    <p className='tt'>
                        내 취향에 딱 맞는 인테리어?
                    </p>
                </div>
            </TextContainer>
            </Bigwall>
        </HeadSectionContainer>
        <BodySectionContainer>
            <BodyWrapper className="wrapper">
                <H2Title>추천 콘텐츠</H2Title>
                <SmallTitle>나를 위한 디자인, 고민하지말고 지금 바로 만나보세요!</SmallTitle>
            </BodyWrapper>

            <MainBodyTitle>
                <GridWrapper>
                    <Boxes>
                        <Box color={'#E3DAC5'} onClick={() => navigate('/fav/pick/1')}>
                            <BoxTextContainer>
                                <div className='img'>
                                    <img src={lamp} alt='lamp'></img>
                                </div>
                                <BoxTextWrapper>
                                    <BoxTitle className='titlecontent'>
                                        나의<br /> 라이프스타일이<br /> 궁금해!
                                    </BoxTitle>
                                </BoxTextWrapper>
                                <BeigeButton position={'center'} fontColor={'black'} fontsize={'14px'} bcolor={'white'} width={'100%'} height={'3.9rem'} margintop={'3rem'} onClick={() => navigate('/fav/pick/1')}>인테리어 취향테스트</BeigeButton>
                            </BoxTextContainer>
                        </Box>
                        <Box color={'rgba(241, 233, 215, 0.78)'} onClick={() => redirecting('https://play.google.com/store/apps/details?id=com.aticodesign.mdh')}>
                            <BoxTextContainer>
                                <div className='img'>
                                    <img src={house} alt='house'></img>
                                </div>
                                <BoxTextWrapper>
                                    <BoxTitle className='titlecontent'>
                                        나의 로망이<br /> 담겨진 꿈의 집을<br /> 만나고싶어!
                                    </BoxTitle>
                                </BoxTextWrapper>
                                <BeigeButton position={'center'} fontColor={'black'} fontsize={'14px'} bcolor={'white'} width={'100%'} height={'3.9rem'} margintop={'3rem'} onClick={() => navigate('/fav/pick/1')}>인테리어 취향테스트</BeigeButton>
                            </BoxTextContainer>
                        </Box>
                        <Box color={'#E3DAC5'} onClick={() => navigate('/interiorcall')}>
                            <BoxTextContainer>
                                <div className='img'>
                                    <img src={chat} alt='chat'></img>
                                </div>
                                <BoxTextWrapper>
                                    <BoxTitle className='titlecontent'>
                                        지금 바로 집을<br />더 멋있게<br /> 꾸미고싶어!
                                    </BoxTitle>
                                </BoxTextWrapper>
                                <BeigeButton position={'center'} fontColor={'black'} fontsize={'14px'} bcolor={'white'} width={'100%'} height={'3.9rem'} margintop={'3rem'} onClick={() => navigate('/fav/pick/1')}>인테리어 취향테스트</BeigeButton>
                            </BoxTextContainer>
                        </Box>
                    </Boxes>
                </GridWrapper>
            </MainBodyTitle>
            <Blank height={'20rem'}/>
            <ColContainer1 gap={'6rem'}>
                <BodyWrapper className="wrapper">
                    <H2Title>지금, 바로 경험해보세요!</H2Title>
                    <SmallTitle>당신만의 독특하고 매력적인 공간을 쉽고 빠르게</SmallTitle>
                </BodyWrapper>
                <PhoneImage>
                    <img alt="img" src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/phone_035.png'} loading="lazy"></img>
                </PhoneImage>
                <GridImages>
                    <img alt="img" src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/gridimages1.png'} loading="lazy"></img>
                    <img alt="img" src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/gridimages2.png'} loading="lazy"></img>
                </GridImages>
                
                <BeigeButton radius={'8px'} width={'30rem'} height={'7rem'} position={'center'} onClick={() => redirecting('https://play.google.com/store/apps/details?id=com.aticodesign.mdh')}>무료 체험하기</BeigeButton>
            </ColContainer1>
            <Blank height={'20rem'}/>
        </BodySectionContainer>
        </>
    )}
    </>
    );
}
export default Createmyhome;
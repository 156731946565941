import styled from "styled-components";
import {useState} from "react";
import axios from "axios";
import Alert from '@mui/material/Alert';
import BeigeButton from "element/BeigeButton";

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100%-8rem);
  overflow-y: hidden;
`;
const LeftSection= styled.div`
    flex: 1;
    height: 100&;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F8F9FA;
`;

const InputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
const LeftWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
const RightWrapper = styled.div` 
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const RightSection = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const FormCard = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    height: 100%;
    padding: 10rem 0 10rem 0;
    background-color: transparent;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  border-color: ${(props) => (props.isValid ? "#ccc" : "#ff0000")};
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap : 1rem;
    padding: 1rem;

    h1 {
        font-size: 4rem;
        font-weight: 500;
        color: #000000;
    }
    p {
        font-size: 3rem;
        font-weight: 500;
        color: #747C87;
        white-space: nowrap;
    }
`;
const TextWrapper2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15vh;
    padding: 2rem;
    gap: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    h2 {
        font-size: 2rem;
        font-weight: 500;
        font-family: Pretendard;
        color: #000000;
    }
    p {
        font-family: Pretendard;
        font-size: 1.5rem;
        font-weight: 700;
    }

`; 

const InputField = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 2rem;
    label {
        font-size: 1.6rem;
        font-weight: 500;
    }
    input {
        padding: 1.2rem 1.6rem;
        height: 4.4rem;
        width: 27rem;
        border-radius: 1rem;
        font-size: 1.6rem;
        font-weight: 300;
        color: #28282e;
    }
`;


const StyledAlert = styled(Alert)`
    width: 100%;
    border-radius: 1rem;
    box-shadow: 0 0 0 1px #566fee;
    transition: all 0.2s ease-in-out;
    * {
        font-size: 1.5rem;
        font-weight: 400;
        color: black;
    }
    &:hover {
        cursor: pointer;
    }
`;


const InteriorCall = () => {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        title: "",
        apartment: "",
    });

    const [isValid, setIsValid] = useState({
        name: true,
        phone: true,
        apartment: true,
    }); // 유효성 검사

    const [showAlert, setShowAlert] = useState(false);



    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
        setIsValid({ ...isValid, [e.target.id]: !!e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newIsValid = {
            name: !!formData.name,
            phone: !!formData.phone,
            apartment: !!formData.apartment,
        };

        setIsValid(newIsValid);
        
        if (Object.values(newIsValid).every(Boolean)) {
            axios.post('http://localhost:8000/api/cs/', formData)
            .then(response => {
                console.log(response);
                setShowAlert(true);
                setFormData({}); // form 초기화
            })
            .catch(error => {
                console.error(error);
            });
        } 
    };

    return (
        <FormContainer className="formcon">
            <LeftSection className="leftcon">
                <TextWrapper>
                    <h1 className="text-3xl font-semibold">인테리어 상담</h1>
                    <p className="text-zinc-500 dark:text-zinc-400"> 시작부터 끝까지 함께하는 인테리어</p>
                </TextWrapper>
            </LeftSection>
            <RightSection className="rightcon">
                <TextWrapper2>
                    <h2>상담 신청</h2>
                    <p>
                        전문 디자이너에게 인테리어 견적을 받아보세요.
                    </p>
                </TextWrapper2>
                <FormCard onSubmit={handleSubmit}>
                    <InputWrapper>
                        <LeftWrapper>
                            <InputField>
                                <div style={{display:"flex"}}>
                                    <Label htmlFor="name">이름</Label>
                                    <span style={{color:"red"}} data-v-37ac7e79="" className="icn_required">*</span>
                                </div>
                                <Input id="name" placeholder="성함을 입력하세요" onChange={handleChange} isValid={isValid.name}/>
                            </InputField>
                            <InputField>
                                <div style={{display:"flex"}}>
                                    <Label htmlFor="phone">연락처</Label>
                                    <span style={{color:"red"}} data-v-37ac7e79="" className="icn_required">*</span>
                                </div>
                                <Input id="phone" placeholder="연락처를 입력하세요" onChange={handleChange} isValid={isValid.phone}/>
                            </InputField>
                            <InputField>
                                <Label htmlFor="title">담당자명/직책</Label>
                                <Input id="title" placeholder="김메타/팀장" type="text" onChange={handleChange} isValid={true} />
                            </InputField>
                        </LeftWrapper>
                        <RightWrapper>
                            <InputField>
                                <div style={{display:"flex"}}>
                                    <Label htmlFor="apartment">아파트명</Label>
                                    <span style={{color:"red"}} data-v-37ac7e79="" className="icn_required">*</span>
                                </div>
                                <Input id="apartment" placeholder="시공하려는 아파트명을 입력해주세요" type="text" onChange={handleChange} isValid={isValid.apartment}/>
                            </InputField>
                        </RightWrapper>
                    </InputWrapper>
                    {showAlert && 
                    <StyledAlert onClick={() =>{setShowAlert(false); window.location.reload();}} id="success" severity="success">상담신청이 완료되었습니다</StyledAlert>
                    }
                    <BeigeButton position="center" margintop={'2rem'} >문의하기</BeigeButton>
                </FormCard>
            
            </RightSection>
        </FormContainer>
  );
};

export default InteriorCall;


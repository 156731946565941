import React, { useRef } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { isLandingDetailModal } from 'store/atoms';
import styled from 'styled-components';
import { modalVariants } from 'utils/animation/variants';
import { boxBorderRadius, flexRowCenter, scrollBarStyle } from 'utils/style/mixins';
import { IoMdClose } from 'react-icons/io';
import career from 'assets/career.png';


const ModalWrapper = styled.div`
    z-index: 999;
    position: fixed;
    ${flexRowCenter};
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(10, 10, 10, 0.86);
    h1 {
        font-size: 1.6rem;
    }
`;

const ModalLayout = styled.div`
    position: relative;
    width: fit-content;
    max-height: 95vh;
    align-items: normal;
    ${boxBorderRadius}
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
    flex-direction: column;
    background-color: white;
    ${scrollBarStyle};
`;

const CloseBox = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    color: black;
    padding: 1rem;
    cursor: pointer;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: ${props => props.show ? 'block' : 'none'};
  
`;

const HeaderWrapper = styled.div`
    width: 70vw;
    height: 100%;
    flex-direction: column;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: white;

    img { 
        width: 100%;
        height: 100%;
        padding: 0 2rem 0 0;
        object-fit: cover;
    
    }
`;

const HeaderText = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 6rem 20rem;
    color: #576F95;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: calc(50% - 20rem); 
        height: 1px;
        background-color: #576F95;
        transform: translate(-50%);
    }
    &::after {
        content: '';
        position: absolute;
        right: 10rem;
        top: 50%;
        width: calc(50% + 30rem); 
        height: 1px;
        background-color: #576F95;
        transform: translate(30%);
    }
`;

const HeadTitle = styled.span`
    align-items: top flex-start;
    justify-content: top flex-start;
    color: #576F95;
    font-family: "Playfair Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
`;
  
const FooterWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 6rem 20rem;
    color: #000;
    gap: 0.5rem;
    font-family: "Noto Sans KR";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;

    strong {
        color: #576F95;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: 150%; /* 30px */
    }
`;

const HeadTitle2 = styled.span`
    align-items: center;
    justify-content: center;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
`;

const BodyWrapper2 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 6rem 20rem;
    color: #576F95;
    gap: 0.5rem;
    font-family: "Noto Sans KR";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
`;

const LeftTextContainer = styled.div`
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 6rem 20rem;
    color: #576F95;
    gap: 0.5rem;
    font-family: "Noto Sans KR";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
`;

const RightTextContainer = styled.div`
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 6rem 20rem;
    color: #576F95;
    gap: 0.5rem;
`;

const ColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 6rem 20rem;
    color: #576F95;
    gap: 0.5rem;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 6rem 20rem;
    color: #576F95;
    gap: 0.5rem;
`;

const TItletext = styled.span`
    align-items: center;
    justify-content: center;
    color: #000;
    font-family: "Noto Sans KR";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
`;

const ContentSpan = styled.span`
    align-items: center;
    justify-content: center;
    color: #666;
    font-family: "Noto Sans KR";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
`;


const Competence = (toggleOverlay) => {
    return (
        <>
            <HeaderWrapper>
                <CloseBox>
                    <IoMdClose size={23}  onClick={toggleOverlay} />
                </CloseBox>
                <HeaderText>
                    <HeadTitle2>[ 경쟁력 ]</HeadTitle2>
                </HeaderText>
            </HeaderWrapper>
            <BodyWrapper2>
                <LeftTextContainer>
                    <ColWrapper>
                        <Content>
                            <TItletext>
                            시장 선도 기술
                            </TItletext>
                            <ContentSpan>
                            "메타디자인하우스"는 기존 인테리어 디자인 플랫폼의 한계를 뛰어넘는 독보적인 AI 기술과 3D 모델링 기능을 통해 사용자에게 차별화된 경험을 제공합니다.
                            </ContentSpan>
                        </Content>
                        <Content>
                            <TItletext>
                            실시간 3D 인테리어 시뮬레이션
                            </TItletext>
                            <ContentSpan>
                            선택된 디자인은 고해상도의 3D 모델로 변환되어 사용자가 실시간으로 자신의 공간을 시각화할 수 있습니다.
                            </ContentSpan>
                        </Content>
                    </ColWrapper>
                </LeftTextContainer>
                <RightTextContainer>
                <ColWrapper>
                        <Content>
                            <TItletext>
                            다기능적 접근
                            </TItletext>
                            <ContentSpan>
                            B2C 및 B2B를 아우르는 서비스로, 개인 주거 공간뿐만 아니라 비즈니스 공간에도 적용할 수 있는 유연성을 갖추고 있습니다.
                            </ContentSpan>
                        </Content>
                        <Content>
                            <TItletext>
                            AI 기반 개인화 디자인
                            </TItletext>
                            <ContentSpan>
                            사용자는 아티코디자인이 제공하는 광범위한 이미지 라이브러리에서 선호하는 디자인을 선택할 수 있습니다. AI는 이러한 선택을 기반으로 개인에게 최적화된 인테리어 디자인을 생성합니다.
                            </ContentSpan>
                        </Content>
                    </ColWrapper>
                </RightTextContainer>
            </BodyWrapper2>
        </>
    )
}

const Career = (toggleOverlay) => {
    return (
        <>
            <HeaderWrapper>
                <CloseBox>
                    <IoMdClose size={23}  onClick={toggleOverlay} />
                </CloseBox>
                <HeaderText>
                    <HeadTitle>Career</HeadTitle>
                </HeaderText>
            </HeaderWrapper>
            <BodyWrapper>
            <img alt="career1" src={career}></img>
            </BodyWrapper>  
            <FooterWrapper>
                <strong>22년</strong>인테리어 & 가구디자인 피드백 기반 <strong>AI 생산형 3D 디자인 연구 개발</strong>
            </FooterWrapper>
        </>
    )
};

const LandingDetailModal = () => {
    const modalName = useRecoilValue(isLandingDetailModal);
    const setModalvisibility = useSetRecoilState(isLandingDetailModal);
    const modalRef = useRef(null);

    const toggleOverlay = () => {
        setModalvisibility(false);
    };

    const renderForm = (modalname) => {
        if (modalname === 'competence'){
            return (
               <Competence toggleOverlay={toggleOverlay}/>
            )
        };
        if (modalname === 'career') {
            return (
                <Career toggleOverlay={toggleOverlay}/>
            );
        };
        // if (modalname === 'career') {
        //     return (
        //         <Career toggleOverlay={toggleOverlay}/>
        //     );
        // }
    }

    return (
        <>
        <Overlay show={!!modalName}  onClick={() => setModalvisibility(false)}/>
        <ModalWrapper
            ref={modalRef}
            onClick={(e) => {
                if (modalRef.current === e.target) {
                    setModalvisibility(false);
                }
            }}
        >
        <ModalLayout variants={modalVariants} initial="start" animate="animate" >
            {renderForm()}
        </ModalLayout>
        </ModalWrapper>
        </>
    );
};
export default LandingDetailModal;
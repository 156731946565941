import React from 'react';
import styled from 'styled-components';


const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 1.3rem;

    @media ${props => props.theme.mediaQuery.desktop} {
        height: 100%;
    };

    @media ${props => props.theme.mediaQuery.tablet} {
        height: fit-content;
    };

    @media ${props => props.theme.mediaQuery.phone} {
        height: fit-content;
    };
`;

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
    appearance: none;
    width: 20px;
    height: 20px;
    background: #D6D6D6;
    border-radius: 50%;
    cursor: pointer;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 17%;
        left: 35%;
        transform: translate(-50%, -50%);
        width: 5px;
        height: 10px;
        border: solid;
        border-width: 0 2px 2px 0;
        border-color: white;
        transform: rotate(45deg);
    }

    &:checked {
        background-color: #CDB36E;
        &:after {
            content: "";
            position: absolute;
            top: 17%;
            left: 35%;
            transform: translate(-50%, -50%);
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }

`;

const CheckboxLabel = styled.label`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #666161;
    cursor: pointer;
    font-family: Spoqa Han Sans Neo;
    font-size: ${props => props.size};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.6px;

    span {
        pointer-events: none;
        color: #FF7235;
        font-family: Spoqa Han Sans Neo;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1.6px;
    }
    
`;

const handleLabelClick = (e) => {
    e.preventDefault();
    let targetElement = e.target;
    if (targetElement.tagName === 'SPAN') {
        targetElement = targetElement.parentNode;
    }
    targetElement.previousSibling.click();
}

const GrayCheckbox = ({ label, size, essential, ...props }) => {
    return (
        <CheckboxContainer>
            <CheckboxInput {...props} />
            <CheckboxLabel size={size} onClick={handleLabelClick}>{label}{essential && <span> (필수)</span> }</CheckboxLabel>
        </CheckboxContainer>
    );
}

export default GrayCheckbox;
import React from 'react';
import naverlogo from '../../assets/loginmodal/naverlogo.svg';
import kakaologo from '../../assets/loginmodal/kakaologo.svg';
import appleicon from '../../assets/loginmodal/appleicon.svg';
import styled from 'styled-components';

const redirectUrlroot = process.env.REACT_APP_SOCIAL_REDIRECT_URL
const LoginBox = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    img {
        width: 2rem;
        height: 2rem;
    };
`;

const SnsLoginButton = ({ social }) => {
    const LoginREf = React.createRef();

    const handleLoginClick = async () => {
        if (LoginREf.current) {
            var currentUrl;
            if ('localhost' === window.location.hostname) {
                currentUrl = "http://localhost:3000"
            } else {
                currentUrl = "https://www.metadh.co.kr"
            };
            const redirectUrl =`${redirectUrlroot}/${social}?returnUrl=${currentUrl}`;
            window.open(redirectUrl);
        } else {
            console.log("BUTTON NOT FOUND");
        }
    };
    
    switch (social) {
        case "naver":
            const naverid = "naverIdLogin";
            return (
                <LoginBox id={naverid} onClick={handleLoginClick}>
                    <div id={naverid} ref={LoginREf} style={{ 'display': 'none' }}></div>
                    <img src={naverlogo} alt={naverid} />
                </LoginBox>
            );
        case "kakao":
            return (
                <LoginBox id="kakaologin" onClick={handleLoginClick}>
                <div id="kakao-login-btn" ref={LoginREf} style={{ 'display':'none' }}></div>
                <img src={kakaologo} alt="KakaoLogin" /> 
                </LoginBox>
            );
        case "apple":
            return (
                <LoginBox id="applelogin" onClick={handleLoginClick} type="button">
                <div id="apple-login-btn" ref={LoginREf} style={{ 'display':'none' }}></div>
                <img src={appleicon} alt="AppleLogin" /> 
                </LoginBox>
            );
        default:
            return null;
    }

};

export default SnsLoginButton;
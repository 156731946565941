import React from "react";
import styled from "styled-components";

const CustomBtn = styled.button`
  cursor: pointer;
  width: ${props => props.width ? props.width : '14.6rem'};
  height: ${props => props.height ? props.height : '5.7rem'};
  font-size: ${props => props.fontsize ? props.fontsize : '2rem'};
  font-weight: ${props => props.fontweight ? props.fontweight : '900'};
  font-family: 'Noto Sans KR', sans-serif;
  pointer-events: inherit;
  border-radius: 3.6rem;
  background-color: ${props => props.bcolor ? props.bcolor : '#EFE8DD'};
  justify-content: ${props => props.position ? props.position : 'center'};
  color: black;
  
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: ${props => props.margintop ? props.margintop : '0rem'};
  width: 100%;
  justify-content: ${props => props.position ? props.position : 'center'};
`;

const MobileBeigeButton = ({ children, onClick, disabled=false, position='left' ,width, height, fontsize, fontweight, margintop, bcolor}) => {
  return (
    <ButtonContainer position={position} margintop={margintop}>
      <CustomBtn type="submit" onClick={onClick} position={position} disabled={disabled} width={width} height={height} fontsize={fontsize} fontweight={fontweight} bcolor={bcolor} >
        {children}
      </CustomBtn>
    </ButtonContainer>
  );
};

export default MobileBeigeButton;
import React from 'react';
import styled from 'styled-components';
import TermsContent from 'components/TermsContent';

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
`;

const ContentDiv = styled.div`
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
    width: 80%;
    height: 100%;

    h1 {
        font-size: 2rem;
        font-weight: 600;
        border-bottom: 3px solid black;
        text-align: left;
        width: 100%;
        padding: 1.6rem 0;
    }
    .text {

        padding: 1.6rem 0 15rem 0;
    }

    * {
        font-size: 1.4rem;
    }
`;


const Terms = () => {
  
  return (
    <>
        <Layout >
            <ContentDiv>
            <h1>이용 약관</h1>
                <div className="text">
                    <TermsContent />
                </div>
            </ContentDiv>
        </Layout>
        </>
    );
};
export default Terms;

const TermsAndConditionstext = `
이용약관 

제1장 총칙 

제1조 목적 
이 약관은원회(이하 "ATICO"라 한다)에서 운영하는 인터넷 홈페이지의 서비스(이하 "서비스"라 한다)를 이용함에 있어 사이트와 이용자의 권리 의무 및 책임사항을 규정함을 목적으로 합니다. 
제2조 용어정의 
이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 
1. "사이트"라 함은 ATICO가 컴퓨터 등 정보통신 설비를 이용하여 제공할 수 있도록 설정한 가상의 공간을 말합니다. 
2. "서비스"라 함은 ATICO의 홈페이지 및 ATICO가 운영하는 인터넷사이트 등에서 제공하는 인터넷상의 모든 서비스를 말합니다. 
3. "이용자"라 함은 본 약관에 동의하고, 인터넷 홈페이지에 접속하여 본 약관에 따라 ATICO가 제공하는 서비스를 받는 자를 말합니다. 
4. "운영자"라 함은 서비스의 전반적인 관리와 원활한 운영을 위하여 ATICO에서 선정한 사람을 말합니다. 
5. "개인정보"라 함은 당해 정보에 포함되어 있는 성명, 연락처, 이메일, 소속, 국적 등의 사항에 의하여 특정 개인을 식별할 수 있는 정보를 말합니다. 
제3조 약관의 게시 및 변경 
1. 이 약관은 ATICO가 서비스 화면에 공지하거나 기타의 방법으로 회원에게 통보함으로써 그 효력이 발생합니다. 
2. ATICO는 불가피한 사정이 있는 경우 관계법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다. 
3. ATICO는 사정상 변경의 경우와 영업상 중요사유가 발생한 경우에는 이 약관을 변경할 수 있으며, 변경된 약관은 전항과 같은 방법으로 공지 또는 통보함으로써 효력을 발생합니다. 
4. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관계법령 또는 상관례에 따릅니다. 
제4조 서비스의 내용 및 변경 
1. ATICO는 다음의 서비스를 제공합니다. 
a. ATICO에서 제공하는 일반 안내 서비스. 
b. 전시게임 정보조회 및 체험서비스. 
c. 컨퍼런스 정보조회 및 참여신청, 영상 서비스 
d. 비즈매칭 신청 및 화상회의 서비스 
e. 티켓 및 초대권 등록 및 사용 
f. 행사이벤트 조회 및 참여 
g. 기타 ATICO가 정하는 서비스. 
2. ATICO는 불가피한 사정이 있는 경우 제공하는 서비스의 내용을 변경할 수 있으며, 이 경우 변경된 서비스의 내용 및 제공일자를 명시하여 서비스 화면에 공지하거나 기타의 방법으로 회원에게 통보합니다. 
3. ATICO는 서비스 내용의 변경으로 인하여 이용자가 입은 손해에 대하여 배상하지 아니합니다. 단, ATICO의 고의 또는 중과실이 있는 경우에는 그러하지 아니합니다. 
제5조 서비스의 중단 
1. ATICO는 시스템 등 장치의 보수점검 및 고장, 일시적 통신장애, 서비스 개발, 시스템 정기 점검, 긴급조치 등 불가피한 사유에 의해 서비스 제공이 일정기간 동안 제한 또는 중단될 수 있습니다. 
2. ATICO는 제1항의 사유로 서비스 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자 가입은 손해에 대하여는 배상하지 아니합니다. 단, ATICO의 고의 또는 중과실이 있는 경우에는 그러하지 아니합니다. 

제2장 서비스 이용 계약 체결 

제6조 이용자의 분류 
1. 기업회원 : ATICO에서 승인한 스튜디오, 스폰서, 파트너 기업회원으로 유료서비스 이용이 가능합니다. 
2. 프레스회원 : ATICO에서 승인한 기자 등급의 일반회원으로 유료서비스 이용이 가능합니다. 
3. 인플루언서회원: ATICO에서 승인한 인플루언서 등급의 일반회원으로 유료서비스 이용이 가능합니다. 
4. 일반회원 : 기업회원,프레스회원,인플루언서회원을 제외한 일반적인 홈페이지 사용자로 티켓이나 초대권 등록 후 유료서비스 이용이 가능합니다. 
제7조 이용 계약의 성립 
1. ATICO사이트 이용자가 "동의합니다" 버튼을 누르면 이 약관에 동의하는 것으로 간주됩니다. 약관 변경 시에도 이와 동일하며, 변경된 약관에 동의하지 않을 경우 회원 등록 취소가 가능합니다. 
2. 이용계약은 서비스 이용희망자의 이용약관 동의 후 이용 신청에 대하여 ATICO가 승낙함으로써 성립합니다. ATICO는 고객의 이용신청이 다음 각 호에 해당하는 경우 그 신청에 대한 승낙을 제한 또는 유보할 수 있습니다. 
a. 이름이 실명이 아닐 경우. 
b. 메일주소 및 연락처를 허위로 기재했을 경우. 
c. 선량한 풍속, 기타 사회질서를 해 할 목적으로 신청한 경우. 
d. 예약신청의 내용을 허위로 기재하였거나 허위서류를 첨부하였을 경우. 
e. ATICO의 업무 수행 상 또는 기술상 지장이 있는 경우. 
f. 기타 이용신청고객의 귀책사유로 이용승낙이 곤란한 경우. 

제3장 서비스 제공 및 이용 

제8조 서비스의 내용 
1. 홍보용 디지털콘텐츠 : ATICO소개를 위한 디지털 콘텐츠 
2. 전시 : 전시 게임정보 조회 및 체험 
3. 컨퍼런스 : 온라인 세션영상 및 이벤트영상 서비스 참여 
4. 비즈매칭 : 스튜디오 회원 및 스폰서 회원 간의 온라인 비즈니스 미팅을 위한 매칭서비스 및 화상회의 서비스 
제9조 서비스 이용 시간 
1. 서비스는 ATICO의 업무상, 기술상 문제 등 기타 특별한 사정이 없는 한 매일 24시간 운영함을 원칙으로 합니다. 
2. ATICO는 일부 서비스의 이용 가능 시간을 별도로 정할 수 있습니다. 이 경우 서비스 이용안내를 통해 공지합니다. 
제10조 서비스 이용 요금 
1. 별도로 표시된 유료 서비스를 제외한 모든 서비스는 회원들에게 무료로 제공됩니다. 
2. 유료 서비스의 이용 요금 및 결제 방식은 해당 서비스에 명시되어 있는 규정에 따릅니다. 
제11조 회원의 게시물 
1. ATICO는 회원이 게재 또는 등록하는 내용물이 다음 각호에 해당된다고 판단하는 경우에 회원의 동의 없이 그 내용을 삭제할 수 있습니다. 
a. 다른 회원 또는 제 3자를 비방하거나 중상모략 함으로써 타인의 명예를 훼손시킨 경우. 
b. 공공질서 및 미풍양속에 위반되는 경우. 
c. 범죄적 행위에 결부된다고 인정되는 경우. 
d. 제3자의 저작권 등 기타 권리를 침해하는 경우. 
e .기타 관계 법령이나 ATICO에서 정한 규정에 위배되는 경우. 
제12조 서비스 제공의 중지 및 제한 
1. 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다. 
a. 서비스용 설비의 보수 또는 공사로 인한 부득이한 경우 
b.전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우 
c.천재지변에 의해 부득이한 경우. 
2. 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있을 때에는 서비스 제공을 중지하거나 제한할 수 있습니다. 

제4장 개인정보의 보호 

제13조 개인정보의 수집 
1. ATICO는 본 서비스의 원활한 활용을 위해 필요한 이용자의 신상정보를 수집할 수 있습니다. 
2. 이용자의 개인정보를 수집하는 때에는 이하 각호의 경우를 제외하고는 당해 이용자의 동의를 받습니다. 
a. 법률에 특별한 규정이 있는 경우 
b. 서비스이용계약의 이행을 위해서 필요한 경우. 
3. ATICO는 개인정보의 분실, 도난, 유출, 변조되지 아니하도록 안정성 확보에 필요한 기술적 조치 등을 강구해야 합니다. 
4. 제공된 개인정보는 당해 이용자의 동의없이 목적 외 이용이나 제3자에게 제공할 수 없습니다. 단, 다음의 경우에는 예외로 합니다. 
a. 법률에 특별한 규정이 있는 경우 
b. 사용자 인증 절차 
c. 서비스의 제공에 따른 요금정산 등을 위하여 필요한 경우 
d. ATICO홈페이지의 원활한 운영 및 통계분석자료로 활용 
e. 통계작성ᆞ 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우. 

제5장 ATICO및 이용자의 의무 

제14조 ATICO의 의무 
1. ATICO는 시스템 점검 및 서비스 개발, 통신장애, 기타 불가항력적인 사고 등 특별한 사정이 없는 한 이 약관 및 동의서가 정한 바에 따라 지속적으로 안정적인 서비스를 제공할 의무가 있습니다. 
2. ATICO는 이용자의 개인신상정보의 보안에 대하여 기술적 안전 조치를 강구하고 관리에 만전을 기함으로써 이용자의 정보보안에 최선을 다합니다. 
3. ATICO는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다. 
제15조 이용자의 의무 
1. 이용자는 서비스를 이용할 때 다음 각호의 행위를 하지 않아야 합니다. 
a. 신청 또는 변경 시 허위내용의 등록 
b. 본인 이외의 개인정보, 임직원번호 등을 부정하게 사용하는 행위 
c. 홈페이지에 게시된 정보의 변경 
d. 서비스를 이용하여 얻은 정보를 회원의 개인적인 이용 외에 복사, 가공, 번역, 2차적 저작 등을 통하여 복제, 공연, 방송, 전시, 배포, 출판 등에 사용하거나 제3자에게 제공하는 행위 
e. 타인의 명예를 손상시키거나 불이익을 주는 행위 
f. ATICO의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위 
g. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위 
h. 범죄와 결부된다고 객관적으로 인정되는 행위 
i. 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염자료를 등록 또는 유포하는 행위 
j. 서비스의 안정적 운영을 방해할 수 있는 정보를 전송하거나 수신자의 의사에 반하여 광고성 정보를 전송하는 행위 
k. 기타 관계법령 및 ATICO규정에 위배되는 행위 

제6장 기타 

제16조 저작권의 귀속 및 이용제한 
1. ATICO는 작성한 저작물에 대한 저작권 기타 지적재산권은 ATICO에 귀속합니다. 
2. 이용자는 홈페이지를 이용함으로써 얻은 정보를 ATICO의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다. 
제17조 분쟁해결 
1. ATICO과 이용자는 서비스와 관련하여 발생한 분쟁을 원활하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다. 
2. ATICO는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다. 
제18조 재판권 및 준거법 
1. ATICO와 이용자간에 서비스 이용으로 발생한 분쟁에 관한 소송은 ATICO주소지 관할 법원으로 합니다. 
2. ATICO와 이용자간에 제기된 소송에는 대한민국법을 적용합니다. 

부칙 
이 약관은 2020년 9월 28일부터 시행합니다.

`;

export default TermsAndConditionstext;
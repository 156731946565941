import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import fetcher from 'utils/api/fetcher';
import { SkeletonMapElement } from 'element/SkeletonMapElement';
import styled from 'styled-components';
import ResponsiveGrid from 'element/Grid';
import HorizontalLinearAlternativeLabelStepper from 'element/Stepper';
import Avatar from '@mui/material/Avatar';



const getOrdersDetail =  async (id) => {
    const token = localStorage.getItem('com.naver.nid.oauth.state_token');
    const url = `/api/order/${id}`;
    const headers = { Authorization: token };
    return await fetcher(url, 'GET', null, headers);
};


const OrderDetails = () => {
    const { id } = useParams();
    const [order, setOrder] = useState(null);
    const [formattedDate, setFormattedDate] = useState(null);
    

    useEffect(() => {
        const fetchOrderDetails = async () => {
        try {
            const data = await getOrdersDetail(id);
            setOrder(data);
            const dateStr = data.created_on;
            const date = new Date(dateStr);
            const dateFormatted = date.toLocaleDateString('ko-KR', { year: 'numeric', month: '2-digit', day: '2-digit' })
            setFormattedDate(dateFormatted);
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
        };
        fetchOrderDetails();
        ;
    }, [id]);

    if (!order) {
        return <SkeletonMapElement />
    }

    return (
        <OrderDetailsWrapper>
            <OrderDetailsContent>
                {order && (
                    <>
                    <Stepper>
                        <HorizontalLinearAlternativeLabelStepper status={ order.status }/>
                    </Stepper>
                    <DetailSection>
                        <ReceiptSection>
                            <TextSection>
                                <OrderSection>
                                    <span className='status'>{order.status}</span>
                                    <span>주문번호 : {order.number}</span>
                                    <span>{order.payments[0].amount}</span>
                                    <span>결제일 : {formattedDate}</span>
                                    <span>{order.payments[0].payment_method}</span>
                                    <span>{order.payments[0].vender}</span>
                                </OrderSection>

                                <ApartmentSection>
                                    <span>{order.apartments.address}</span>
                                    <span>{order.apartments.fullName}</span>

                                </ApartmentSection>
                            </TextSection>
                            {/* <ToolbarComponent/> */}
                        </ReceiptSection>
                        <OrderProcessSection>
                            <DesignerWrapper>
                                {order.designer && (
                                    <>
                                    <Avatar alt={order.designer.fullname} src={order.designer.profile_image} sx={{ width: 40, height:40}} />
                                    <span className='dname'> 담당 디자이너 : {order.designer.fullname}</span>
                                    </>
                                )}
                            </DesignerWrapper>
                            
                            <OnSite>
                                {order.insite && (
                                    <>
                                    <h2 className='title'>현장 방문</h2>
                                    <span> 현장 방문 날짜 {order.insite?.date}</span>
                                    <ResponsiveGrid image={order.insite?.files}/>
                                    </>
                                )    
                                }
                            </OnSite>
                            <PlanWrapper>
                                {order.designplan && (
                                    <>
                                    <h2 className='title'>디자인 시안</h2>
                                    <ResponsiveGrid image={order.designplan}/>
                                    </>
                                )}
                            </PlanWrapper>
                            <ConstructionWrapper>
                                {order.construction && (
                                    <>
                                    <h2 className='title'>시공</h2>
                                    <span className='startdate'> 시공 시작일 { order.construction?.start_date }</span>
                                    <ResponsiveGrid image={order.construction.files}/>
                                    
                                    { order.construction.updates &&(
                                        <>
                                        <h2> 추가 내용 </h2>
                                        <span> {order.construction.updates.details} : {order.construction.updates.writer}</span>
                                        </>
                                    )}
                                    </>
                                )}
                                </ConstructionWrapper>
                        </OrderProcessSection>
                    </DetailSection>
                    
                    </>
                )}
            </OrderDetailsContent>
        </OrderDetailsWrapper>
    );
};

export default OrderDetails;

const ConstructionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 3rem 0;
    border-bottom: 1px solid #e9ecef;
    width: 100%;
    span.startdate {
        font-size: 1.6rem;
        font-weight: 400;
        color: #707072;

    }
`;

const PlanWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 3rem 0;
    border-bottom: 1px solid #e9ecef;
    width: 100%;
`;

const DetailSection = styled.section`
    display: flex;
    flex-direction: column;

    padding: 0 5rem;
    border-bottom: 1px solid #e9ecef;
`;

const OnSite = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 3rem 0;
    border-bottom: 1px solid #e9ecef;
    span {
        font-size: 1.6rem;
        font-weight: 400;
        color: #707072;

    }
`;

const DesignerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;


    padding: 3rem 0;
    span.dname {
        font-size: 16px;
        font-weight: 500;
    }
    border-bottom: 1px solid #e9ecef;
`;

const OrderProcessSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: left;

    left: 0;
    gap: 1rem;
    .title {
        font-size: 2rem;
        font-weight: 700;
        padding: 0 0 2rem 0;
    }
`;

const ReceiptSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #e9ecef;
`;

const ApartmentSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const OrderSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .status {
        font-size: 3rem;
        font-weight: 700;
        padding: 0 0 2rem 0;
        color: black;
    }
`;

const OrderDetailsWrapper = styled.section`
    margin-top: 8rem;
    display: flex;
    width: 100%; 
`;
const OrderDetailsContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    width: 70rem;
    gap: 5rem;
    border-radius: 1rem;
`;

const TextSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 4rem 1rem;
    flex-shrink: 0;
    span {
        font-size: 1.6rem;
        font-weight: 400;
        color: #707072;
    }
`

const Stepper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50%;
    padding: 4rem 0 2rem 0;
    align-items: center;
    white-space: nowrap;

    text.MuiStepIcon-text {
        font-size: 1.5rem;
    }
    span.MuiStepLabel-label {
        font-size: 1.2rem;
        font-weight: 600;
    }

`;
import { Audio } from 'react-loader-spinner'
import styled from 'styled-components';
const Section = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 88vw;
`;


const Spinner = () => {
    return (
        <Section>
        <Audio
            height="80"
            width="80"
            radius="9"
            color="rgb(86, 111, 238)"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
        />
        </Section>
    );
    }

export default Spinner;
import React from "react";
import styled from "styled-components";

const CustomBtn = styled.button`
  cursor: pointer;
  width: ${props => props.width ? props.width : '14.6rem'};
  height: ${props => props.height ? props.height : '5.7rem'};
  font-size: ${props => props.fontsize ? props.fontsize : '2rem'};
  font-weight: ${props => props.fontweight ? props.fontweight : '900'};
  font-family: 'Noto Sans KR', sans-serif;
  pointer-events: inherit;
  border-radius: ${props => props.radius ? props.radius : '3.6rem'};
  background-color: ${props => props.bcolor ? props.bcolor : 'black'};
  color: ${props => props.fontColor ? props.fontColor : 'white'};
  &:hover {
    background-color: #00000082;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: ${props => props.margintop ? props.margintop : '0rem'};
  width: 100%;
  justify-content: ${props => props.position === 'left' ? 'left' : props.position};
`;

const BeigeButton = ({ children, fontColor, onClick, disabled=false, position='left' ,width, height, fontsize, fontweight, margintop, bcolor, radius}) => {
  
  return (
    <ButtonContainer position={position} margintop={margintop}>
      <CustomBtn type="submit" fontColor={fontColor} onClick={onClick} disabled={disabled} width={width} height={height} fontsize={fontsize} fontweight={fontweight} bcolor={bcolor} radius={radius}>
        {children}
      </CustomBtn>
    </ButtonContainer>
  );
};

export default BeigeButton;
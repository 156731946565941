
import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import {  searchValueState } from "store/atoms";
import styled from "styled-components";

import { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { getPortfolioFilterSearch } from "utils/api/api";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoIosCheckboxOutline } from "react-icons/io";
import { IoIosCheckbox } from "react-icons/io";


const FilterSection = styled.section`
    display:flex;
    flex-direction: column;
    width: 100%;

    padding-left: 3.9rem;
    padding-right: 3.2rem;
`;
const Search = styled.div`
    display: inline-flex;
    width: 100%;
    height: 9.2rem;
    gap: 4.9rem;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    white-space: nowrap;
    border-bottom: 1px solid var(--primary_color_2, #D9D9D9);
`;


const ResetFilter = styled.div`
    background-color: ${props => props.filterused ? '#000000' : '#BCBCBC'};
    align-items: center;
    justify-content: center;
    position: relative;
    // top: -0.6rem;

    display: flex;
    font-size: 1.4rem;
    font-family: "Noto Sans KR";
    font-weight: 500;
    flex-shrink: 0;

    height: 3.5rem;
    width: 7.2rem;
    border-radius: 3.1rem;
    cursor: pointer;
    color: white;
    margin-right: 1.8rem;
`;

const InputSearch = styled.input`
    all: unset;
    width: 100%;
    height: 4rem;
    font-size: 1.4rem;
    padding: 0 1rem;
    border-bottom: 0.075rem solid black;
`;

const TextFilter = styled.div`
    display: flex;
    margin-left: auto;
    width: 30rem;
    align-items: center;
`;

const GridContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Grid = styled.div`
    flex-basis: calc(100% / 6);
    box-sizing: border-box;
    padding-bottom: 21px;
    height: fit-content;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    gap: 0.5rem;
    &:hover {
        border-color: #aaa;
    }

    & > label {
        font-size: 14px;
        cursor: pointer;
        color: ${props => props.checked ? '#333' : '#aaa'};
    }
`;

const CheckboxFiltersWrapper  = styled.div`
    display: flex;
    margin-top: 15.67px;
    width: 100%;
    z-index: 1;
`;

const FilterWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    cursor: pointer;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    color: black;
    width: 6.8rem;
    // padding-bottom: 1.2rem;
    border-bottom: ${props => props.active ? '2px solid #000000' : 'transparent'}; 
`;

const PortfolioFilter = () => {
  // const modalRef = useRef(null);
  // const [activeBtn, setActiveBtn] = useState(null);
  const [filterused, setfilterused] = useState(false);
  const isSearchValue = useRecoilValue(searchValueState);
  const [activeFilter, setActiveFilter] = useState(null);
  const [apiData, setApiData] = useState(null);

  const toggleFilter = (title) => {
    setActiveFilter(prevState => prevState === title ? null : title);
  };

  const filterOptions = {
    '시공 유무': ['홈스타일링', '인테리어'],
    '스타일': ['MODERN','CLASSIC','VINTAGE','NORDIC','NATURAL'],
    // '평수': ['10평대','20평대','30평대','40평대','50평대','60평대','70평대','80평대','100평대','100평대 이상'],
    // '추천순': ['abc', 'edc']
  };

  const keymap = {
    '시공 유무': 'construct',
    '스타일': 'style',
    // '평수': 'roomsize',
    // '추천순': 'recommend'
  };

  const initialFilterState = Object.keys(keymap).reduce((acc, key) => {
    acc[keymap[key]] = [];
    return acc;
  }, {});

  const [filterState, setFilterState] = useState(initialFilterState);

  useEffect(() => {
    const filterUsed = Object.values(filterState).some((v) => v.length > 0);
    setfilterused(filterUsed);
  }, [filterState]);

  const updateFilterState = (title, option) => {
    setFilterState(prevState => {
      const newState = {
        ...prevState,
        [keymap[title]]: prevState[keymap[title]].includes(option) ? prevState[keymap[title]].filter(i => i !== option) : [...prevState[keymap[title]], option]
      };
      return newState;
    });
  };

  const resetFilter = () => {
    setFilterState(initialFilterState);
  };


  const CheckboxFilters = ({ title, options, state, handleClick }) => (
    <CheckboxFiltersWrapper className="checkbox">
      <GridContainer container>
        {options.map((option, index) => (
          <Grid key={index}>
            <Container
              onClick={() => handleClick(title, option)}
              checked={state.includes(option)}
            >
              {state.includes(option) ? <IoIosCheckbox size={'1.6rem'}/> : 
              <IoIosCheckboxOutline size={'1.6rem'} style={{color: '#BCBCBC'}}/>}
              <label id={option}>{option}</label>
            </Container>
          </Grid>
        ))}
      </GridContainer>
    </CheckboxFiltersWrapper>
  );
  
  useEffect(() => {
    const fetchData = async () => {
      const data = {
        ...isSearchValue,
        ...Object.fromEntries(Object.entries(filterState).map(([k, v]) => [k, v.join(',')]))
      };
      const response = await getPortfolioFilterSearch(data);
      console.log("response : ", response);
      setApiData(response);
    }
    fetchData();
  }, [filterState, isSearchValue]);

  return { apiData, FilterSection : (
    <FilterSection>
      <Search>
          <ResetFilter filterused={filterused} onClick={resetFilter}>초기화</ResetFilter>
          {Object.keys(filterOptions).map((title, index) => (
            <>
              <FilterWrapper 
                onClick={() => toggleFilter(title)} 
                active={activeFilter === title} 
                key={index}>
                {title}<MdOutlineKeyboardArrowDown size={20} />
              </FilterWrapper>
            </>
          ))
          }
          <TextFilter>
              <InputSearch placeholder="검색"></InputSearch>
              <BiSearch size={30} color={"black"} onClick={() =>{}}/>
          </TextFilter>
      </Search>
      {activeFilter && (
        <CheckboxFilters
          title={activeFilter}
          options={filterOptions[activeFilter]}
          state={filterState[keymap[activeFilter]]}
          handleClick={updateFilterState}
        />
      )}
    </FilterSection>
  )};
};

export default PortfolioFilter;

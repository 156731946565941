import React from "react";
import styled from "styled-components";

const CustomBlock = styled.div`
    display: flex;
    height: ${props => props.height};
    width: ${props => props.width};
`;

const Block = ({ children, height, width, ...props }) => {
    return (
        <CustomBlock height={height} width={width} {...props}>
            {children}
        </CustomBlock>
    );
};

export default Block;

import styled from "styled-components";
import VideoComponent from 'components/VideoComponent';

const Wall = styled.section`
    display: flex;
    position: relative;
    white-space: nowrap;
    justify-content: space-between;
    width: 100%;
    height: 80vh;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : null};
`;

const TextContainer = styled.div`
    display: flex;
    position: absolute;
    top: ${props => props.top ? props.top : '-10%'};
    left: ${props => props.left ? props.left : '-30%'};
    width: 100%;
    height: 100%;
    z-index: 3;
    padding-top: 6rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color : white;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : 'transparent'};
`;

const TextWrapper = styled.div`
    display: flex;
    width: 40.4rem;
    height: 23.6rem;
    flex-direction: column;
    align-items: center;
    justify-content: top;
`;

const ImageContainer = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    align-items: top;
    justify-content: top;
    z-index: 2;
    
    img {
        z-index: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    video {
        width: 100%;
        object-fit: cover;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${props => props.ImgBackgroundColor ? props.ImgBackgroundColor : null};
        z-index: 1;
    }
`;

const Bigwall = ({children, top,left, position, fit, loop, backgroundColor, image, video, ImgBackgroundColor, autoPlayOnLoad}) => {
    return (
        <Wall className="wallpaper">
            <TextContainer backgroundColor={backgroundColor} top={top} left={left}>
                <TextWrapper>
                    {children}
                </TextWrapper>
            </TextContainer>
            <ImageContainer className="imagecon" position={position} fit={fit} ImgBackgroundColor={ImgBackgroundColor}>
                {video && <VideoComponent loop={loop} autoPlayOnLoad={autoPlayOnLoad} muted src={video} alt="wall"/>}
                {image && <img src={image} alt="wall"/>}
            </ImageContainer>
        </Wall>
    )
}

export default Bigwall;
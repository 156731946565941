export const theme = {
    mainColor: '#CDB36E',
    unselectColor: '#757E89',
    selectColor1: '#5d5d5d',
    selectColor2: '#707070',
    boldColor: '#222222',
    borderColor: '#efefef',
    mediaQuery: {
        phone: '(max-width: 440px)',
        tablet: '(max-width: 1200px)',
        desktop: '(max-width: 3120px)'
    }
};

import React from 'react';
import styled from 'styled-components';
import { SkeletonElementTextWrapperStyle } from 'utils/style/mixins';
import { useLocation } from 'react-router-dom';
// import { useSetRecoilState } from 'recoil';
// import { isLoginModalState } from 'store/atoms';
import { useMutation, useQueryClient } from 'react-query';
// import { postWish } from 'utils/api/api';
import { getCookie } from 'utils/cookie/cookie';
import Button from './Button';
import { postHouseDelete } from '../utils/api/api';
import { HiBuildingOffice2 } from 'react-icons/hi2';
import { FiChevronRight } from 'react-icons/fi';

const AptElementWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background-color: white;
`;

const AptElementTextWrapper = styled.div`
    ${SkeletonElementTextWrapperStyle}
    justify-content: space-between;
`;

const Chevronright = styled.div`
    align-self: center;
    right: 0;
    top: 50%;
    .chevron {
        color: black;
        
    }
    
    
`;

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-top: 0.75rem;
    gap: 1rem;
    justify-content: flex-end;
    button {
        width: 40%;
        align-self: flex-end;
    }
`;

const AptElement = ({ house, isWish, made }) => {
    const { pathname } = useLocation();
    // const setIsLoginModal = useSetRecoilState(isLoginModalState);
    // const [islikeState, setIsLikeState] = useState(house.isLike);
    const token = getCookie('jwt');

    // // 로그인 x -> 하트 눌렀을 때
    // const moveLogin = (e) => {
    //     e.stopPropagation();
    //     alert('로그인 후 가능합니다. 로그인 해주세요.');
    //     setIsLoginModal(true);
    // };
    // // 로그인 o -> 하트 눌렀을 때
    // const likeClick = (e, id) => {
    //     e.stopPropagation();
    //     setIsLikeState(!islikeState);
    //     likeMutation.mutate(id);
    // };

    const queryClient = useQueryClient();
    // const likeMutation = useMutation((id) => postWish(id, getCookie('token')), {
    //     onSuccess: (res) => {
    //         alert(res.data.message);
    //         if (isWish) {
    //             queryClient.invalidateQueries('wish');
    //         } else {
    //             queryClient.invalidateQueries('infinityTest');
    //         }
    //     },
    // });

    const removeMutation = useMutation((id) => postHouseDelete(id, token), {
        onSuccess: () => {
            queryClient.invalidateQueries('registration');
            alert('삭제가 완료되었습니다!');
        },
    });

    const onRemove = async (e, id) => {
        e.stopPropagation();
        const res = await removeMutation.mutateAsync(id);
        console.log(res);
    };
    
    return (
        <AptElementWrapper >
            {/* {localUserName[0] === '' ? <LikeState size={28} position={true} isTrue={false} onClick={moveLogin} /> : <LikeState size={28} position={true} isTrue={house?.likes} onClick={(e) => likeClick(e, house.id)} />} */}
            <AptElementTextWrapper>
                <header className="apartment-header">
                    <div>
                        <HiBuildingOffice2 size={14} color='black'/>
                    </div>
                    <h1>{house?.name}</h1>
                </header>
                <div className="apartment-body">
                    <p>{house.address} | {house.year} </p>
                </div>
                {pathname === '/profile' && made === true && (
                    <ButtonWrapper>
                        <Button type={true} onClick={(e) => onRemove(e, house.id)}>
                            삭제
                        </Button>
                    </ButtonWrapper>
                )}
            </AptElementTextWrapper>
            
            <Chevronright>
                <FiChevronRight className='chevron' size={20} />
            </Chevronright>
        </AptElementWrapper>
    );
};

export default AptElement;

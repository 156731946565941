import React, { useState, useRef } from "react";
import styled from "styled-components";
import GrayCheckbox from "element/GrayCheckbox";
import { flexColumnCenter } from "utils/style/mixins";
import ProgressBar from 'element/ProgressBar';
import TermsAndConditionstext from "element/TermsAndConditionstext";
import Privacytext from "element/Privacytext";
import AgreementComp from "components/sociallogin/AgreementComp";
import { signupFormState } from 'store/atoms';
import { useRecoilState } from 'recoil';

const FormWrapper = styled.div`
    width: 100%;
    
    max-height: 100%;
    align-items: center;
    background-color: white;
    
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${props => props.theme.mediaQuery.desktop} {
        padding: 5.6rem 5.6rem 7.6rem 5.6rem;
        gap: 7.6rem;
        height: 100%;
    };

    @media ${props => props.theme.mediaQuery.tablet} {
        padding: 5.6rem 5.6rem 7.6rem 5.6rem;
        height: 100vh;
        overflow-y: scroll;
    };

    @media ${props => props.theme.mediaQuery.phone} {
        padding: 5.6rem 3rem 7.6rem 3rem;
        height: 100%;
        overflow-y: scroll;
    };

`;

const HeaderProgressBar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${props => props.theme.mediaQuery.desktop} {
        padding: 0 12.9rem;
    };

    @media ${props => props.theme.mediaQuery.tablet} {
        padding: 0;
    };

    @media ${props => props.theme.mediaQuery.phone} {
        padding: 0 ;
    };
`;

const TItleWrapper = styled.div`
    width: 100%;
    height: 100%;
    ${flexColumnCenter}
`;

const BodyWrapper = styled.div`
    width: 100%;
    height: 100%;
    ${flexColumnCenter}
    gap: 5.9rem;
`;

const SubmitWrapper = styled.div`
    width: 100%;
    height: 100%;
    ${flexColumnCenter}
`;

const TItleH1 = styled.span`
    font-family: Spoqa Han Sans Neo;
    font-size: 3.2rem;
    font-weight: 700;
    color: #222;
    letter-spacing: -2.56px;
`;

const TItlespan = styled.span`
    color: #757E89;
    text-align: center;
    font-family: Spoqa Han Sans Neo;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -1.28px;
`;

const AgreementBox = styled.div`
    height: 18rem;
    width: 100%;
    max-width: 65rem;
    display: flex;
    align-items: flex-start;
    white-space: pre-wrap;
    color: #222;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const AgreementContainer = styled.div`
    display: flex;
    height: fit-content;
    width: 100%;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    overflow-y: scroll;
    cursor: pointer;
`;

const AgreementWrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    gap: 2.6rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Button = styled.button`
    display: flex;
    width: 148px;
    height: 48px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    border-radius: 500px;
    background: #CDB36E;
    color: #FFF;
    cursor: pointer;

    span {
        color: #FFF;
        font-family: Spoqa Han Sans Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    &:active {
        background-color: #5433D4;
        color: white;
    }
    &:focus {
        background-color: #5433D4;
        color: white;
    }
`;

const SignupAgreementsForm = () => {
    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);
    // const [isSignupForm, setSignupForm] = useRecoilState(currentSignupStage);
    const [AgreementCompshow, setAgreementCompshow] = useState(false);
    const [agreementType, setAgreementType] = useState(null);
    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isCheckedAll, setIsCheckedAll] = useState(false);
    const checkboxRef1 = useRef(null);
    const checkboxRef2 = useRef(null);
    const [signupForm, setSignupForm] = useRecoilState(signupFormState);

    const handleHide = () => {
        setAgreementCompshow(false);
    };

    const handleDetailLook = (type) => () => {
        setAgreementCompshow(true);
        setAgreementType(type);
    }

    const handleCheckbox1Change = (event) => {
        setIsChecked1(event.target.checked);
        setError1(!event.target.checked);
    }

    const handleCheckbox2Change = (event) => {
        setIsChecked2(event.target.checked);
        setError2(!event.target.checked);
    }

    const handleCheckboxChangeAll = (event) => {
        setIsCheckedAll(event.target.checked);
        setIsChecked1(event.target.checked);
        setIsChecked2(event.target.checked);
    }

    const handleSubmit = () => {
        if (!isChecked1) {
            setError1(true);
        }

        if (!isChecked2) {
            setError2(true);
            return;
        }

        setSignupForm((prevform) =>
        ({...prevform,
            lastsignupstage: 2, // 다음 단계로 넘어가기
            termsconscent: isChecked1,
            privacyconscent: isChecked2,
        }));
    };

    let title, body;
    if (agreementType === 'agreement') {
        title = "이용약관";
        body = TermsAndConditionstext;
    } else if (agreementType === 'privacy') {
        title = "개인정보 필수 및 이용안내";
        body = Privacytext;
    }
    if (AgreementCompshow) {
        return <AgreementComp title={title} body={body} onHide={handleHide} />
    }
    
    return (
        <>
        <FormWrapper>
            <HeaderProgressBar>
                <ProgressBar activePoint={signupForm.lastsignupstage}/>
            </HeaderProgressBar>

            <TItleWrapper>
                <TItleH1>약관동의</TItleH1>
                <TItlespan>Atico 회원가입을 위해 약관에 동의해주세요.</TItlespan>
            </TItleWrapper>

            <BodyWrapper className="agreementform">
                <GrayCheckbox size='20px' checked={isCheckedAll} onChange={handleCheckboxChangeAll} label="이용약관, 개인정보 수집 및 이용, 마케팅 정보 수신 동의(선택)에 모두 동의합니다." />
                
                <AgreementWrapper >
                    <GrayCheckbox  size='20px' label="이용약관" essential={true}  checked={isChecked1} onChange={handleCheckbox1Change}/>
                    {error1 && <p ref={checkboxRef1} style={{color:'red',fontSize:'18px'}}>이용약관에 동의해주세요.</p>}
                    <AgreementContainer onClick={handleDetailLook('agreement')}>
                        <AgreementBox>
                            {TermsAndConditionstext}
                        </AgreementBox>
                    </AgreementContainer>
                </AgreementWrapper>

                <AgreementWrapper>
                    <GrayCheckbox size='20px' label="개인정보 필수 및 이용안내" essential={true} checked={isChecked2} onChange={handleCheckbox2Change}/>
                    {error2 && <p ref={checkboxRef2} style={{color:'red',fontSize:'18px'}}>이용안내에 동의해주세요.</p>}
                    <AgreementContainer onClick={handleDetailLook('privacy')}>
                        <AgreementBox>
                            {Privacytext}
                        </AgreementBox>
                    </AgreementContainer>
                </AgreementWrapper>
            </BodyWrapper>

            <SubmitWrapper>
                <ButtonWrapper>
                    <Button onClick={handleSubmit}><span>확인</span></Button>
                </ButtonWrapper>
            </SubmitWrapper>
        </FormWrapper>
        </>
    );
};


export default SignupAgreementsForm;

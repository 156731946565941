import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';

const DropDownWrapper = styled.div`
  display: flex;
  width: 100%;

  .dropdownmenus {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.9rem;
  }

  #dropdown-item-button {
    background-color : white;
    color: black;
    width: 10.7rem;
    height: 5rem;
    border: 1px solid #747C87;
    border-radius: 4px;
    font-size: 1.6rem;
    font-weight: regular;
    font-family: Sqoqa Han Sans Neo;
    &:hover {
      background-color: #747C87;
      color: white;
    }
  }

  .dropdown-menu {
    width: 10.7rem;
    height: 30rem;
    border: 1px solid #747C87;
    border-radius: 4px;
    overflow-y: scroll;
    .dropdown-item {
      width: 10.7rem;
      height: fit-content;
      font-size: 1.6rem;
      font-weight: regular;
      font-family: Sqoqa Han Sans Neo;
      overflow-y: scroll;
      &:hover {
        background-color: #747C87;
        color: white;
      }
    }
    
  }
`;

const DateDropdownV2 = ({selectedYear, setSelectedYear, selectedMonth, setSelectedMonth, selectedDay, setSelectedDay}) => {
  const yeardatalist = Array.from({length: 105}, (_, i) => 1920 + i).reverse();
  const monthdatalist = Array.from({length: 12}, (_, i) => i + 1);
  const daydatalist = Array.from({length: 31}, (_, i) => i + 1);
  // const [selectedYear, setSelectedYear] = useState("년도");
  // const [selectedMonth, setSelectedMonth] = useState("월");
  // const [selectedDay, setSelectedDay] = useState("일");

  return (
    <DropDownWrapper>
      <Dropdown className='dropdownmenus'>
        <DropdownButton id="dropdown-item-button" title={selectedYear? selectedYear:'년도'}>
        {
          yeardatalist?.map((data) => {
            return (
              <Dropdown.Item as="div" onClick={() => setSelectedYear(data)}>{data}</Dropdown.Item>
            );
          })
        }
        </DropdownButton>

        <DropdownButton id="dropdown-item-button" title={selectedMonth ? selectedMonth : '월'}>
        {
          monthdatalist?.map((data) => {
            return (
              <Dropdown.Item as="div" onClick={() => setSelectedMonth(data)}>{data}</Dropdown.Item>
            );
          })
        }
        </DropdownButton>
        
        <DropdownButton id="dropdown-item-button" title={selectedDay ? selectedDay : '일'}>
        {
          daydatalist?.map((data) => {
            return (
              <Dropdown.Item as="div" onClick={() => setSelectedDay(data)}>{data}</Dropdown.Item>
            );
          })
        }
        </DropdownButton>
      </Dropdown>
      </DropDownWrapper>
  );
}

export default DateDropdownV2;
import fetcher from './fetcher';

export const postSignup = async (data) => {
    console.log("POSTING DATA ", data)
    return await fetcher('/member/signup', 'post', data);
};

export const postSignin = async (data) => {
    console.log("POSTING DATA ", data)
    return await fetcher('/member/login', 'post', data);
};

export const getMemberDetail = async (id, token) => {
    return await fetcher(`/member/${id}`, 'get', null, token);
};

export const getMemberList = async (page, token) => {
    return await fetcher(`/member/?page=${page}&size=20`, 'get', null, token);
};

export const getFavPicList = async (page, token) => {
    return await fetcher(`/social/favtest/images?page=${page}&size=20`, 'get', null, token);
};

export const getFavPicDetail = async (idx, token) => {
    return await fetcher(`/social/favtest/images/${idx}`, 'get', null, token);
};

export const postFavPicCreate = async (data, token) => {
    return await fetcher(`/social/favtest/images`, 'post', data, token);
};

export const postFavPicCheckList = async (data, token) => {
    // 클릭한 이미지들 post로 보내기
    return await fetcher(`/social/favtest/`, 'post', data, token);
};

export const putFavPicEdit = async (idx, data, token) => {
    return await fetcher(`/social/favtest/images/${idx}`, 'put', data, token);
};

export const getRandomFavPic = async (style, token) => {
    return await fetcher(`/social/favtest/images?random=true&size=3&page=1&style=${style}`, 'get', token);
};

export const postCheckduplicheckEmail = async (data) => {
    return await fetcher(`/member/duplicheck?email=${data}`, 'get');
}

export const postVerifyEmail = async (data) => {
    return await fetcher(`/member/authcheck/email/verify`, 'post', data);
}
export const postVerifyCheckEmail = async (data) => {
    return await fetcher(`/member/authcheck/email/check`, 'post', data);
}

export const getFavTestResult = async (key, token) => {
    return await fetcher(`/social/favtest/results/${key}`, 'get', token);
};

export const getApplyList = async (page,token) => {
    return await fetcher(`/cs/?page=${page}&size=20`, 'get', null, token);
};

export const getApartments = async (data) => {
    let res = '';
    if (data.id) {
        res = await fetcher(`"/apartments/?name=${data.id}`);
    } else {
        res = await fetcher(`/apartments/`);
    }
    return res;
};

export const getDetailPortfolio = async (id) => {
    const url = `/portfolio/${id}/`;
    return fetcher(url).then((res) => {
        return res.data;
    }).catch((err) => { 
        console.log("ERROR getDetailPortfolio API ", err);
        return [];
    })
};

export const getDetailApartments = async (id) => {
    const res = await fetcher(`/apartments/${id}`);
    return res;
};

export const getApartmentPrice = async (id) => {
    await fetch( id ? 
        `http://localhost:8001/crawler/govapi/${id}` :
        `http://localhost:8001/crawler/govapi/?page=1&size=10`
    ).then((res) => res.json())
     .then((res) => {
        return res;
     }).catch((err) => {
        console.log("ERROR getApartmentPrice API ", err);
        return [];
     });
};

export const getWishList = async (token) => {
    const url = '/apartments/wish';
    const res = await fetcher(url, 'get', null, { Authorization: token });
    return res;
};

export const getDetailPatch = async (id) => {
    let url = `/apartments/${id.id}/`;
    if (id.userId) {
        url = `/apartments/${id.houseId}/?userId=${id.userId}`;
    }
    const res = await fetcher(url);
    return res;
};

export const postDetailRequest = async (data, token) => {
    const url = '/reservation';
    const res = await fetcher(url, 'post', data, { Authorization: token });
    return res;
};

export const postHouses = async (data, token) => {
    const url = '/apartments/';
    const res = await fetcher(url, 'post', data, { Authorization: token, 'Content-Type': 'multipart/form-data' });
    return res;
};

export const getRegistration = async (token) => {
    const url = '/apartments/registration';
    const res = await fetcher(url, 'get', null, { Authorization: token });
    return res;
};

export const getTags = async () => {
    const url = '/tags/';
    const res = await fetcher(url);
    return res;
};

export const postWish = async (id, token) => {
    const url = `/apartments/wish/${id}`;
    const res = await fetcher(url, 'post', {}, { Authorization: token });
    return res;
};

// 검색시 
export const getSearchHouses = async (data) => {
    let res = '';
    if (data.id) {
        res = await fetcher(
            `/apartments/?location=${data.filter.location}&peopleCount=${data.filter.peopleCount}&minPrice=${data.filter.minPrice}&maxPrice=${data.filter.maxPrice}&startDate=${data.filter.startDate}&endDate=${data.filter.endDate}&page=1&size=10&sortBy=id&isAsc=false&userId=${data.id}`
        );
    } else {
        res = await fetcher(`/apartments/?location=${data.filter.location}&peopleCount=${data.filter.peopleCount}&minPrice=${data.filter.minPrice}&maxPrice=${data.filter.maxPrice}&startDate=${data.filter.startDate}&endDate=${data.filter.endDate}&page=1&size=10&sortBy=id&isAsc=false`);
    }
    return res;
};

export const getPortfolioFilterSearch = async (data) => {
    let url;
    if (typeof data === "string" || typeof data === "number") {
        url =`/portfolio/?search=${data}&page=1&size=10`
    } else {
        url = data ? `/portfolio/?search=${data?.search ? data.search : ''}&style=${data.style ? data.style : ''}&page=1&size=10` : `/portfolio/?page=1&size=10`;
    }
    return fetcher(url).then((res) => {
        return res.data;
    }).catch((err) => { 
        console.log("ERROR getPortfolioFilterSearch API ", err);
        return [];
    }
    )
};

export const postHouseDelete = async (id, token) => {
    const url = `/apartments/${id}/`;
    const data = { headers: { Authorization: token } };
    return await fetcher(url, 'delete', data);
};

export const getInfinityHouse = async (page, id) => {
    let url = `/apartments/?page=${page}&size=10`;
    const result = await fetcher(url);
    return result;
};

export const getAllHouseFilterSearch = async (data) => {
    let url;
    console.log("params", data);
    url = '/apartments/';
    if (data){
        url = '/apartments/?';
        for (let key in data) {
            if (data[key]) {
                    url += `${key}=${data[key]}`;
                    url += '&';
                }
            }
        if (data['page']) {
            url += `&page=${data.page}&size=10`;
        }
        
        const result = await fetcher(url);
        return result;
    } else {
        return [];
    }
};

export const getInfinityHouseFilterSearch = async (page, data) => {
    let url;
    if (typeof data === "string" || typeof data === "number") {
        url =`/apartments/?search=${data ? data: ''}&page=${page}&size=10`
    } else {
        url = `/apartments/?location=${data.location}&peopleCount=${data.peopleCount}&minPrice=${data.minPrice}&maxPrice=${data.maxPrice}&startDate=${data.startDate}&endDate=${data.endDate}&page=${page}&size=10`;
    }
    
    return await fetcher(url);
};

export const getOrder = async (token) => {
    const url = '/order/?page=1&size=10';
    const res = await fetcher(url, 'get', null, { Authorization: token });
    return res;
};

export const getInfiniteFilterSearch = async (domain, page, data, token) => {
    let url;

    if (typeof data === "string" || typeof data === "number") {
        // 일반 텍스트 검색인 경우
        url =`/${domain}/?search=${data}&page=1&size=10`
    } else {
        // 필터 검색
        url = data ? `/${domain}/?search=${data?.search ? data.search : ''}&address=${data?.address? data.address:''}&year=${data?.year? data.year: ''}&minPrice=${data?.minPrice ? data.minPrice : ''}&maxPrice=${data?.maxPrice ? data.maxPrice : ''}&startDate=${data.startDate ? data.startDate : ''}&endDate=${data.endDate ? data.endDate : ''}&roomsize=${data.roomsize ? data.roomsize : ''}&style=${data.style ? data.style : ''}&space=${data.space ? data.space : ''}&keyword=${data.keyword ? data.keyword : ''}&price=${data.price ? data.price : ''}&page=1&size=10` : `/order/?page=${page}&size=10`;
    }
    return await fetcher(url, 'GET', null, token);
};

// location=${data.filter.location}&peopleCount=${data.filter.peopleCount}&minPrice=${data.filter.minPrice}&maxPrice=${data.filter.maxPrice}&startDate=${data.filter.startDate}&endDate=${data.filter.endDate}&page=0&size=10&sortBy=id&isAsc=false&userId=${data.id}`

// export const getSearchHouses = (data) => {
//   let res = "";
//   if (data.id) {
//     res = axios.get(
//       `/apartments/apartments?location=${data.filter.location}&peopleCount=${data.filter.peopleCount}&minPrice=${data.filter.minPrice}&maxPrice=${data.filter.maxPrice}&startDate=${data.filter.startDate}&endDate=${data.filter.endDate}&page=0&size=10&sortBy=id&isAsc=false&userId=${data.id}`
//     );
//   } else {
//     res = axios.get(
//       `/apartments/apartments?location=${data.filter.location}&peopleCount=${data.filter.peopleCount}&minPrice=${data.filter.minPrice}&maxPrice=${data.filter.maxPrice}&startDate=${data.filter.startDate}&endDate=${data.filter.endDate}&page=0&size=10&sortBy=id&isAsc=false`
//     );
//   }
//   return res;
// };

export const getTotalTags = async (id) => {
    const res = await fetcher(`/tagTypes/byHome?homeId=${id}`);
    return res;
};

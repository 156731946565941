import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useQuery } from 'react-query';
import { getFavTestResult } from 'utils/api/api';
import { useResetRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { selectedCheckboxesState, PreviousImagesState } from "../../store/atoms";
import { PiMagnifyingGlassPlusBold } from "react-icons/pi";
import RadarChart from "../../element/RadarChart";

// Components
import AppDown from "components/mobile/AppDown";
import CatSpinner from 'components/CatSpinner';

// elements
import BeigeButton from 'element/BeigeButton';
const Contents = styled.div`
    
    display: flex;
    width: 100%;
    heigiht: auto;
    @media ${props => props.theme.mediaQuery.desktop} {
      margin-top: 8rem;
    }
    @media ${props => props.theme.mediaQuery.tablet} {
      margin-top: 8rem;
    }
    @media ${props => props.theme.mediaQuery.phone} {
      margin-top: 0;
    }
`;  

const TasteSection = styled.section`
    display: flex;
    width: 100%;
    flex-direction: column;
    // height: calc(100vh - 8rem);
    height: 100%;
    flex-direction: column;
    flex-wrap: wrap;
`;

const ImageContainer = styled.div`
  display: flex;
`;

const Tasteh1 = styled.h1`
    width: 100%;
    font-size: 22px;
    font-weight: 400;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 2rem 0;
`;
   
const BtnDiv = styled.div`
    display: flex;
    justify-content: center;
    padding: 3rem 0;
    width: 100%;
`;


const PreBtn = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: 500;
    width: 100%;
    height: 5rem;
    background-color: white;
    border: 1px solid black;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #747C87;

    & svg {
      margin-right: 1rem;
      color: black;
    }
`;

const TextDiv  = styled.div`
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 5rem 5rem 5rem;

    p {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      line-height: 1.5;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1.5;
      color: black;
    }
`;

const ImageScrollView = styled.div`
    display: flex;
    position: absolute;
    bottom: 3rem;
    right:0;
    transform: translateX(-10%);
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;

    & img {
        width: 18rem;
        height: 18rem;
        object-fit: cover;
        border-radius: 1rem;
        cursor: pointer;
    }
  `;

const SplitSection = styled.section`
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: space-between;

`;

const ImageWall = styled.div`
    display: flex;
    positoin: relative;
    flex: 1.7;
    flex-wrap: wrap;
    

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
    }
`;


const AppDownFrame = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    @media ${props => props.theme.mediaQuery.phone} {
      position: relative;
    }
`;

const Thumbnail = styled.img`
    display: flex;
    // height: 100%;
    height: 50rem;
    width: auto;
    object-fit: cover;
`;

const Blank = styled.div`
    display: flex;
    width: 100%;
    height: ${props => props.height ? props.height : '20rem'};
`;

const RadarWrapper = styled.div`
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    max-height:100%;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.4);
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 0 2.5rem;
`;
const Text = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 0;
    gap: 0.8rem;

    .title {
        color: #222222;
        font-family: Pretendard;
        font-size: 1.3rem;
        font-weight: regular;
        text-align: center;
    }
    .content {
        color: #222222;
        font-family: Pretendard;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
    }
`;


const ServiceSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    gap: 10rem;
    padding: 0 0 10rem 0;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 2.5rem;
    gap: 0.8rem;
    .bodycontent {
        color: #222222;
        font-family: Pretendard;
        font-size: 1.8rem;
        font-weight: regular;
        text-align: center;
        white-space: pre-line;
    }
`;

const ColorBall = styled.div`
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 2px solid #E0D7C0;
    background-color: ${(props) => props.color};
    z-index: 1;
    ${props => props.style};
`;

const ImageGridWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.color};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.8rem;
    padding: 2rem;
    img {
        width: 100%;
        height: auto;
        border-radius: 0;
    }
`;


const CardMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding: 0rem 2.4rem;
`;

const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 29.7rem;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 0.8rem;
`;

const PromotionTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: left;
    padding: 2.5rem 0 1.8rem 0;

    .content {
        color: #232120;
        font-size: 2.8rem;
        font-weight: 900;
        font-family: Pretendard;
        line-height: 110%;
        letter-spacing: 0%;
        text-align: left;
    }
    .title {
        padding: 0.5rem 0;
        color: #232120;
        font-size: 1.4rem;
        font-weight: 500;
        font-family: Pretendard;
        text-align: left;
    }
`;

const SelectResult = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width: 440px)');
  const token = localStorage.getItem('token');
  const [ metaData, setMetaData ] = useState({});
  const [ ResultImages, setResultImages ] = useState([]);
  const [ resultStyle, setResultStyle ] = useState('');
  const resetSelectedCheckboxes = useResetRecoilState(selectedCheckboxesState);
  const resetPreviousImages = useResetRecoilState(PreviousImagesState);

  // const styles = ['pop', 'modern', 'nordic', 'classic', 'natural', 'vintage'];
  resetSelectedCheckboxes();
  resetPreviousImages();

  const { data: favtestresult, isError, error } = useQuery('favtestresult', async () => {
    return await getFavTestResult(id, token); 
  }, {
    retry: false,
  });

  const handleImageClick = (index) => {
    console.log("index", index)
  };

  const thumbnailRef = useRef();
  const [thumbnailDimensions, setThumbnailDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isError) {
      console.log(error);
    }
    console.log("factestresult", favtestresult)
    const results_style = favtestresult?.data?.results_style.toUpperCase();
    const images = favtestresult?.data?.results_pics;
    const meta = favtestresult?.data?.results_meta;
    setMetaData(meta);
    setResultImages(images);
    setResultStyle(results_style);
  },[isError, error, favtestresult]);

  if (!metaData && !ResultImages && !resultStyle) {
    return <CatSpinner />
  }
  
  const ImageGrid = ({ image, index, handleImageClick }) => {
    return (
      <ImageContainer>
        <img
          src={image}
          alt={index}
          onClick={() => handleImageClick(index)}
        />
      </ImageContainer>
    );
  };
  
  const ImageGridTag = ({ color, portfolioimages }) => {
    console.log(portfolioimages);
    return (
        <ImageGridWrapper className='ImageGrid' color={color}>
            {portfolioimages.map((image, index) => (
                <img src={image.images} alt={`portfolioimage${index}`} key={index} />
            ))}
        </ImageGridWrapper>
    )
  };
  const Apply = () => {
    return (
        <>
        <CardMenuWrapper>
            <CardItem>
                <PromotionTextWrapper>
                    <div className="content">해당 스타일로 <br />상담을 받아보세요!</div>
                </PromotionTextWrapper>
                <BeigeButton className='service1button' position={'center'} width={'100%'} height={'5rem'} fontsize={'2rem'} fontweight={'900'} margintop={'0'} bcolor={'#F3F0E7'} radius={'0'}>상담하기</BeigeButton>
                {/* <BlackButton position={'center'} fontWeight={'600'} fsize={'2rem'} bradius={'0'} width={'29.7rem'} height={'6.4rem'} fontFamily={'inherit'} >무료 체험하기</BlackButton> */}
            </CardItem>
        </CardMenuWrapper>
        </>
    );
  }

  return (
    <>
      <Contents>
        {/* 모바일 */}
        {matches ? (
          <TasteSection>
              <AppDownFrame className="appframe">
                <RadarWrapper 
                  className="radarwrap"
                  style={{ 
                    width: `${thumbnailDimensions.width}px`,
                    height: `${thumbnailDimensions.height}px`
                  }}
                >
                  <RadarChart metaData={metaData} />
                </RadarWrapper>
                <Thumbnail
                  ref={thumbnailRef} 
                  src={ResultImages?.[0]?.images} 
                  alt={ResultImages?.[0]?.id}
                  onLoad={() => {
                    if (thumbnailRef.current) {
                      setThumbnailDimensions({
                        width: thumbnailRef.current.offsetWidth,
                        height: thumbnailRef.current.offsetHeight,
                      });
                    }
                  }}
                >
                </Thumbnail>
                <AppDown title="당신의 인테리어 스타일은?" content={resultStyle + " STYLE"} backgroundColor={'#E0D7C0'}/>
                <Blank height={'15rem'}/>
              </AppDownFrame>
              <ServiceSection>
                <Header className='serviceheader'>
                    <Text>
                        <div className="title">{ResultImages?.[0]?.title}</div>
                        <div className="content">{ResultImages?.[0]?.sub_title}</div>
                    </Text>
                </Header>
                <Body>
                    <div className="bodycontent">“<br/>
                      {ResultImages?.[0]?.content}
                      <br/>
                    ”
                    </div>
                </Body>
                <ColorBall color={'#E0D7C0'} />
                <ImageGridTag color={"#E0D7C0"} portfolioimages={ResultImages} />
                <Apply />
              </ServiceSection>
          </TasteSection>
        ) : (
            <TasteSection>
              <RadarChart metaData={metaData} />
              <SplitSection>
                <ImageWall>
                    <img
                      src={ResultImages?.[0]?.images}
                      alt={ResultImages?.[0]?.id}
                    />
                </ImageWall>
                <TextDiv>
                  <Tasteh1>“Home sweet home”</Tasteh1>
                  <p style={{fontWeight:'700', fontSize:'29px'}}>NORDIC STYLE</p>
                  <br />
                  <p style={{fontWeight:'700'}}>편안한 즐거움으로 소소한 일상의 행복을 줄 수 있는 기능적이고 아늑한 스타일을 추천합니다.</p>
                  <br />
                  <p style={{fontSize:'15px'}}>가족과 함께하는 시간, 따뜻한 차와 과자, 포근한 분위기와 같은 일상적인 즐거움에 중점을 둔 공간입니다. 우드, 패브릭과 같은 자연소재는 밝고 아늑한 분위기를 만들어 줍니다. 따뜻한 뉴트럴톤을 베이스컬러로 하며 부드러운 파스텔 톤의 컬러를 포인트컬러로 매치하면 생동감 있는 공간으로 연출이 가능합니다. 미니멀한 디자인의 기능적인 가구를 배치하여 편안하고 실용적인 공간으로 구성해보세요.</p>
                  <BtnDiv>
                    <PreBtn onClick={() => navigate('/fav')}>
                      <PiMagnifyingGlassPlusBold style={{ fontSize: '20px' }} />
                      공간별 진단
                    </PreBtn>
                  </BtnDiv>
                  <ImageScrollView className="Grid">
                    { ResultImages?.slice(1).map((image) => {
                      return (
                        <ImageGrid
                          key={image.id}
                          image={image.images}
                          index={image.id}
                          handleImageClick={handleImageClick}
                        />
                      );
                      })
                    }
                  </ImageScrollView>
                </TextDiv>
              </SplitSection>
            </TasteSection>
        )}
      </Contents>
    </>
  );
};

export default SelectResult;


import React, { useRef, useContext, useState } from 'react';
import { AuthContext } from "utils/func/AuthContext";
import styled from 'styled-components';
import { AiOutlineRight } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IoMdClose } from 'react-icons/io';

import { modalVariants, mobileNavVariants } from 'utils/animation/variants';
import { boxBorderRadius,  flexRowCenter } from 'utils/style/mixins';
import { isMyPageModalState, userNamePersistState, globalUserInfoState } from 'store/atoms';

import {
    useRecoilValue,
    useSetRecoilState,
} from "recoil";
import profileicon from "assets/mobile/mypage/profileicon.png";
import StrokeLine from 'element/StrokeLine';

const ModalWrapper = styled.div`
    z-index: 999;
    position: fixed;
    ${flexRowCenter};
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(10, 10, 10, 0.35);
    h1 {
        font-size: 1.6rem;
    }
`;

const ModalLayout = styled.div`
    position: relative;
    width: 60rem;
    height: 66rem;
    ${boxBorderRadius}
    background-color: white;
    display: flex;
    flex-direction: column;
    padding:  5rem;
    
    div.content {
        padding: 2rem;
        text-align: left;
    }
    h2 {
        line-height: 1.25;
        font-size: 4rem;
        font-weight: 900;
        letter-spacing: -0.3rem;
        margin-bottom: 4rem;
    }
    div.category {
        display: flex;
        flex-direction: column;
        gap: 3.5rem;
    }
    div.title {
        
    }
`;

const MobileModalLayout = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
`;
const DropdownMenu = styled.div`
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    font-size: 2rem;
    font-weight: 500;
    font-family: 'Pretendard';
    text-decoration: none;
    color: black;
`

const DropdownMenuItem = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
    cursor: pointer;
`;

const ModalSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 4rem;
`;

const HeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: fit-content;
    padding: 0 1.6rem;
`;

const ProfileWrapper = styled.div`
    width: 100%;
    height: 6.5rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
`;


const CloseBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 6rem;
    color: black;
    cursor: pointer;
`;

const IconWrapper = styled.div`
    width: 6.5rem;
    height: 6.5rem;
    display: flex;
    flex-direction: row;
`;


const ProfileLinkWrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 1rem;
    span {
        color :#CDB36E;
        font-family: "Noto Sans KR";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
    };
`;

const Profile = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    .email { 
        display: flex;
        width: 100%;
        text-align: left;
        color: #999;
        font-family: "Noto Sans KR";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
`;

const Greetings = styled.span`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 2rem;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0.3px;
`;

const Editbutton = styled.button`
    width: 10rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #E0D7C0;
    border-radius: 3px;
    cursor: pointer;
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10rem;
        height: 2.5rem;
        font-size: 1.4rem;
        font-family: "Noto Sans KR";
        font-style: normal;
        font-weight: 500;
        color: #ffffff;
    }
`;
const ProfileSection = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 3rem;
`;

const BodyWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 0 3rem;
`;

const ColButtons = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-family: "Noto Sans KR";
    color: black;
    font-weight: 800;
`;

const FooterButton = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    margin-top: auto;

    align-items: center;
    background-color: #E0D7C0;
    justify-content: center;
    font-size: 2rem;
    letter-spacing: 0.4px;
    font-family: "Noto Sans KR";
    font-weight: 800;
    color: black;
    padding: 2rem 0;
`;


const MyPageModal = () => {
    const matches = useMediaQuery('(max-width: 1015px)');
    const isVisibleMyPageModal = useSetRecoilState(isMyPageModalState);
    const { logoutClear } = useContext(AuthContext);
    const UserName = useRecoilValue(userNamePersistState);
    const GlobalUserInfo = useRecoilValue(globalUserInfoState);
    
    // const setIsUser = useSetRecoilState(isUserState);
    const navigate = useNavigate();
    const modalRef = useRef(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const hanleTitleClick = () => {
        setDropdownVisible(!dropdownVisible);
    }

    // const terminateClick = () => {
    //     alert("회원탈퇴")
    //     console.log("회원탈퇴")
    // }
    
    const toggleOverlay = () => {
        // OFF
        isVisibleMyPageModal(false);
    };

    const handlenavigate = (path) => {
        navigate(path);
        toggleOverlay();
    }

    return (
        <>
        <ModalWrapper
            ref={modalRef}
            onClick={(e) => {
                if (modalRef.current === e.target) {
                    isVisibleMyPageModal(false);
                }
            }}
        >
        {matches ? ( // 모바일 화면
            <MobileModalLayout variants={mobileNavVariants} initial="start" animate="animate" >
                <ModalSection>
                    <HeaderWrapper className='header'>
                        <CloseBox>
                            <IoMdClose size={32} onClick={toggleOverlay} />
                        </CloseBox>
                    </HeaderWrapper>
                    <ProfileSection>
                        <ProfileWrapper>
                            <IconWrapper>
                                <img src={profileicon} alt="프로필아이콘" />
                            </IconWrapper>
                            <Profile>
                                <ProfileLinkWrapper>
                                    <span>Profile</span>
                                    <AiOutlineRight size={15} color={"#CDB36E"} alt="오른쪽표시" />
                                </ProfileLinkWrapper>
                                <span className='email'>{GlobalUserInfo?.email}</span>
                            </Profile>
                        </ProfileWrapper>
                        <Greetings>
                            {UserName} 님 환영합니다.
                        </Greetings>
                        <Editbutton><span>계정 정보 수정</span></Editbutton>
                    </ProfileSection>
                    <BodyWrapper>
                        <StrokeLine Linecolor={'#E0D7C0'} width={'100%'} position={'center'} marginTop={'0'} height={'2px'}></StrokeLine>
                        <ColButtons onClick={() => handlenavigate('/')}>홈</ColButtons>
                        <ColButtons onClick={() => handlenavigate('/portfolio') }>포트폴리오</ColButtons>
                        <ColButtons onClick={() => handlenavigate('/createmyhome')}>우리집 만들기</ColButtons>
                    </BodyWrapper>
                    <FooterButton>상담 신청</FooterButton>
                </ModalSection>
            </MobileModalLayout>
        ) :
        ( // 데스크탑 화면
            <ModalLayout variants={modalVariants} initial="start" animate="animate" >
                <div className='content'>
                    <h2>
                        <span style={{'fontSize': '4rem','fontWeight':'900', 'white-space':'nowrap'}}>{UserName} </span>
                    님.
                    </h2>
                    <span>{}</span>
                    <div className='category'>
                    {/* <DropdownMenu className='title'>내 질문/답변 <AiOutlineRight size={15} color={"black"} alt="오른쪽표시" /></DropdownMenu> */}
                    {/* <DropdownMenu 
                        as="a"
                        href="/orders"
                        className='title'
                    >
                    내 결제내역 <AiOutlineRight size={15} color={"black"} alt="오른쪽표시" /></DropdownMenu> */}
                    <DropdownMenu 
                        className='title' 
                        onClick={hanleTitleClick}
                    > 계정 설정 <AiOutlineRight size={15} color={"black"} alt="오른쪽표시" />
                        {dropdownVisible && (
                            <>
                            <DropdownMenuItem onClick={logoutClear}>로그아웃</DropdownMenuItem>
                            </>
                        )}
                    </DropdownMenu>
                    </div>
                </div>
            </ModalLayout>
        )}
        </ModalWrapper>
        
        </>
    );
};

export default MyPageModal;

import React from 'react'
import styled from 'styled-components'

//images
import GridSection from 'assets/mobile/GridSection.png';
import GridSection2 from 'assets/mobile/GridSection2.png';


const ServiceSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: top-center;
`;
const Text = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 0rem;
    gap: 0.8rem;

    .title {
        color: #222222;
        font-family: 'Pretendard';
        font-size: 1.3rem;
        font-weight: regular;
        text-align: center;
    }
    .content {
        color: #222222;
        font-family: 'Pretendard';
        font-size: 2rem;
        font-weight: 900;
        text-align: center;
        line-height: 110%;
        white-space: nowrap;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 9rem;
    height: fit-content;
    justify-content: center;
    align-items: center;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const CardMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 1.6rem;
    
`;

const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    
    height: 100%;
    justify-content: center;
    align-items: center;

    img {
    
        width: 85%;
        max-width: 37.5rem;
        height: auto;
        padding: 2.4rem 0;
    }
`;

const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    gap: 1.6rem;
    .left {
        width: 100%;
        height: auto;
        align-self: flex-start;
    }
    .right {
        width: 100%;
        height: auto;
        align-self: flex-end;
    }
`;


const CardMenu = () => {
    return (
        <>
        <ImageWrapper><img src="https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/phone.png" alt='altimg'></img></ImageWrapper>
        <CardMenuWrapper>
            <CardItem>
                <GridContainer>
                    <img src={GridSection} alt='altimg' className='left' />
                    <img src={GridSection2} alt='altimg' className='right' />
                </GridContainer>
            </CardItem>
        </CardMenuWrapper>
        </>
    );
}


const Threed = () => {
    
    return (
        <>
        <ServiceSection>
            <Header className='service1header'>
                <Text>
                    <div className="title">홈스타일링 / 인테리어</div>
                    <div className="content">SAMPLE</div>
                </Text>
            </Header>
            <Body className='service1body'>
                <BodyWrapper>
                    <CardMenu/>
                </BodyWrapper>
            </Body>
        </ServiceSection>
        </>
    );
}

export default Threed;
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';

const DropDownWrapper = styled.div`
  display: flex;
  width: 100%;

  .dropdownmenus {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.9rem;
  }

  #dropdown-item-button {
    background-color : white;
    color: black;
    width: 10.7rem;
    height: 5rem;
    border: 1px solid #747C87;
    border-radius: 4px;
    font-size: 1.6rem;
    font-weight: regular;
    font-family: Sqoqa Han Sans Neo;
    &:hover {
      background-color: #747C87;
      color: white;
    }
  }

  .dropdown-menu {
    width: 10.7rem;
    height: fit-content;
    border: 1px solid #747C87;
    border-radius: 4px;
    .dropdown-item {
      width: 10.7rem;
      height: fit-content;
      font-size: 1.6rem;
      font-weight: regular;
      font-family: Sqoqa Han Sans Neo;
      overflow-y: scroll;
      &:hover {
        background-color: #747C87;
        color: white;
      }
  }
`;

const DropdownButtonElem = ({selectedVal, setSelectedVal}) => {
  
  const handleClick = (e) => {
    setSelectedVal(e.target.innerText);
  }
  return (
    <DropDownWrapper>
      <Dropdown className='dropdownmenus'>
        <DropdownButton id="dropdown-item-button" title={selectedVal ? selectedVal : '성별'}>
          <Dropdown.Item as="div" onClick={handleClick}>여</Dropdown.Item>
          <Dropdown.Item as="div" onClick={handleClick}>남</Dropdown.Item>
        </DropdownButton>
      </Dropdown>
    </DropDownWrapper>
  );
}

export default DropdownButtonElem;
import styled from "styled-components";
import {
  SkeletonElementTextWrapperStyle,
  SkeletonElementWrapperStyle,
} from "utils/style/mixins";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonElementWrapper = styled.li`
  ${SkeletonElementWrapperStyle}
  
`;

const SkeletonElementTextWrapper = styled.ul`
  ${SkeletonElementTextWrapperStyle}
`;

export const SkeletonMapElement = () => {
  return (
    <SkeletonElementWrapper>
      <Skeleton height={270} />
      <SkeletonElementTextWrapper>
        <header className="apartment-header">
          <Skeleton className="title"/>
        </header>
        <p>
          <Skeleton height={15} />
          <Skeleton width={60} height={15} />
        </p>
        <Skeleton className="address" width={50} />
      </SkeletonElementTextWrapper>
    </SkeletonElementWrapper>
  );
};

export const SkeletonSecondSectionWrapper = () => {
  return (
    <SkeletonElementWrapper>
      <Skeleton height={30} />
      <SkeletonElementTextWrapper>
        <header className="apartment-header">
          <Skeleton className="title"/>
        </header>
        <p>
          <Skeleton height={15} />
          <Skeleton width={60} height={15} />
        </p>
        
        <Skeleton className="address" width={50} />
      </SkeletonElementTextWrapper>
      <SkeletonElementTextWrapper>
        <header className="apartment-header">
          <Skeleton className="title"/>
        </header>
        <p>
          <Skeleton height={15} />
          <Skeleton width={60} height={15} />
        </p>
        
        <Skeleton className="address" width={50} />
      </SkeletonElementTextWrapper>
      <SkeletonElementTextWrapper>
        <header className="apartment-header">
          <Skeleton className="title"/>
        </header>
        <p>
          <Skeleton height={15} />
          <Skeleton width={60} height={15} />
        </p>
        
        <Skeleton className="address" width={50} />
      </SkeletonElementTextWrapper>
    </SkeletonElementWrapper>
  );
};
import styled from "styled-components";
import { getApplyList } from 'utils/api/api';
import { useQuery } from 'react-query';
import {  useState } from 'react';
import CatSpinner from 'components/CatSpinner';

import { getCookie } from 'utils/cookie/cookie';
import Pagination from '@mui/material/Pagination';

const MainContentWrapper = styled.section`
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

const Card = styled.div`
  
  overflow: hidden;

`;

const CardHeader = styled.div`
  background-color: transparent;
  padding: 16px;
`;

const CardTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #343a40;
`;

const CardContent = styled.div`
  padding: 16px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  background-color: #f8f9fa;
`;

const TableHeaderCell = styled.th`
  padding: 12px;
  text-align: left;
  font-weight: 600;
  border-bottom: 1px solid #e9ecef;
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
 
`;
const TableCell = styled.td`
  padding: 12px;
  border-bottom: 1px solid #e9ecef;
`;
const PaginationSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const Apply = () => {
    const [pageNm, setPageNm] = useState(1);
    const token = getCookie('jwt');
    const { data: applyList, isError, error, refetch } = useQuery('applyList', () => getApplyList(pageNm, token), {
      retry: false, // Disable automatic retry
    });
    if (isError) {
      return (
        <MainContentWrapper>
        <div>
          <p>Error: {error.message}</p>
        </div>
        </MainContentWrapper>
      );
    }
    // Access memblist.data
    const applyData = applyList?.data?.results;
    const numPages = applyData?.num_pages;

    const handlePageChange = (e,value) => {
      console.log("PAGE", value, "e", e);
      setPageNm(value);
      refetch();
    }
    return (
        <MainContentWrapper>
        { applyData ? (
        <Card>
            <CardHeader>
            <CardTitle>상담신청건</CardTitle>
            </CardHeader>
            <CardContent>
            <Table>
                <TableHeader>
                <TableRow>
                    <TableHeaderCell>성함</TableHeaderCell>
                    <TableHeaderCell>SNS 채널</TableHeaderCell>
                    <TableHeaderCell>핸드폰번호</TableHeaderCell>
                    <TableHeaderCell>아파트</TableHeaderCell>
                    <TableHeaderCell>주소</TableHeaderCell>
                    {/* Add more header cells as needed */}
                </TableRow>
                </TableHeader>
                <TableBody>
                { applyData.map((obj) => (
                    <TableRow key={obj.user?.email}>
                      <TableCell>{obj.name}</TableCell>
                      <TableCell>{obj.user?.provider}</TableCell>
                      <TableCell>{obj.phone}</TableCell>
                      <TableCell>{obj.apartment}</TableCell>
                      <TableCell>{obj.address}</TableCell>
                    </TableRow>
                ))}
                </TableBody>

            </Table>
            <PaginationSection>
            <Pagination
                count={numPages}
                onClick={handlePageChange}
            />
            </PaginationSection>

            </CardContent>
        </Card>
        ) : (
          <CatSpinner />
        )}
    </MainContentWrapper>
    );
}

export default Apply;
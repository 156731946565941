
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { mobileNavVariants } from 'utils/animation/variants';
import defaultprofile from 'assets/mobile/mypage/defaultprofile.png';
import StrokeLine from 'element/StrokeLine';
import { useState } from 'react';

const MobileModalLayout = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: space-between;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: none;
`;

const ModalSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4rem;
    flex-grow: 1;
`;

const MobileHeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: fit-content;
    padding: 0 1.6rem;
`;

const ProfileWrapper = styled.div`
    width: 100%;
    height: 6.5rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
`;

const MobileCloseBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 6rem;
    color: black;
    cursor: pointer;
`;

const IconWrapper = styled.div`
    width: 6.5rem;
    height: 6.5rem;
    display: flex;
    flex-direction: row;
`;

const Greetings = styled.span`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 2rem;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0.3px;
`;

const Editbutton = styled.button`
    width: 10rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: ${(props) => props.backgroundcolor };
    border-radius: 3px;
    
    border: ${(props) => props.strokeWidth && props.strokeColor ? props.strokeWidth + ' solid ' + props.strokeColor : 'none' }; 
    cursor: pointer;
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10rem;
        height: 2.5rem;
        font-size: 1.5rem;
        font-family: "Noto Sans KR";
        font-style: normal;
        font-weight: 600;
        color: ${(props) => props.color ? props.color : 'white'};
    }
`;
const ProfileSection = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 3rem;
`;

const MobileBodyWrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 0 3rem;
`;

const ColButtons = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-family: "Noto Sans KR";
    color: black;
    font-weight: 800;
`;

const FooterButton = styled.div`
    width: 100%;
    height: 6.1rem;
    display: flex;
    box-sizing: border-box;
    
    align-items: center;
    background-color: #E0D7C0;
    justify-content: center;
    font-size: 2rem;
    letter-spacing: 0.4px;
    font-family: "Noto Sans KR";
    font-weight: 800;
    color: black;
`;

const ButtonWrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;

const FooterButtonSection = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: auto;
`;

const MobileLoginForm = ({toggleOverlay, renderForm, setIsSignUp, Signup, onClick }) => {
    const navigate = useNavigate();
    const onClickHandler = (url) => {
        navigate(url);
        onClick();
    }
    const [isLoginSignup, setLoginSignup] = useState(false);

    const onClickLoginHandler = () => {
        // setIsSignUp(true);
        setLoginSignup(true);
        renderForm();
    }

    return (
        <MobileModalLayout className='loginmodal' variants={mobileNavVariants} initial="start" animate="animate" >
            {isLoginSignup ? renderForm() :
            <ModalSection>
                <MobileHeaderWrapper className='header'>
                    <MobileCloseBox>
                        <IoMdClose size={32} onClick={toggleOverlay} />
                    </MobileCloseBox>
                </MobileHeaderWrapper>
                <ProfileSection>
                    <ProfileWrapper>
                        <IconWrapper>
                            <img src={defaultprofile} alt="프로필아이콘" />
                        </IconWrapper>
                    </ProfileWrapper>
                    <Greetings>
                        로그인 하시고 ~를 이용해보세요!
                    </Greetings>
                    <ButtonWrapper>
                        <Editbutton onClick={onClickLoginHandler} backgroundcolor={'#E0D7C0'}><span>로그인</span></Editbutton>
                        <Editbutton onClick={onClickLoginHandler} color={'#E0D7C0'} backgroundcolor={'#ffffff'} strokeColor={'#E0D7C0'} strokeWidth={'1px'}><span>회원가입</span></Editbutton>
                    </ButtonWrapper>
                </ProfileSection>
                <MobileBodyWrapper>
                    <StrokeLine Linecolor={'#E0D7C0'} width={'100%'} position={'center'} marginTop={'0'} height={'2px'}></StrokeLine>
                    <ColButtons onClick={() => onClickHandler('/')}>홈</ColButtons>
                    <ColButtons onClick={() => onClickHandler('/portfolio')}>포트폴리오</ColButtons>
                    <ColButtons onClick={() => onClickHandler('/createmyhome')}>우리집 만들기</ColButtons>
                </MobileBodyWrapper>
                <FooterButtonSection>
                    <FooterButton>상담 신청</FooterButton>
                </FooterButtonSection>
            </ModalSection>
            }
        </MobileModalLayout>
    )
}

export default MobileLoginForm;
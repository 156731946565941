import React, { useState } from "react";
import styled from "styled-components";
import useMediaQuery from '@mui/material/useMediaQuery';
// import {SkeletonListElement} from '../element/SkeletonListElement';

// comps
import Bigwall from './../components/Bigwall';

// elems
import DropDownIcon from "element/DropDownIcon";
import DropUpIcon from "element/DropUpIcon";

// images
import aticopay from '../assets/aticopay.png';
import aticoplus from '../assets/aticoplus.png';
import aticostay from '../assets/aticostay.png';
import aticostyling from '../assets/aticostyling.png';
import aticosnap from '../assets/aticosnap.png';


const HeadSectionContainer  = styled.div`
    height: auto;
`;

const BodySectionContainer = styled.div`
    padding-top: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: auto;
    gap: 15rem;
`;


const MainBodyTitle = styled.div`
    display: flex;
    justify-content: center;
    font-size: 4rem;
    font-weight: 900;
    font-family: Notosans KR;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: center;
    justify-content: center;
    gap: 2rem;
`;


const SmallTitle = styled.h1`
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 400;
    font-family: Playfair Display;
    color: #999999;
    height: fit-content;
    letter-spacing: 1.6px;
`;

const H2Title = styled.h2`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4.8rem;
    font-weight: 700;
    font-family: Noto Sans KR;
    color: black;
`;

const BodyFeatures = styled.section`
    display: flex;
    position: relative;
    flex-direction: rows;
    // max-width: 1400px;
    align-items: center;
    justify-content: center;
    
    padding: 0 12rem;
    gap: 5rem;
    margin-top: 10rem;
    margin-left: auto;
    margin-right: auto;
`;

const Blank = styled.div`
    display: flex;
    width: 100%;
    height: ${(props) => props.height ? props.height : '20rem'};
`;

const ColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-items: center;
    justify-content: center;
    

    @media ${props => props.theme.mediaQuery.desktop} {
        img {
            width:100%;
            height:100%;
            margin-top: 2rem;
        }
    };

    @media ${props => props.theme.mediaQuery.tablet} {
        img {
            width:100%;
            height:100%;
        }
    };

    @media ${props => props.theme.mediaQuery.phone} {
        width: 100vw;
        height: fit-content;
    };
`;


const ServiceColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-items: center;
    justify-content: center;
    

    @media ${props => props.theme.mediaQuery.desktop} {
        img {
            width:16rem;
            height:16rem;
            margin-top: 2rem;
        }
    };

    @media ${props => props.theme.mediaQuery.tablet} {
        img {
            width:100%;
            height:100%;
        }
    };

    @media ${props => props.theme.mediaQuery.phone} {
        width: 100vw;
        height: fit-content;
    };
`;

const Title = styled.span`
    display: flex;
    flex-direction: rows;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 2.4rem;
    font-weight: 800;
    font-family: Pretendard;
    color: black;
    height: fit-content;
`;

const SpanTitle = styled.span`
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 2.4rem;
    font-weight: 600;
    font-family: Pretendard;
    color: #000;
`;

const SpanContent = styled.span`
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 300;
    font-family: Noto Sans KR;
    color: #616161;
`;

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    text-underline-position: under;
    cursor: pointer;
    color: #616161;
    font-size: 1.5rem;
    font-weight: 300;
    margin-top: 1rem;
    background-color: transparent;
`;

const TextWrapper = styled.div`
    display: flex;
    margin-top: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15rem;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const GirdContainer = styled.div`   
    display: flex;
    padding: 0 20rem;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 15rem;
    margin-left: auto;
    margin-right: auto;
`;

const DropDownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    width: 100%;
    padding: 0 20rem;
    align-items: center;
    justify-content: center;
    gap: 5rem;
`;

const DropDown = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
`;

const DropDownTitle = styled.span`
    display: flex;
    align-items: top;
    justify-content: flex-start;
    height: fit-content;
    color: #000;
    font-family: Pretendard;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 1.6px;
    white-space: nowrap;
`;

const DropDownContent = styled.div`
    
    transition: opacity 0.3s ease, max-height 0.3s ease;
    
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    text-align: left;
    justify-content: left;
`;

const DropDownContentText = styled.span`
    display: flex;
    align-items: center;
    padding: 5rem 0 2rem 0;
    text-align: left;
    justify-content: left;
    font-size: 3rem;
    font-weight: 400;
    font-family: Pretendard;
    color: #000;
    height: fit-content;
`;

const DropDownTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    height: fit-content;
    cursor: pointer;
`;

const TextContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center-left;
    flex-direction: column;
    z-index: 3;
    .titlespan {
        p {
            color : white;
            font-family: Pretendard;
            font-size: 24px;
            font-weight: 300;
            letter-spacing: 5%;
        }
    }
`;

const TopTitle = styled.h1`
    display: flex;
    align-items: center;
    color: white;
    font-family: Pretendard;
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 5%;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 2rem;
`;


const Intro = () => {
    const matches = useMediaQuery('(max-width: 1015px)');
    const [openStates, setOpenStates] = useState({});

    const toggleOpen = (id) => {
        setOpenStates(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const questions = [
        { id: 1, question: '상담을 신청하면 바로 연결되나요?', answer: 'A. 상담 신청 즉시 실시간으로 채팅 상담이 가능합니다.' },
        { id: 2, question: '온라인 상담 외에 방문상담이 가능한가요?', answer: 'A. 사전 연락을 통해 방문상담을 예약할 수 있습니다.' },
        { id: 3, question: '실제로 인테리어 시공을 하지않고 상담만 할 수 있나요?', answer: 'A. 네. 3D모델링부터 상담까지 언제든지 가능합니다.' },
        { id: 4, question: '여러 디자인을 동시에 상담할 수 있나요?', answer: 'A. 여러 디자인을 전문가와 함께 보며 상담할 수 있습니다.' },
        { id: 5, question: '만들어진 3D 모델링을 직접 수정할 수 있나요?', answer: 'A. 가구 배치부터 마감까지 모든 수정을 직접 할 수 있습니다.'}
    ];

    return (
    <>
      {/* 모바일 */}
      {matches ? (
        < ></>
      ) : (
        <>
        <HeadSectionContainer className="sectioncont">
            <Bigwall backgroundColor={'transparent'} loop={false} autoPlayOnLoad={true} video={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/servicewall.mp4'}>
                <TextContainer>
                    <TopTitle>서비스</TopTitle>
                    <div className="titlespan">
                        <p>
                        메타디자인하우스만의 서비스를 만나보세요.
                        </p>
                    </div>
                    
                </TextContainer>
            </Bigwall>
        </HeadSectionContainer>
        <BodySectionContainer>
            <MainBodyTitle id="ourTechnology" className="bodyTitle">
                <BodyWrapper className="wrapper">
                    <H2Title>구독</H2Title>
                    <SmallTitle>어떤 서비스가 필요하신가요?</SmallTitle>
                </BodyWrapper>
            </MainBodyTitle>
            <BodyFeatures>
                <ColWrapper>
                    <Title>ESSENTIAL</Title>
                    <img src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/Rectangle+3463790+(2).png'} loading="lazy" alt="service1" />
                    <TextWrapper>
                        <SpanTitle>에센셜 서비스</SpanTitle>
                        <SpanContent>기본적인 시공을 위한<br /> 
                        합리적인 선택<Button>더보기</Button>
                        </SpanContent>
                    </TextWrapper>
                </ColWrapper>
                <ColWrapper>
                    <Title>PREMIUM
                    </Title>
                    <img src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/Rectangle+3463790.png'} alt="service2" />
                    <TextWrapper>
                        <SpanTitle>프리미엄 서비스</SpanTitle>
                        <SpanContent>고급스러운 취향의 공간을 위한<br />
                        전문적인 디자인<Button>더보기</Button>
                        </SpanContent>
                    </TextWrapper>
                </ColWrapper>
                <ColWrapper>
                    <Title>KITCHEN
                    </Title>
                    <img src={'https://aticos3.s3.ap-northeast-2.amazonaws.com/frontmedia/Rectangle+3463790+(1).png'} alt="service3" />
                    <TextWrapper>
                        <SpanTitle>주방 전용 서비스</SpanTitle>
                        <SpanContent>주방을 중심으로 한<br />
                        인테리어 변신<Button>더보기</Button>
                        </SpanContent>
                    </TextWrapper>
                </ColWrapper>
            </BodyFeatures>

            <Blank height={'10rem'}/>
            <MainBodyTitle id="ourTechnology" className="bodyTitle">
                <BodyWrapper className="wrapper">
                    <H2Title>혜택</H2Title>
                    <SmallTitle>메타디자인하우스만의 혜택</SmallTitle>
                </BodyWrapper>
            </MainBodyTitle>
            <GirdContainer>
                <RowWrapper>
                    <ServiceColWrapper>
                        <Title>ATICO PAY ’</Title>
                        <img src={aticopay} alt="aticopay" />
                        <TextWrapper>
                            <SpanTitle>무이자 할부</SpanTitle>
                            <SpanContent>'ATICO PAY'로 비용 부담 없이<br/> 
                            인테리어를 시작하세요.
                            </SpanContent>
                        </TextWrapper>
                    </ServiceColWrapper>
                    <ServiceColWrapper>
                        <Title>ATICO PLUS ’</Title>
                        <img src={aticoplus} alt="aticoplus" />
                        <TextWrapper>
                            <SpanTitle>회원가입 혜택</SpanTitle>
                            <SpanContent>'ATICO PLUS'로 회원가입하고<br/>
                                 다양한 혜택을 받으세요.
                            </SpanContent>
                        </TextWrapper>
                    </ServiceColWrapper>
                    <ServiceColWrapper>
                        <Title>ATICO STAY ’</Title>
                        <img src={aticostay} alt="aticostay" />
                        <TextWrapper>
                            <SpanTitle>임시 거주 공간</SpanTitle>
                            <SpanContent>'ATICO STAY'로 거주 중 인테리어<br/> 
                            걱정없이 리모델링을 진행하세요.
                            </SpanContent>
                        </TextWrapper>
                    </ServiceColWrapper>
                </RowWrapper>
                <RowWrapper>
                    <ServiceColWrapper>
                        <Title>ATICO STYLING ’</Title>
                        <img src={aticostyling} alt="aticostyling" />
                        <TextWrapper>
                            <SpanTitle>가구 및 가전 제품 쇼핑</SpanTitle>
                            <SpanContent>'ATICO STYLING'으로 전문 스타일리스트의 <br />
                            추천과 할인 혜택을 누리세요.
                            </SpanContent>
                        </TextWrapper>
                    </ServiceColWrapper>
                    <ServiceColWrapper>
                        <Title>ATICO SNAP ’</Title>
                        <img src={aticosnap} alt="aticosnap" />
                        <TextWrapper>
                            <SpanTitle>특별한 순간 기록</SpanTitle>
                            <SpanContent>'ATICO SNAP'으로 새 집에서의 <br />
                            시작을 아름다운 앨범으로 남기세요.
                            </SpanContent>
                        </TextWrapper>
                    </ServiceColWrapper>
                </RowWrapper>
            </GirdContainer>
            <Blank height={'10rem'}/>
            <MainBodyTitle id="ourTechnology" className="bodyTitle">
                <BodyWrapper className="wrapper">
                    <H2Title>FAQ</H2Title>
                    <SmallTitle>자주 묻는 질문</SmallTitle>
                </BodyWrapper>
            </MainBodyTitle>
            <DropDownWrapper>
                {questions.map(({ id, question, answer }) => (
                    <DropDown key={id}>
                        <DropDownTitleWrapper onClick={() => toggleOpen(id)}>
                            <DropDownTitle>Q.{question}</DropDownTitle>
                            {openStates[id] ? <DropUpIcon /> : <DropDownIcon />}
                        </DropDownTitleWrapper>
                        {openStates[id] && (
                            <DropDownContent>
                                <DropDownContentText>{answer}</DropDownContentText>
                            </DropDownContent>
                        )}
                    </DropDown>
                ))}
            </DropDownWrapper>
            <Blank height={'10rem'}/>
        </BodySectionContainer>
        </>
      )
      }
    </>
  );
};

export default Intro;




import React, { useRef } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { isAdminLoginModalState, isSignUp } from 'store/atoms';
import styled from 'styled-components';
import { modalVariants } from 'utils/animation/variants';
import { boxBorderRadius, flexColumnCenter, flexRowCenter } from 'utils/style/mixins';
import { useForm } from 'react-hook-form';
import AdminLoginForm from './AdminLoginForm';
import AdminSignupForm from './AdminSignupForm';

const ModalWrapper = styled.div`
    z-index: 999;
    position: fixed;
    ${flexRowCenter};
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(10, 10, 10, 0.35);
    h1 {
        font-size: 1.6rem;
    }
`;

const ModalLayout = styled.div`
    position: relative;
    width: auto;
    height: auto;
    ${boxBorderRadius}
    ${flexColumnCenter}
    background-color: white;
    display: flex;
    flex-direction: column;
    padding:  5rem;
`;

const Overlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  display: ${props => props.show ? 'block' : 'none'};
`;

const AdminLoginModal = () => {
    const isVisibleLoginModal = useSetRecoilState(isAdminLoginModalState);
    const isSignUp_ = useRecoilValue(isSignUp);
    const setIsSignUp = useSetRecoilState(isSignUp);
    const { reset } = useForm();
    const modalRef = useRef(null);
   
    const onLoginToggle = () => {
        setIsSignUp(!isSignUp_);
        reset();
    };

    return (
        <>
        <Overlay  onClick={() => isVisibleLoginModal(false)}/>
        <ModalWrapper
            ref={modalRef}
        >
            <ModalLayout variants={modalVariants} initial="start" animate="animate" >
                {isSignUp_ ? <AdminSignupForm onLoginToggle={onLoginToggle}/> : <AdminLoginForm onLoginToggle={onLoginToggle} />}
            </ModalLayout>
        </ModalWrapper>
        </>
    );
};

export default AdminLoginModal;

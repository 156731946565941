import React from "react";
import ReactDOM from "react-dom";
// import { IoMdFlashlight } from "react-icons/io";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

// const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 3,
    },
  },
});

ReactDOM.render(
  //<React.StrictMode>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter >
        <App />
        </BrowserRouter >
      </QueryClientProvider>
    </RecoilRoot>,
  //</React.StrictMode>
  document.getElementById("root")
);
reportWebVitals();

import styled from "styled-components";
import { getFavPicList } from 'utils/api/api';
import { useQuery } from 'react-query';
import {  useState } from 'react';
import CatSpinner from 'components/CatSpinner';
import { getCookie } from 'utils/cookie/cookie';
import Pagination from '@mui/material/Pagination';
import EditModal from 'components/admin/EditModal';
import { isAdminEditModalState } from 'store/atoms';
import AddButton from 'element/AddButton';  
import { useRecoilState } from "recoil";

const MainContentWrapper = styled.section`
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

const Card = styled.div`
  overflow: hidden;

`;

const CardHeader = styled.div`
display: flex;
justify-content: space-between;
  padding: 16px;
`;

const CardTitle = styled.h2`
display: flex;
align-items: center;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #343a40;
`;

const CardContent = styled.div`

`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
border-bottom: 1px solid #e9ecef;
`;

const TableHeaderCell = styled.th`
  padding: 12px;
  text-align: left;
  font-weight: 600;
  
  cursor: pointer;
  active {
    border-bottom: 10px solid #e9ecef;
  }
`;

const TableBody = styled.tbody`
  overflow: auto;

`;

const TableRow = styled.tr`
  
  
`;
const TableCell = styled.td`
  padding: 12px;
  border-bottom: 1px solid #e9ecef;
`;

const TableImageCell  = styled.td`
  padding: 12px;

  img {
    width: 8rem;
    height: 8rem;
    object-fit: cover;
    border-radius: 0.5rem;
    cursor: pointer;
    position: relative;
  }

  img:hover::after {
    content: '바꾸기';
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1f1f1f;
    color: #fff;
    opacity: 0.7;
  }
  
  
`;

const PaginationSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const Favtest = () => {
    const [pageNm, setPageNm] = useState(1);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isVisibleEditModal, setIsAdminEditModalState]  = useRecoilState(isAdminEditModalState);
    const token = getCookie('jwt');
    const { data: favimagelist, isError, error, refetch } = useQuery('favimagelist', () => getFavPicList(pageNm, token), {
      retry: false,
    });
    
    const ImagesData = favimagelist?.data?.results;
    const numPages = favimagelist?.num_pages;

    const handlePageChange = (e,value) => {
        console.log("PAGE", value, "e", e);
        setPageNm(value);
        refetch();
    }

    const handleImageClick = (image) => {
      setIsAdminEditModalState(true);
      setSelectedImage(image);
    };

    const renderMetaCells = (meta) => Object.entries(meta).map(([key, value]) => <TableCell key={key}>{value}</TableCell>);
    const renderRows = () => ImagesData.map((image) => (
      <TableRow key={image.id} >
        <TableImageCell>
          <img alt="img1" src={image.images} onClick={() => handleImageClick(image)}/>
        </TableImageCell>
        {renderMetaCells(image.meta)}
      </TableRow>
    ));

    if (isError) {
      return (
        <MainContentWrapper>
        <div>
          <p>Error: {error.message}</p>
        </div>
        </MainContentWrapper>
      );
    }

    return (
      <>
        <MainContentWrapper>
        { !ImagesData || ImagesData.length === 0 ? (
        <CatSpinner/>
        ) : (
        <Card>
            <CardHeader>
              <CardTitle>취향테스트</CardTitle>
              <AddButton onClick={() => {
                setIsAdminEditModalState(true);
                setSelectedImage(null);
              }}>
                  +이미지추가
              </AddButton>
              </CardHeader>

            <CardContent>
            <Table>
                <TableHeader>
                  <TableRow key="HEAD">
                      <TableHeaderCell >파일명</TableHeaderCell>
                      {
                        Object.keys(ImagesData?.[0].meta).map(key => 
                          <TableHeaderCell key={key}>{key}</TableHeaderCell>
                        )
                      }
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {renderRows()}
                </TableBody>
            </Table>

            <PaginationSection>
              <Pagination
                  count={numPages}
                  onClick={handlePageChange}
              />
            </PaginationSection>
            </CardContent>
        </Card>
        )}

    </MainContentWrapper>
    { isVisibleEditModal && <EditModal selectedImage={selectedImage} setSelectedImage={setSelectedImage}/>
    }
    </>
  );
}
export default Favtest;